import 'react-perfect-scrollbar/dist/css/styles.css';
import { useNavigate, useRoutes } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Routes from './Routes';
import Login from './apps/iam/pages/Login';
import { useEffect } from 'react';
import { CookieUtils } from './utils/UtilFunctions';
import APIData from './utils/APIData';
import Services from './utils/Services';
import { validateStringForNull } from './utils/FieldValidations';

const App = () => {
  const navigate = useNavigate();
  const APIToken = {
    CHECK_LOGIN: 'PA01'
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.checkLogin && apiToken == APIToken.CHECK_LOGIN) {
      const userData = response.data;
      if (userData.user_id !== CookieUtils.getUserId() || userData.user_name !== CookieUtils.getUserName()) {
        CookieUtils.clearCookies();
        navigate('/login');
      }
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (apiData == APIData.checkLogin && apiToken == APIToken.CHECK_LOGIN) {
      console.log(err);
    }
  };

  const checkLoginHash = () => {
    Services.sendBackendRequest({ apiData: APIData.checkLogin },
      APIToken.CHECK_LOGIN, processSuccess, processError);
  };

  const MINUTE_MS = 600000;

  useEffect(() => {
    const interval = setInterval(() => {
      const isValidHash = validateStringForNull(CookieUtils.getLoginhash());
      if (isValidHash) {
        checkLoginHash();
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  const getContent = () => {
    const routes = useRoutes(Routes);
    const isValidHash = validateStringForNull(CookieUtils.getLoginhash());
    if (!isValidHash) {
      if (window.location.pathname === '/404') {
        return routes;
      } else {
        return (<Login/>);
      }
    } else {
      if (window.location.pathname !== '/login') {
        return routes;
      }
    }
  };


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {getContent()}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
