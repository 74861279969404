import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

const SalesSplitupCard = ({ columns, cardData, reloadIndex })=>{
  const [currentData, setCurrentData] = useState(undefined);
  useEffect(() => {
    if (cardData != undefined) {
      setCurrentData(cardData);
    }
  }, [reloadIndex]);

  const getValueToDisplay = (obj) => {
    return obj.display !== undefined ? obj.display : obj.value;
  };

  const getSplitupData = () => {
    const splitupData = [];
    let i = 0;
    currentData.map((row) => {
      splitupData[i] = (
        <TableRow key={i}>
          <TableCell> {row.name} </TableCell>
          {columns.map((column, j) => (
            <TableCell key={j}> {getValueToDisplay(row.data[column])} </TableCell>
          ))}
        </TableRow>
      );
      i++;
    });
    return splitupData;
  };

  return (
    (currentData != undefined ? (
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item container xs= {12} sx={{ overflow: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {columns.map((obj, i) => (
                  <TableCell key={i}>{obj}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getSplitupData()}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    ) : '')
  );
};

SalesSplitupCard.propTypes = {
  cardData: PropTypes.array,
  columns: PropTypes.array,
  reloadIndex: PropTypes.number
};

export default SalesSplitupCard;
