import { Helmet } from 'react-helmet';
import React from 'react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useState } from 'react';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import DownloadIcon from '@mui/icons-material/Download';
import { QrReader } from 'react-qr-reader';
import APIData from '../../../utils/APIData';
import CustomSnackbar from '../../../common/components/CustomSnackbar';
import { CookieUtils, Utils } from '../../../utils/UtilFunctions';
import Services from '../../../utils/Services';
import { validateAmount, validateNumber, validateStringForNull } from '../../../utils/FieldValidations';
import { DISCOUNT_FEATURES, validateFeature } from '../../../utils/FeatureValidator';
import { useEffect } from 'react';
import { cloneDeep } from 'lodash';

const RajDiscount = () => {
  const pageTitle = 'Discount | Rajmahal Apps';
  const [isLoading, setIsLoading] = useState(false);
  const [isScanStarted, setIsScanStarted] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const [isBillNumberFieldDisabled, setIsBillNumberFieldDisabled] = useState(false);
  const [isBillEditable, setIsBillEditable] = useState(false);

  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const dummy = {
    bill_no: '',
    bill_qty: '',
    bill_amount: '',
    discount_amount: '',
    bill_status: '',
    discount_percentage: '',
    net_amount: ''
  };
  const [values, setValues] = useState(dummy);
  const [allowedFeatures, setAllowedFeatures] = useState([]);
  const [originalValues, setOriginalValues] = useState(dummy);
  const [billDiscounted, setBillDiscounted] = useState(false);
  const APIToken = {
    GET_USER: 'PRD01',
    CREATE_DISCOUNT: 'PRD02',
    UPDATE_DISCOUNT: 'PRD03',
    DELETE_DISCOUNT: 'PRDO4',
    FETCH_BILL_DETAILS: 'PRD05'
  };

  const handleScannerResult = (result) => {
    if (result != undefined && result.text != undefined) {
      setValues({ ...values, bill_no: result.text.toUpperCase() });
      setOriginalValues({ ...originalValues, bill_no: result.text.toUpperCase() });
      setIsScanStarted(false);
      raiseGetBillDetails(result.text.toUpperCase());
    }
  };

  useEffect(() => {
    raiseGetUserRequest();
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getUser && apiToken == APIToken.GET_USER ) {
      if (response.data.app_list != undefined && response.data.app_list.length > 0) {
        const filtered = response.data.app_list.filter((app) => app.app_id == 'DISCOUNT');
        if (filtered != undefined && filtered.length > 0) {
          setAllowedFeatures(filtered[0].available_features);
        }
      }
    } else if ((apiData == APIData.updateDiscount && apiToken == APIToken.UPDATE_DISCOUNT) ||
    (apiData == APIData.createDiscount && apiToken == APIToken.CREATE_DISCOUNT) ||
    (apiData == APIData.deleteDiscount && apiToken == APIToken.DELETE_DISCOUNT)) {
      handleClearFields();
      showSnackBar('success', response.message);
    } else if (apiData == APIData.fetchBillDetails && apiToken == APIToken.FETCH_BILL_DETAILS) {
      if (response.data != undefined) {
        const data = response.data;
        if (validateStringForNull(data.discount_amount)) {
          data.discount_percentage = Number((data.discount_amount / data.bill_amount) * 100).toFixed(2);
          data.net_amount = data.bill_amount - data.discount_amount;
        } else {
          data.discount_percentage = '';
          data.net_amount = '';
        }
        setValues(data);
        setOriginalValues(data);
        setBillDiscounted(validateStringForNull(data.discount_amount));
        setIsBillNumberFieldDisabled(true);
        setIsBillEditable(data.can_edit);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getUser && apiToken == APIToken.GET_USER) {
      defaultMsg= 'Failed to get Logged in user details';
    } else if (apiData == APIData.updateDiscount && apiToken == APIToken.UPDATE_DISCOUNT) {
      defaultMsg = 'Failed to update discount';
    } else if (apiData == APIData.createDiscount && apiToken == APIToken.CREATE_DISCOUNT) {
      defaultMsg = 'Failed to create discount';
    } else if (apiData == APIData.deleteDiscount && apiToken == APIToken.DELETE_DISCOUNT) {
      defaultMsg = 'Failed to delete discount';
    } else if (apiData == APIData.fetchBillDetails && apiToken == APIToken.FETCH_BILL_DETAILS) {
      setIsBillEditable(false);
      setBillDiscounted(false);
      setIsBillNumberFieldDisabled(false);
      setValues({ ...values, bill_no: callbackValues.billNo, bill_qty: '', bill_amount: '',
        discount_amount: '', discount_percentage: '', net_amount: '' });
      setOriginalValues({ ...originalValues, bill_no: callbackValues.billNo, bill_qty: '',
        bill_amount: '', discount_amount: '', discount_percentage: '', net_amount: '' });
      defaultMsg = 'Failed to fetch bill Details';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetUserRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getUser, uriValues: [CookieUtils.getUserId()] },
      APIToken.GET_USER, processSuccess, processError);
  };

  const handleTextChange = (event) => {
    const input = cloneDeep(values);
    input[event.target.name] = event.target.value.toUpperCase();
    if (event.target.name == 'bill_amount' || event.target.name == 'discount_amount') {
      if (validateAmount(input.bill_amount) && validateAmount(input.discount_amount)) {
        input.discount_percentage = Number((input.discount_amount / input.bill_amount) * 100).toFixed(2);
        input.net_amount = input.bill_amount - input.discount_amount;
      } else {
        input.discount_percentage = '';
        input.net_amount = input.bill_amount;
      }
    }
    setValues(input);
  };

  const getKeyToDisplay = (key) => {
    const arr = key.split('_');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const finalString = arr.join(' ');
    return finalString;
  };

  const getValidValue = (key, obj) => {
    switch (key) {
    case 'bill_qty':
      if (!validateNumber(obj[key])) {
        throw new Error('Invalid ' + getKeyToDisplay(key));
      }
      return parseInt(obj[key]);
    case 'discount_amount':
    case 'bill_amount':
      if (!validateAmount(obj[key])) {
        throw new Error('Invalid ' + getKeyToDisplay(key));
      }
      return obj[key].toString();
    default:
      if (!validateStringForNull(obj[key])) {
        throw new Error('Invalid ' + getKeyToDisplay(key));
      }
      return obj[key].toString();
    }
  };

  const validateParams = () => {
    const result = {};
    Object.keys(values).forEach((key) => {
      result[key] = getValidValue(key, values);
    });
    return result;
  };

  const getModifiedValuesToUpdate = () => {
    const result = {};
    Object.keys(values).forEach((key) => {
      if (originalValues[key].toString() !== values[key].toString()) {
        result[key] = getValidValue(key, values);
      }
    });
    delete result['bill_no'];
    if (Object.keys(result).length == 0) {
      throw new Error('Nothing to update');
    }
    return result;
  };

  const handleApplyDiscount = () => {
    try {
      let params = undefined;
      if (billDiscounted) {
        params = getModifiedValuesToUpdate();
      } else {
        params = validateParams();
      }
      raiseApplyDiscount(params);
    } catch (err) {
      setSnackBarStatus({
        open: true,
        severity: 'error',
        message: err.message
      });
      setIsLoading(false);
    }
  };

  const raiseApplyDiscount = (params) => {
    setIsLoading(true);
    delete params['net_amount'];
    delete params['discount_percentage'];
    if (billDiscounted) {
      Services.sendBackendRequest({ apiData: APIData.updateDiscount, uriValues: [values.bill_no], params: params },
        APIToken.UPDATE_DISCOUNT, processSuccess, processError);
    } else {
      Services.sendBackendRequest({ apiData: APIData.createDiscount, params: params },
        APIToken.CREATE_DISCOUNT, processSuccess, processError);
    }
  };

  const raiseGetBillDetails = (billNo) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.fetchBillDetails, uriValues: [billNo] },
      APIToken.FETCH_BILL_DETAILS, processSuccess, processError, { billNo: billNo });
  };

  const raiseDeleteDiscount = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.deleteDiscount, uriValues: [values.bill_no] },
      APIToken.DELETE_DISCOUNT, processSuccess, processError);
  };

  const handleClearFields = () => {
    setValues(dummy);
    setOriginalValues(dummy);
    setIsBillNumberFieldDisabled(false);
    setIsBillEditable(false);
    setIsScanStarted(false);
  };

  const handleDeleteDiscount = () => {
    if (validateStringForNull(values.bill_no)) {
      raiseDeleteDiscount();
    }
  };

  const canEnableManageButtons = (feature) => {
    return (
      isBillEditable && isBillNumberFieldDisabled &&
      validateFeature(allowedFeatures, feature)
    );
  };

  const disableTextfields = () => {
    if (canEnableManageButtons(DISCOUNT_FEATURES.CREATE_OR_UPDATE_DISCOUNT)) {
      return !isBillEditable;
    }
    return true;
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box sx={{
        backgroundColor: 'background.default',
        minHeight: '100vh',
        pb: 10,
        pt: 2,
        justifyContent: 'center',
        alignItems: 'flex-start',
        display: 'flex'
      }}>
        <Container>
          <Card>
            <CardHeader
              title="Manage Discount"/>
            <CardContent>
              <Grid
                container
                justifyContent='center'
                columnSpacing={2}
              >
                {isScanStarted ? (
                  <Grid item xs={12} lg={6} sx={{ justifyContent: 'center', display: 'flex', py: 2 }}>
                    <QrReader
                      constraints= {{
                        facingMode: 'environment',
                        torch: false,
                        autoFocus: true
                      }}
                      scanDelay={1}
                      onResult={(result) => {
                        if (result) {
                          handleScannerResult(result);
                        }
                      }}
                      containerStyle={{ width: '100%', height: '65vh' }}
                      videoContainerStyle={{ height: '100%', width: 'auto', padding: '0%' }}
                      videoStyle={{ objectFit: 'cover' }}
                    />
                  </Grid> ) : ''}
                <Grid item container xs={12} lg={6} rowSpacing={2} columnSpacing={2} alignContent={'flex-start'} sx={{ pt: 3 }}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      disabled = {isBillNumberFieldDisabled}
                      name="bill_no"
                      size='small'
                      label="Bill No"
                      required
                      value={values.bill_no}
                      onChange={handleTextChange}
                      onBlur={() => {
                        if (validateStringForNull(values.bill_no)) {
                          raiseGetBillDetails(values.bill_no);
                        }
                      }}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end">
                            {values.bill_no != undefined ? (
                              <Tooltip title='Scan Barcode'>
                                <IconButton tabIndex={-1}
                                  onClick={() => raiseGetBillDetails(values.bill_no)}
                                >
                                  <DownloadIcon/>
                                </IconButton>
                              </Tooltip> ) : ''}
                            {isScanStarted == false ? (
                              <Tooltip title='Scan Barcode'>
                                <IconButton tabIndex={-1}
                                  onMouseDown={(e) => {
                                    setIsScanStarted(true);
                                    e.preventDefault();
                                  }}
                                >
                                  <QrCode2Icon/>
                                </IconButton>
                              </Tooltip>
                            ) : ''}
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant='h5'
                      sx={{
                        textAlign: 'center',
                        color: isBillEditable ? 'green' : 'red'
                      }}
                    >
                      {values.bill_status}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      name="bill_qty"
                      inputMode='numeric'
                      onChange={handleTextChange}
                      value={values.bill_qty ?? ''}
                      disabled = {disableTextfields()}
                      pattern='[0-9]*'
                      type='tel'
                      size='small'
                      label="No. of Bills"
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4.5}>
                    <TextField
                      fullWidth
                      type='number'
                      name="bill_amount"
                      inputMode='numeric'
                      disabled = {disableTextfields()}
                      size='small'
                      pattern="[0-9\.]*"
                      onChange={handleTextChange}
                      value={values.bill_amount ?? ''}
                      label="Total Amount"
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4.5}>
                    <TextField
                      fullWidth
                      name="discount_amount"
                      inputMode='numeric'
                      size='small'
                      type='number'
                      pattern="[0-9\.]*"
                      disabled = {disableTextfields()}
                      onChange={handleTextChange}
                      value={values.discount_amount ?? ''}
                      label="Discount Amount"
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      size='small'
                      type='number'
                      disabled
                      value={values.discount_percentage ?? ''}
                      label="Discount Percentage"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      size='small'
                      type='number'
                      disabled
                      value={values.net_amount ?? ''}
                      label="Net Amount"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ pb: 5, justifyContent: 'flex-end', display: 'flex' }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      size='small'
                      onClick={handleClearFields}
                    >
                      Clear
                    </Button>
                    {billDiscounted && canEnableManageButtons(DISCOUNT_FEATURES.REMOVE_DISCOUNT) ? (
                      <Button
                        color="error"
                        variant="outlined"
                        size='small'
                        onClick={handleDeleteDiscount}
                        sx={{ ml: 2 }}
                      >
                        Delete
                      </Button>
                    ) : ''}
                    {canEnableManageButtons(DISCOUNT_FEATURES.CREATE_OR_UPDATE_DISCOUNT) ? (
                      <Button
                        color="primary"
                        variant="contained"
                        size='small'
                        onClick={handleApplyDiscount}
                        sx={{ ml: 2 }}
                      >
                        {billDiscounted ? 'Update' : 'Apply'}
                      </Button>
                    ) : ''}
                  </Grid>
                </Grid>
              </Grid>
              {getSnackbar}
              <Backdrop open={isLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default RajDiscount;
