import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  SvgIcon,
  Tooltip
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const SalesTotalCard = ({ cardTitle, data, cardIcon, extraData, reloadIndex })=>{
  const [currentData, setCurrentData] = useState(undefined);
  const getValueToDisplay = (obj) => {
    return obj.display !== undefined ? obj.display : obj.value;
  };

  useEffect(() => {
    if (data != undefined) {
      setCurrentData(data);
    }
  }, [reloadIndex]);

  return (
    (currentData != undefined ? (
      <Card>
        <CardContent>
          <Grid
            container
            sx={{ flexDirection: 'row' }}
          >
            <Grid item xs={10}>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h6"
              >
                {cardTitle}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Avatar
                sx={{
                  backgroundColor: cardIcon.color,
                  height: 36,
                  width: 36
                }}
              >
                <SvgIcon component={cardIcon.icon}/>
              </Avatar>
            </Grid>
            <Grid item container xs={12} sx={{ p: 2, pt: 3 }} columnGap={2} rowGap={2}>
              <Grid item container xs={12} sm={5.5} rowGap={2}>
                <Grid item xs={12} sx={{ borderLeft: '5px solid #6b778cad' }}>
                  <Tooltip title={getValueToDisplay(currentData[0].value)}>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h5"
                      sx={{ pl: 2 }}
                    >
                      {getValueToDisplay(currentData[0].value)}
                    </Typography>
                  </Tooltip>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                    sx={{ pl: 2 }}
                  >
                    {currentData[0].title}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ borderLeft: '5px solid #6b778cad' }}>
                  <Tooltip title={getValueToDisplay(currentData[1].value)}>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h5"
                      sx={{ pl: 2 }}
                    >
                      {getValueToDisplay(currentData[1].value)}
                    </Typography>
                  </Tooltip>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h6"
                    sx={{ pl: 2 }}
                  >
                    {currentData[1].title}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={5.5} rowGap={2}>
                {extraData != undefined ? (
                  <Grid item xs={12} sx={{ borderLeft: '5px solid green' }}>
                    <Tooltip title={getValueToDisplay(extraData.value)}>
                      <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="h4"
                        sx={{ pl: 2 }}
                      >
                        {getValueToDisplay(extraData.value)}
                      </Typography>
                    </Tooltip>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="h5"
                      sx={{ pl: 2 }}
                    >
                      {extraData.title}
                    </Typography>
                  </Grid> ) :
                  <Grid item xs={12} sx={{ pb: { sm: 6 } }}></Grid>}
                <Grid item xs={12} sx={{ borderLeft: '5px solid green' }}>
                  <Tooltip title={getValueToDisplay(currentData[2].value)}>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h4"
                      sx={{ pl: 2 }}
                    >
                      {getValueToDisplay(currentData[2].value)}
                    </Typography>
                  </Tooltip>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h5"
                    sx={{ pl: 2 }}
                  >
                    {currentData[2].title}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ) : '')
  );
};

SalesTotalCard.propTypes = {
  data: PropTypes.array,
  cardTitle: PropTypes.string,
  cardIcon: PropTypes.object,
  extraData: PropTypes.object,
  reloadIndex: PropTypes.number
};

export default SalesTotalCard;
