import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Container, CircularProgress, Grid, TextField, Card,
  Table, TableHead, TableContainer, Paper, TableCell, TableBody,
  TableRow, Button, IconButton, Typography, Tooltip } from '@mui/material';
import { Utils } from '../../../../utils/UtilFunctions';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { ANALYSIS_COMPONENTS, MATERIAL_TYPE } from '../../../../utils/EnumDefinitions';
import { validateStringForNull } from '../../../../utils/FieldValidations';
import Services from '../../../../utils/Services';
import APIData from '../../../../utils/APIData';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PlanDetails = ({ planId, onSubmit, onBack }) => {
  const [planDetails, setPlanDetails] = useState({
    plan_id: '',
    plan_date: '',
    plan_tag: '',
    material_type: '',
    plan_data: []
  });
  const [showViewOrders, setshowViewOrders] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const APIToken = {
    GET_PLAN_DETAILS: 'CPD01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (validateStringForNull(planId)) {
      raiseGetPlanRequest(planId);
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getPlanDetailsForAnalysis && apiToken == APIToken.GET_PLAN_DETAILS ) {
      if (response.data != undefined) {
        const arr = response.data.plan_data.filter((plan) => plan.has_orders == true);
        setshowViewOrders(arr.length > 0);
        populatePlanData(response.data);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getPlanDetailsForAnalysis && apiToken == APIToken.GET_PLAN_DETAILS) {
      defaultMsg = 'Failed to retrieve plan list';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetPlanRequest = (planId) => {
    const params = 'plan_id=' + planId;
    Services.sendBackendRequest({ apiData: APIData.getPlanDetailsForAnalysis, params: params },
      APIToken.GET_PLAN_DETAILS, processSuccess, processError);
  };

  const populatePlanData = (data) => {
    const materialType = data.material_type;
    const obj = {};
    if (materialType == 3) {
      data.plan_data.forEach((item) => {
        const key = `${item.cate_id}_${item.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            cate_name: item.cate_name,
            cate_id: item.cate_id,
            prod_id: item.prod_id,
            prod_name: item.prod_name,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          model_name: item.model_name,
          brand_name: item.brand_name,
          model_id: item.model_id,
          brand_id: item.brand_id,
          min_price: item.min_price,
          max_price: item.max_price,
          quantity: item.quantity,
          plan_serial: item.serial,
          has_orders: item.has_orders
        });
      });
    } else {
      data.plan_data.forEach((item) => {
        const key = item.prod_id;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: item.prod_id,
            prod_name: item.prod_name,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          max_price: item.max_price,
          min_price: item.min_price,
          quantity: item.quantity,
          plan_serial: item.serial,
          has_orders: item.has_orders
        });
      });
    }
    const filteredData = Object.values(obj);
    setPlanDetails({
      ...planDetails,
      plan_id: data.plan_id,
      plan_date: data.plan_date,
      material_type: data.material_type,
      plan_tag: validateStringForNull(data.plan_tag) ? data.plan_tag: '',
      plan_data: filteredData
    });
  };

  const getMaterialUIContent = () => {
    if (planDetails.plan_data.length != 0) {
      if (planDetails.material_type == 3) {
        return getReadyMadePlanUIContent(planDetails.material_type);
      } else if (planDetails.material_type == 2 || planDetails.material_type == 1) {
        return getSilkAndPolysterPlanUIContent(planDetails.material_type);
      } else {
        return '';
      }
    };
  };

  const handleViewOrdersClick = () => {
    const obj = {
      component_id: ANALYSIS_COMPONENTS.plan_orders,
      plan_id: planId
    };
    onSubmit(obj);
  };

  const handleOrdersByProductClick = (prodId) => {
    const obj = {
      component_id: ANALYSIS_COMPONENTS.orders_by_product,
      plan_id: planId,
      prod_id: prodId,
      material_type: planDetails.material_type
    };
    onSubmit(obj);
  };

  const handleOrdersBySerialClick = (serial) => {
    const obj = {
      component_id: ANALYSIS_COMPONENTS.orders_by_serial,
      plan_id: planId,
      plan_serial: serial
    };
    onSubmit(obj);
  };

  const doesPlanDataContainsOrders = (planData) => {
    if (planData == undefined) {
      return false;
    }
    const arr = planData.sub_data.filter((plan) => plan.has_orders == true);
    return arr.length > 0;
  };

  const getReadyMadePlanUIContent = () => {
    const gridArr = [];
    planDetails.plan_data.map((readyMadePlan, i) => {
      gridArr.push(
        <Grid item xs={12} key={readyMadePlan.id}>
          <Card elevation={12}>
            <Grid key={readyMadePlan.id} container>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
                sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="category Name"
                    label="Category Name"
                    name="cate_name"
                    autoComplete ='off'
                    variant='standard'
                    value={readyMadePlan.cate_name}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="product Name"
                    label="Product Name"
                    name="prod_name"
                    autoComplete ='off'
                    variant='standard'
                    value={readyMadePlan.prod_name}
                    size='small'
                    fullWidth
                    disabled={true}
                  />
                </Grid>
                {doesPlanDataContainsOrders(readyMadePlan) ? (
                  <Grid item xs={3} sm={2} display='flex' justifyContent='flex-end'>
                    <Tooltip title='View Order By Product'>
                      <IconButton
                        color='primary'
                        onClick={() => handleOrdersByProductClick(readyMadePlan.prod_id)}
                        sx={{ p: 1 }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : ''}
              </Grid>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Model Name</TableCell>
                      <TableCell>Brand Name</TableCell>
                      <TableCell>Min Price</TableCell>
                      <TableCell>Max Price</TableCell>
                      <TableCell>Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {readyMadePlan.sub_data.map((planSubData, j) => (
                      <TableRow key={planSubData.id}>
                        <TableCell>{planSubData.model_name}</TableCell>
                        <TableCell>{planSubData.brand_name}</TableCell>
                        <TableCell>{planSubData.min_price}</TableCell>
                        <TableCell>{planSubData.max_price}</TableCell>
                        <TableCell>
                          {planSubData.has_orders == true ? (
                            <Tooltip title='Orders By Range'>
                              <Typography variant='h6' color='red'
                                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => handleOrdersBySerialClick(planSubData.plan_serial)}
                              >
                                {planSubData.quantity}
                              </Typography>
                            </Tooltip>
                          ) : planSubData.quantity }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Card>
        </Grid>
      );
    });
    return gridArr;
  };

  const getSilkAndPolysterPlanUIContent = () => {
    const gridArr = [];
    planDetails.plan_data.map((silkAndPolyesterPlan, i) => {
      gridArr.push(
        <Grid item xs={12} key={silkAndPolyesterPlan.id}>
          <Card elevation={12}>
            <Grid container>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
                sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="prod_id"
                    label="Product Id"
                    name="prod_id"
                    autoComplete ='off'
                    value={silkAndPolyesterPlan.prod_id}
                    size='small'
                    disabled={true}
                    fullWidth
                    variant='standard'
                  />
                </Grid>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="prod_name"
                    label="Product Name"
                    name="prod_id"
                    autoComplete ='off'
                    value={silkAndPolyesterPlan.prod_name}
                    size='small'
                    fullWidth
                    variant='standard'
                    disabled={true}
                  />
                </Grid>
                {doesPlanDataContainsOrders(silkAndPolyesterPlan) ? (
                  <Grid item xs={3} sm={2} display='flex' justifyContent='flex-end'>
                    <Tooltip title='View Order By Product'>
                      <IconButton
                        color='primary'
                        onClick={() => handleOrdersByProductClick(silkAndPolyesterPlan.prod_id)}
                        sx={{ p: 1 }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : ''}
              </Grid>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Min Price</TableCell>
                      <TableCell>Max Price</TableCell>
                      <TableCell>Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {silkAndPolyesterPlan.sub_data.map((planSubData, j) => (
                      <TableRow key={planSubData.id}>
                        <TableCell>{planSubData.min_price}</TableCell>
                        <TableCell>{planSubData.max_price}</TableCell>
                        <TableCell>
                          {planSubData.has_orders == true ? (
                            <Tooltip title='orders by Range'>
                              <Typography variant='h6' color='red'
                                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => handleOrdersBySerialClick(planSubData.plan_serial)}
                              >
                                {planSubData.quantity}
                              </Typography>
                            </Tooltip>
                          ) : planSubData.quantity }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Card>
        </Grid>
      );
    });
    return gridArr;
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
        <Grid item xs={1}>
          <Tooltip title='Back'>
            <IconButton
              color='primary'
              onClick={onBack}
              sx={{ p: 1 }}
            >
              < ArrowBackIcon/>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={11} sx={{ mb: 2 }}>
          <Typography variant='h5' align='center'>
            Analysis - Plan Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="Plan Id"
            label="Plan Id"
            name="plan_id"
            autoComplete ='off'
            value={planDetails.plan_id}
            size='small'
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={3.5}>
          <TextField
            id="Plan tag"
            label="Plan Tag"
            name="plan_tag"
            autoComplete ='off'
            value={planDetails.plan_tag}
            size='small'
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <TextField
            id="Plan Date"
            label="Plan Date"
            name="plan_date"
            autoComplete ='off'
            value={planDetails.plan_date}
            size='small'
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="material_type"
            label="Material Type"
            name="material_type"
            autoComplete ='off'
            value={validateStringForNull(planDetails.material_type) ? MATERIAL_TYPE[planDetails.material_type] : ''}
            size='small'
            disabled={true}
            fullWidth
          />
        </Grid>
        {showViewOrders == true ? (
          <Grid item xs={12} display='flex' justifyContent='flex-end'>
            <Button
              color="primary"
              variant="contained"
              size='small'
              onClick={handleViewOrdersClick}
            >
              View Orders
            </Button>
          </Grid>
        ) : ''}
        {getMaterialUIContent()}
      </Grid>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

PlanDetails.propTypes = {
  planId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
};

export default PlanDetails;
