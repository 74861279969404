import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import OrderReport from '../components/reports/OrderReport';

const Reports = () => {
  const pageTitle = 'Orders Report | Rajmahal Apps';
  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box
        sx={{
          minHeight: 'calc(94vh - 64px)',
          alignItems: 'flex-start',
          pb: 5,
          display: 'flex'
        }}
      >
        <OrderReport />
      </Box>
    </React.Fragment>
  );
};

export default Reports;
