import { Grid, TextField,
  Backdrop, CircularProgress, Button, Tooltip, CardContent, CardHeader, Card } from '@mui/material';
import React, { useState } from 'react';
import { blue } from '@mui/material/colors';
import PropTypes from 'prop-types';
import { Utils } from '../../../utils/UtilFunctions';
import CustomSnackbar from '../../../common/components/CustomSnackbar';
import { validateNumber, validateStringForNull } from '../../../utils/FieldValidations';
import Services from '../../../utils/Services';
import APIData from '../../../utils/APIData';

const CreateUser = ({ onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    user_name: '',
    phone: ''
  });
  const APIToken = {
    CREATE_USER: 'CCU01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const handleTextChange = (event) => {
    setUserDetails({ ...userDetails, [event.target.name]: event.target.value });
  };

  const validateParams = () => {
    const modifiedParams = {};
    if (!validateStringForNull(userDetails.user_name)) {
      throw new Error('Provide valid user name');
    }
    if (!validateNumber(userDetails.phone)) {
      throw new Error('Provide valid Phone Number');
    }
    modifiedParams.user_name = userDetails.user_name;
    modifiedParams.phone = userDetails.phone;
    return modifiedParams;
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.createUser && apiToken == APIToken.CREATE_USER ) {
      showSnackBar('success', response.message ?? 'User created successfully');
      setUserDetails({ ...userDetails,
        user_name: '',
        phone: ''
      });
      onSuccess();
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.createUser && apiToken == APIToken.CREATE_USER) {
      defaultMsg = 'Failed to create user';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseCreateUserRequest = (params) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.createUser, params: params },
      APIToken.CREATE_USER, processSuccess, processError);
  };

  const handleCreateClick = () => {
    setIsLoading(true);
    try {
      const finalParams = validateParams();
      raiseCreateUserRequest(finalParams);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to create user');
      setIsLoading(false);
    }
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader
        title="Create User"
      />
      <CardContent>
        <Grid container justifyContent='space-between' alignItems='center' rowSpacing={3} columnSpacing={3}>
          <Grid item xs={12} sm={6} >
            <TextField
              id="Name"
              label="User Name"
              name="user_name"
              autoComplete ='off'
              size='small'
              value={userDetails.user_name}
              onChange={(event) => {handleTextChange(event);}}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="phone"
              name="phone"
              size='small'
              value={userDetails.phone}
              label="Phone"
              autoComplete ='off'
              fullWidth
              onChange={(event) => {handleTextChange(event);}}
            />
          </Grid>
          <Grid item container justifyContent='flex-end' alignItems='center' sx={{ py: 2 }} xs={12} >
            <Grid item>
              <Tooltip title='Create User'>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  onClick={handleCreateClick}
                >
                  Create User
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {getSnackbar}
        <Backdrop open={isLoading}>
          <CircularProgress style= {{ color: blue[200] }} />
        </Backdrop>
      </CardContent>
    </Card>
  );
};

CreateUser.propTypes = {
  onSuccess: PropTypes.func.isRequired
};

export default CreateUser;
