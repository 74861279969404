import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CustomDatagrid from './CustomDatagrid';
import Services from '../../../../utils/Services';

const ReportsTable = ({ reportsURI, isTotalTable, queryParams, getColumns, handleException, handleCompletion, instanceId }) => {
  const [rowData, setRowData] = useState(undefined);
  const [columnData, setColumnData] = useState(undefined);
  const perPage=100;
  const APIToken = {
    REPORT_URI: 'CRT01'
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == reportsURI && apiToken == APIToken.REPORT_URI ) {
      const reportsTableData = response.data;
      setRowData(reportsTableData.reports);
      if (callbackValues.reloadColumns) {
        setColumnData(getColumns(reportsTableData, isTotalTable));
      }
      if (handleCompletion!= undefined && callbackValues.reloadColumns) {
        handleCompletion(isTotalTable);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      return;
    };
    if (apiData == reportsURI && apiToken == APIToken.REPORT_URI) {
      if (handleException!= undefined) {
        handleException(err);
      } else {
        console.log(err);
      }
      if (handleCompletion!= undefined && callbackValues.reloadColumns) {
        handleCompletion(isTotalTable);
      }
    }
  };

  const getReportsData = (reloadColumns, pageNo) =>{
    const uriParams = queryParams+'&total='+isTotalTable+'&per_page='+perPage+'&page='+(pageNo+1);
    Services.sendBackendRequest({ apiData: reportsURI, params: uriParams },
      APIToken.REPORT_URI, processSuccess, processError, { reloadColumns: reloadColumns });
  };

  useEffect(() => {
    setRowData(undefined);
    setColumnData(undefined);
    getReportsData(true, 0);
  }, [instanceId]);

  return (
    <React.Fragment>
      { (rowData !== undefined && columnData !== undefined) ? (
        <div style={{ width: '100%' }}>
          <CustomDatagrid
            rows={rowData}
            columns={columnData}
          />
        </div> ) : ''}
    </React.Fragment>
  );
};

ReportsTable.propTypes = {
  reportsURI: PropTypes.object,
  queryParams: PropTypes.string,
  isTotalTable: PropTypes.bool,
  getColumns: PropTypes.func,
  handleException: PropTypes.func,
  handleCompletion: PropTypes.func,
  instanceId: PropTypes.number
};

export default ReportsTable;
