import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography
} from '@mui/material';
import {
  ChevronsLeft as ChevronsLeftIcon
} from 'react-feather';
import { CookieUtils, Utils } from '../../utils/UtilFunctions';
import { styled } from '@mui/system';
import Logo from '../components/Logo';
import { SideBarMenuItems } from '../../utils/MenuItems';

const AppSidebar = ({ appName, onClose, isOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const getFilteredItems = () => {
    const filteredItems = SideBarMenuItems.getMenuItems(appName);
    // for (let i = 0; i < filteredItems.length; i++) {
    //   const item = filteredItems[i];
    //   for (let j = 0; j < item.items.length; j++) {
    //     if (item.items[j].isSuperUserOnly == true) {
    //       item.items.splice(j, 1);
    //     }
    //   }
    // }
    return filteredItems;
  };

  useEffect(() => {
    if (isOpen && onClose) {
      onClose();
    }
  }, [location.pathname]);

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  }));

  const handleNavigation = (item) => {
    if (onClose != undefined) {
      onClose();
    }
    navigate(item.link);
  };

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={isOpen}
      variant="temporary"
      PaperProps={{
        sx: {
          width: 280
        }
      }}
    >
      <DrawerHeader sx={{ bgcolor: '#205295' }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
          }}
        >
          <Avatar
            component={RouterLink}
            sx={{
              cursor: 'pointer',
              width: 32,
              height: 32,
              ml: 1,
              bgcolor: 'white',
              color: '#205295'
            }}
            to="/my-account"
          >
            {Utils.getInitials(CookieUtils.getUserName())}
          </Avatar>
          <Typography
            sx={{ ml: 1, alignItems: 'center' }}
            color="white"
            variant="h5"
          >
            Welcome {CookieUtils.getUserName().charAt(0).toUpperCase() + CookieUtils.getUserName().slice(1)}
          </Typography>
        </Box>
        <IconButton onClick={onClose} sx={{ alignContent: 'right' }}>
          <ChevronsLeftIcon color='white' />
        </IconButton>
      </DrawerHeader>
      {getFilteredItems().map((list, i) => (
        <List key={i} subheader={list.header != '' ? <ListSubheader>{list.header}</ListSubheader> : ''}>
          {list.items.map((item, j) => (
            <ListItem key={j} disablePadding sx={{ display: 'block' }} onClick={() => {handleNavigation(item);}}>
              <Tooltip title={open ? '' : item.name} placement="right">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center'
                    }}
                  >
                    <item.icon/>
                  </ListItemIcon>
                  <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      ))}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          px: 2,
          py: 2
        }}>
        <Typography
          sx={{ mb: 1, display: 'flex', justifyContent: 'center' }}
          color="text.secondary"
          variant="h6"
        >
          Powered By
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <a href="http://www.teqshack.in" target="_">
            <Logo
              alt="Teqshack"
              src="/static/images/Teqshack.png"
              height={40}/>
          </a>
        </Box>
      </Box>
    </Drawer>
  );
};

AppSidebar.propTypes = {
  appName: PropTypes.string,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool
};

export { AppSidebar };
