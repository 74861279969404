const Constants = {
  LOGIN_HASH: 'rajdashboardhash',
  REPORTS_APP_KEY: 'reports',
  IAM_APP_KEY: 'iam',
  DISCOUNT_APP_KEY: 'discount',
  ROOT_APP_KEY: 'root',
  ORDERS_APP_KEY: 'orders',
  APPROVALS_APP_KEY: 'approvals'
};

export {
  Constants
};
