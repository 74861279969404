import {
  Box,
  Card,
  CardContent,
  colors,
  Typography,
  useTheme
} from '@mui/material';
import { ArcElement, Chart } from 'chart.js';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';

Chart.register(
  ArcElement
);

const SalesDonutChart = ({ cardData, reloadIndex }) => {
  const theme = useTheme();
  const [donutchartInputData, setDonutchartInputData] = useState(undefined);
  useEffect(() => {
    if (cardData != undefined) {
      setDonutchartInputData(cardData);
    }
  }, [reloadIndex]);
  const colorarr = [colors.indigo[300], colors.brown[300], colors.green[300],
    colors.red[300], colors.blue[300], colors.orange[300], colors.yellow[300]];

  const getData = () => {
    const data = {};
    data.datasets = [
      {
        data: donutchartInputData.map((obj) => obj.amount.value),
        backgroundColor: colorarr
      }
    ];
    data.labels = donutchartInputData.map((obj) => obj.pay_mode_name);
    return data;
  };

  const options = {
    animation: true,
    plugins: {
      legend: {
        display: false
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    borderWidth: 2,
    hoverBorderWidth: 0,
    tooltip: {
      displayColors: false,
      borderColor: 'transparent',
      intersect: false,
      backgroundColor: theme.palette.background.paper
    }
  };

  const getTransactionDisplay= (arr) =>{
    const transactionDisplay = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].txn_type != 'Total') {
        transactionDisplay[i] = {};
        transactionDisplay[i].title = arr[i].pay_mode_name;
        transactionDisplay[i].color = colorarr[i];
        transactionDisplay[i].amount = arr[i].amount.display;
      }
    }
    return transactionDisplay;
  };

  return (
    (donutchartInputData != undefined ?
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Box
            sx={{
              height: 190,
              position: 'relative'
            }}
          >
            <Doughnut
              data={getData()}
              options={options}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              pt: 2,
              flexWrap: 'wrap'
            }}
          >
            {getTransactionDisplay(donutchartInputData).map(({
              color,
              title,
              amount
            }) => (
              <Box
                key={title}
                sx={{
                  p: 0.75,
                  textAlign: 'center'
                }}
              >
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  {title}
                </Typography>
                <Typography
                  style={{ color,
                    fontWeight: '600',
                    fontSize: '16px'
                  }}
                  variant="h3"
                >
                  {amount}
                </Typography>
              </Box>
            ))}
          </Box>
        </CardContent>
      </Card> : '')
  );
};

SalesDonutChart.propTypes = {
  cardData: PropTypes.array,
  reloadIndex: PropTypes.number
};
export default SalesDonutChart;
