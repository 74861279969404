import { Bar } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  useTheme,
  colors
} from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { useEffect } from 'react';

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SalesBarChart = ({ data, choice, reloadIndex } ) => {
  const theme = useTheme();
  const [barchartInputdata, setBarchartInputdata] = useState(undefined);
  useEffect(() => {
    if (data != undefined) {
      setBarchartInputdata(data);
    }
  }, [reloadIndex]);

  const getLabel = (label) => {
    const arr = label.split('_');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const finalString = arr.join(' ');
    return finalString;
  };

  const getData = () => {
    const data = {};
    data.datasets = [
      {
        label: choice,
        data: Object.keys(barchartInputdata[choice]).map((key) => barchartInputdata[choice][key].value),
        datalabels: Object.keys(barchartInputdata[choice]).map((key) => barchartInputdata[choice][key].display),
        backgroundColor: [colors.orange[300], colors.blue[300],
          colors.brown[300], colors.red[300], colors.indigo[300], colors.green[300], colors.blue[300], colors.indigo[300]]
      }
    ];
    data.labels = Object.keys(barchartInputdata[choice]).map((key) => getLabel(key));
    return data;
  };

  const options = {
    animation: true,
    cornerRadius: 20,
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 0,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        formatter: function(value, context) {
          return context.dataset != undefined ? context.dataset.datalabels[context.dataIndex] : '';
        },
        display: true,
        color: '#6b778c',
        anchor: 'end',
        offset: -20,
        align: 'start'
      }
    }
  };

  return (
    (barchartInputdata != undefined ?
      <Card sx={{ mb: 2, overflow: 'auto' }}>
        <CardContent>
          <Box
            sx={{
              height: 400,
              minWidth: 550,
              position: 'relative'
            }}
          >
            <Bar
              data={getData()}
              options={options}
              plugins={[ChartDataLabels]}
            />
          </Box>
        </CardContent>
      </Card> : '')
  );
};

SalesBarChart.propTypes = {
  reloadIndex: PropTypes.number,
  choice: PropTypes.string,
  data: PropTypes.object
};
export default SalesBarChart;
