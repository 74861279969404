import DashboardIcon from '@mui/icons-material/InsertChart';
import InventoryIcon from '@mui/icons-material/Inventory';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';

const getReportsAppMenuItems = () => {
  return [
    {
      header: 'Home',
      items: [
        {
          name: 'Dashboard',
          icon: DashboardIcon,
          link: '/reports',
          isSuperUserOnly: true
        }
      ]
    },
    {
      header: 'Reports',
      items: [
        {
          name: 'Stock',
          icon: InventoryIcon,
          link: '/reports/stock',
          isSuperUserOnly: true
        }
      ]
    },
    {
      header: 'Account',
      items: [
        {
          name: 'My Account',
          icon: PersonIcon,
          link: '/reports/my-account'
        },
        {
          name: 'Logout',
          icon: LogoutIcon,
          link: '/logout'
        }
      ]
    }
  ];
};

const getIAMAppMenuItems = () => {
  return [
    {
      header: 'User Management',
      items: [
        {
          name: 'Manage Users',
          icon: PersonIcon,
          link: '/iam/manage-users',
          isSuperUserOnly: true
        }
      ]
    },
    {
      header: 'Account',
      items: [
        {
          name: 'My Account',
          icon: PersonIcon,
          link: '/iam/my-account'
        },
        {
          name: 'Logout',
          icon: LogoutIcon,
          link: '/logout'
        }
      ]
    }
  ];
};

const getDiscountAppMenuItems = () => {
  return [
    {
      header: 'Home',
      items: [
        {
          name: 'Discount',
          icon: PersonIcon,
          link: '/discount/home'
        }
      ]
    },
    {
      header: 'Account',
      items: [
        {
          name: 'My Account',
          icon: PersonIcon,
          link: '/discount/my-account'
        },
        {
          name: 'Logout',
          icon: LogoutIcon,
          link: '/logout'
        }
      ]
    }
  ];
};

const getOrdersAppMenuItems = () => {
  return [
    {
      header: 'Orders',
      items: [
        {
          name: 'Planning',
          icon: PersonIcon,
          link: '/orders/planning'
        },
        {
          name: 'Booking',
          icon: PersonIcon,
          link: '/orders/booking'
        },
        {
          name: 'Direct Booking',
          icon: PersonIcon,
          link: '/orders/direct-booking'
        }
      ]
    },
    {
      header: 'Entities',
      items: [
        {
          name: 'Create New',
          icon: PersonIcon,
          link: '/orders/new-entities'
        }
      ]
    },
    {
      header: 'Reports',
      items: [
        {
          name: 'Analysis',
          icon: PersonIcon,
          link: '/orders/order-analysis'
        },
        {
          name: 'Order Report',
          icon: PersonIcon,
          link: '/orders/order-report'
        }
      ]
    },
    {
      header: 'Account',
      items: [
        {
          name: 'My Account',
          icon: PersonIcon,
          link: '/orders/my-account'
        },
        {
          name: 'Logout',
          icon: LogoutIcon,
          link: '/logout'
        }
      ]
    }
  ];
};

const getDefaultAppMenuItems = () => {
  return [
    {
      header: 'Account',
      items: [
        {
          name: 'My Account',
          icon: PersonIcon,
          link: '/home/my-account'
        },
        {
          name: 'Logout',
          icon: LogoutIcon,
          link: '/logout'
        }
      ]
    }
  ];
};

class SideBarMenuItems {
  static getMenuItems(appName) {
    switch (appName.toLowerCase()) {
    case 'reports': return getReportsAppMenuItems();
    case 'iam': return getIAMAppMenuItems();
    case 'discount': return getDiscountAppMenuItems();
    case 'orders': return getOrdersAppMenuItems();
    default: return getDefaultAppMenuItems();
    }
  }
}

export { SideBarMenuItems };
