import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  AppBar, Toolbar, IconButton, Avatar, Grid, Menu, MenuItem, Typography, Divider, Dialog, Container, Tooltip
} from '@mui/material';
import Logo from '../components/Logo';
import { CookieUtils, Utils } from '../../utils/UtilFunctions';
import { useState } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountDetails from '../../apps/iam/components/AccountDetails';
import ChangePassword from '../../apps/iam/components/ChangePassword';
import CloseIcon from '@mui/icons-material/Close';

const HomeNavbar = ({ ...rest }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const isPopupMenuOpen = Boolean(anchorEl);
  const [isMyAccountOpen, setIsMyAccountOpen] = useState(false);

  const handlePopupMenuOpen = (event) => {
    if (anchorEl == null) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };
  const handlePopupMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMyAccountOpen = () => {
    setIsMyAccountOpen(true);
  };

  const handleMyAccountClose = () => {
    setIsMyAccountOpen(false);
  };

  const handleLogoutClick = () => {
    navigate('/logout');
  };

  const getMenuItems = () => {
    return [
      {
        id: 1,
        label: 'My Account',
        icon: <ManageAccountsIcon/>,
        onclick: handleMyAccountOpen
      },
      {
        id: 3,
        label: 'Logout',
        icon: <LogoutIcon/>,
        onclick: handleLogoutClick
      }
    ];
  };

  return (
    <AppBar
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <Grid container
          direction="row"
          justifyContent="center"
          alignItems="center">
          <Grid item xs={true}>
            <RouterLink to="/">
              <Logo
                alt="Rajmahal"
                src="/static/images/Rajmahal.png"
                height={40}/>
            </RouterLink>
          </Grid>
          <Grid item>
            <Tooltip title='My Account'>
              <Avatar
                sx={{
                  cursor: 'pointer',
                  width: 32,
                  height: 32,
                  bgcolor: 'white',
                  color: '#205295'
                }}
                onClick={handlePopupMenuOpen}
                aria-controls={isPopupMenuOpen ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isPopupMenuOpen ? 'true' : undefined}
              >
                {Utils.getInitials(CookieUtils.getUserName())}
              </Avatar>
            </Tooltip>
          </Grid>
        </Grid>
      </Toolbar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={isPopupMenuOpen}
        onClose={handlePopupMenuClose}
        onClick={handlePopupMenuOpen}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {getMenuItems().map((menuItem) => (
          <MenuItem key={menuItem.id} onClick={menuItem.onclick}>
            <IconButton color="inherit" size="small" >
              {menuItem.icon}
              <Typography sx={{ ml: 1 }} variant="button" noWrap>
                {menuItem.label}
              </Typography>
            </IconButton>
            <Divider/>
          </MenuItem>
        ))}
      </Menu>
      <Dialog
        fullScreen
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }
        }}
        open={isMyAccountOpen}
        PaperProps={{
          sx: {
            width: {
              lg: '50%'
            }
          }
        }}
      >
        <AppBar sx={{ position: 'relative', mb: 5 }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleMyAccountClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              My Account
            </Typography>
          </Toolbar>
        </AppBar>
        <Container sx={{ p: 1 }}>
          <Grid
            container
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              <AccountDetails/>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              mt={2}
            >
              <ChangePassword/>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </AppBar>
  );
};

export default HomeNavbar;
