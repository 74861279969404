import { Helmet } from 'react-helmet';
import React from 'react';
import {
  Box,
  Container,
  Grid
} from '@mui/material';
import AccountDetails from '../components/AccountDetails';
import { CookieUtils } from '../../../utils/UtilFunctions';
import ResetPassword from '../components/ResetPassword';
import ChangePassword from '../components/ChangePassword';

const MyAccount = () => {
  const pageTitle = 'My Account | Rajmahal Apps';
  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: 'calc(100vh - 98px)',
          py: 3
        }}
      >
        <Container maxWidth="md">
          <Grid
            container
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              <AccountDetails/>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              mt={2}
            >
              <ChangePassword/>
            </Grid>
            {
              CookieUtils.isSuperUser() ? (
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  mt={2}
                >
                  <ResetPassword/>
                </Grid>
              ) : ''
            }
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default MyAccount;
