import { Helmet } from 'react-helmet';
import {
  Backdrop,
  CircularProgress,
  Typography
} from '@mui/material';
import { useState, useEffect } from 'react';
import CustomSnackbar from '../../../common/components/CustomSnackbar';
import { CookieUtils, Utils } from '../../../utils/UtilFunctions';
import React from 'react';
import APIData from '../../../utils/APIData';
import Services from '../../../utils/Services';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const pageTitle = 'Sign Out | Rajmahal Apps';
  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const navigate = useNavigate();
  const APIToken = {
    LOG_OUT: 'PLO01'
  };

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.logout && apiToken == APIToken.LOG_OUT ) {
      CookieUtils.clearCookies();
      setTimeout(() => navigate('/', { replace: true }), 2000);
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.logout && apiToken == APIToken.LOG_OUT) {
      defaultMsg = 'Failed to logout';
      CookieUtils.clearCookies();
      setTimeout(() => navigate('/login', { replace: true }), 2000);
    }
    showSnackBar('error', err.message ?? defaultMsg);
  };

  useEffect(() => {
    Services.sendBackendRequest({ apiData: APIData.logout },
      APIToken.LOG_OUT, processSuccess, processError);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CustomSnackbar
        isOpen={snackBarStatus.open}
        severity={snackBarStatus.severity}
        message={snackBarStatus.message}
        onClose={handleSnackBarClose}
      />
      <Backdrop open={!snackBarStatus.open}>
        <Typography
          color="textPrimary"
          variant="body1"
        >
          Logging out.. Hold on
        </Typography>
        <br />
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default Logout;
