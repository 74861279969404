import moment from 'moment';

const validateStringForNull = (value) => (
  !(value === undefined || value === '' || value === null)
);

const validateNumber = (value) => (
  !(value === null || value === undefined || value === '' || isNaN(value))
);

const validateEmailId = (value) => {
  const regexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return regexp.test(value);
};

const validateAmount = (value) => {
  const regex = /^\d+(\.\d{1,2})?$/;
  return regex.test(value);
};

const validateDate = (value) => {
  if (validateStringForNull(value)) {
    const date = moment(value);
    return date.isValid();
  }
  return false;
};

export { validateStringForNull, validateEmailId, validateNumber, validateDate, validateAmount };
