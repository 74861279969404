import moment from 'moment';
import { Constants } from './Constants';
import * as Crypto from 'crypto-js';

class Utils {
  static getInitials(name) {
    if (name == undefined) {
      return '-';
    }
    return name
      .replace(/\s+/, ' ')
      .split(' ')
      .slice(0, 2)
      .map((v) => v && v[0].toUpperCase())
      .join('');
  }

  static getCurrentDate() {
    return moment().format('YYYY-MM-DD');
  }

  static getInitialStatusBarState() {
    return {
      open: false,
      severity: 'info',
      message: ''
    };
  }

  static isMobile() {
    return window.innerWidth <= 480;
  }
}

class CookieUtils {
  static getParsedCookie() {
    try {
      const bytes = Crypto.AES.decrypt(localStorage.getItem(Constants.LOGIN_HASH), 'R#(@ui*54$@y');
      const rawStr = bytes.toString(Crypto.enc.Utf8);
      const parsedObj = JSON.parse(rawStr);
      if (parsedObj == null || parsedObj == undefined || parsedObj == '') {
        return {};
      }
      return parsedObj;
    } catch {
      return {};
    }
  }

  static getLoginhash() {
    const parsedObj = CookieUtils.getParsedCookie();
    return parsedObj.loginHash;
  }

  static getUserId() {
    const parsedObj = CookieUtils.getParsedCookie();
    return parsedObj.user_id;
  }

  static getUserName() {
    const parsedObj = CookieUtils.getParsedCookie();
    return parsedObj.user_name;
  }

  static getUserRole() {
    const parsedObj = CookieUtils.getParsedCookie();
    return parsedObj.role;
  }

  static getAllowedApps() {
    const parsedObj = CookieUtils.getParsedCookie();
    return parsedObj.allowed_apps;
  }

  static isSuperUser() {
    return this.getUserRole() == 1;
  }

  static clearCookies() {
    localStorage.clear();
  }

  static setCookie(key, value) {
    CookieUtils.clearCookies();
    const eValue = Crypto.AES.encrypt(value, 'R#(@ui*54$@y').toString();
    localStorage.setItem(key, eValue);
  }
}

class JsonToFormDataConverter {
  static isObject(value) {
    return value === Object(value);
  }

  static isReactNativeBlob(value) {
    return (
      typeof new FormData().getParts === 'function' &&
      JsonToFormDataConverter.isObject(value) &&
      !JsonToFormDataConverter.isUndefined(value.uri)
    );
  }

  static isBlob(value) {
    return (
      (JsonToFormDataConverter.isObject(value) &&
        typeof value.size === 'number' &&
        typeof value.type === 'string' &&
        typeof value.slice === 'function') ||
        JsonToFormDataConverter.isReactNativeBlob(value)
    );
  }

  static isFile(value) {
    return (
      JsonToFormDataConverter.isBlob(value) &&
      typeof value.name === 'string' &&
      (JsonToFormDataConverter.isObject(value.lastModifiedDate) || typeof value.lastModified === 'number')
    );
  }

  static stripQuotes(a) {
    if (a.charAt(0) === '"' && a.charAt(a.length-1) === '"') {
      return a.substr(1, a.length-2);
    }
    return a;
  }

  static convert(jsonValue) {
    const data = new FormData();
    Object.keys(jsonValue).forEach((key) => {
      if (JsonToFormDataConverter.isFile(jsonValue[key])) {
        data.append(key, jsonValue[key]);
      } else {
        data.append(key, JsonToFormDataConverter.stripQuotes(JSON.stringify(jsonValue[key])));
      }
    });
    return data;
  }
}

class KeyPressUtil {
  static escFunction(e, onCancel) {
    e.preventDefault();
    if (e.keyCode === 27) {
      // Close when esc is pressed
      onCancel();
    }
  }

  static enterFunction(e, onEnter) {
    if (e.keyCode === 13) {
      e.preventDefault();
      // Close when enter is pressed
      onEnter();
    }
  }

  static keyListenerFunc(callbackfunc) {
    document.addEventListener('keyup', callbackfunc, true);
    return () => {
      document.removeEventListener('keyup', callbackfunc, true);
    };
  }
}

export { Utils, CookieUtils, JsonToFormDataConverter, KeyPressUtil };
