import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { AppSidebar } from './AppSidebar';
import AppNavbar from './AppNavbar';
import PropTypes from 'prop-types';

const AppLayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const AppLayoutWrapper = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  })
);

const AppLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const AppLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const AppLayout = ({ appName }) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  return (
    <AppLayoutRoot>
      <AppNavbar
        appName={appName}
        onSideBarOpen={() => setIsSideBarOpen(true)} />
      <AppSidebar
        appName={appName}
        onClose={() => setIsSideBarOpen(false)}
        isOpen={isSideBarOpen}
      />
      <AppLayoutWrapper>
        <AppLayoutContainer>
          <AppLayoutContent>
            <Outlet />
          </AppLayoutContent>
        </AppLayoutContainer>
      </AppLayoutWrapper>
    </AppLayoutRoot>
  );
};

AppLayout.propTypes = {
  appName: PropTypes.string.isRequired
};

export default AppLayout;
