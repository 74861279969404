import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import HomeNavbar from './HomeNavbar';

const HomeLayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const HomeLayoutWrapper = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  })
);

const HomeLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const HomeLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const HomeLayout = () => {
  return (
    <HomeLayoutRoot>
      <HomeNavbar/>
      <HomeLayoutWrapper>
        <HomeLayoutContainer>
          <HomeLayoutContent>
            <Outlet />
          </HomeLayoutContent>
        </HomeLayoutContainer>
      </HomeLayoutWrapper>
    </HomeLayoutRoot>
  );
};

export default HomeLayout;
