import { Helmet } from 'react-helmet';
import { Backdrop, Box, Card, CardMedia,
  Chip,
  CircularProgress, Container, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import CustomSnackbar from '../components/CustomSnackbar';
import { CookieUtils, Utils } from '../../utils/UtilFunctions';
import { useState } from 'react';
import Services from '../../utils/Services';
import APIData from '../../utils/APIData';
import { blue } from '@mui/material/colors';
import { APP_INFO } from '../../utils/EnumDefinitions';
import { useNavigate } from 'react-router-dom';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Constants } from '../../utils/Constants';

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [apps, setApps] = useState(undefined);
  const navigate = useNavigate();
  const APIToken = {
    GET_USER: 'PH01'
  };

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };
  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getUser && apiToken == APIToken.GET_USER ) {
      const obj = {
        user_id: response.data.user_id,
        user_name: response.data.user_name,
        role: response.data.role,
        loginHash: CookieUtils.getLoginhash(),
        allowed_apps: response.data.app_list
      };
      // const app = {
      //   app_id: 'ORDERS',
      //   app_name: 'Orders',
      //   role_name: 'Custom'
      // };
      // obj.allowed_apps.push(app);
      CookieUtils.setCookie(Constants.LOGIN_HASH, JSON.stringify(obj));
      setApps(response.data.app_list);
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getUser && apiToken == APIToken.GET_USER) {
      defaultMsg = 'Unable to retrieve app list';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetUserRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getUser, uriValues: [CookieUtils.getUserId()] },
      APIToken.GET_USER, processSuccess, processError);
  };

  useEffect(() => {
    raiseGetUserRequest();
  }, []);

  const handleAppClick = (app) => {
    const appLink = '/' + APP_INFO[app.app_id];
    navigate(appLink);
  };

  const getCardImage = (appId) => {
    return '/static/images/' + APP_INFO[appId] + '.png';
  };

  const getAppsCards = () => {
    return (
      <Container sx={{ pt: 10, pb: 10 }}>
        <Grid
          container
          direction="row"
          rowSpacing={2}
          justifyContent="space-between">
          <Grid item>
            <Typography
              variant="h4"
              color="text.primary"
            >
              Available Apps
            </Typography>
          </Grid>
        </Grid>
        <Grid sx={{ py: 2, pb: 5 }} container spacing={4} >
          {apps.map((app) => (
            <Grid item key={app.app_id} xs={12} sm={6} md={4} lg={3}>
              <Card
                onClick={() => handleAppClick(app)}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: 3,
                  backgroundImage: 'linear-gradient(45deg, #144272, #2C74B3)',
                  '&:hover': {
                    backgroundPosition: 'right'
                  },
                  backgroundSize: '150%',
                  transition: '0.6s'
                }}>
                <Grid container sx={{ width: '100%', p: 1 }}>
                  <Grid item container xs={6}>
                    <Grid item xs={12} alignItems='flex-end' display='flex' sx={{ pl: 1 }}>
                      <Typography color='white' variant='h5'><strong>{app.app_name}</strong></Typography>
                    </Grid>
                    <Grid item xs={12} alignItems='flex-end' display='flex'>
                      <Chip sx={{ backgroundColor: '#ffff' }} size="small" icon={<PersonOutlineIcon />} label={app.role_name}/>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} justifyContent={'flex-end'} display={'flex'}>
                    <CardMedia
                      component="img"
                      sx={{ width: 100, height: 100, m: 1 }}
                      image={getCardImage(app.app_id)}
                      alt={app.app_name}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  };

  const getNoAppsCard = () => {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        sx={{ pt: 25 }}
      >
        <Grid item>
          <Typography
            align="center"
            color="textPrimary"
            variant="h3"
          >
            You don&apos;t have permission to access our apps. Contact Administrator
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const getApps = () => {
    if (apps == undefined) {
      return <React.Fragment/>;
    } else if (apps.length > 0) {
      return getAppsCards();
    } else {
      return getNoAppsCard();
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '90vh'
        }}>
        <Helmet>
          <title>Home | Rajmahal Apps</title>
        </Helmet>
        {getApps()}
        {getSnackbar}
        <Backdrop open={isLoading}>
          <CircularProgress style= {{ color: blue[200] }} />
        </Backdrop>
      </Box>
    </React.Fragment>
  );
};

export default Home;
