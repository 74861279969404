import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Backdrop, CircularProgress, Typography, Grid, IconButton,
  Dialog, DialogContent, DialogActions, Box, Tooltip, Card, CardHeader, CardContent, AppBar, Toolbar, Slide, Container } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { blue } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { Utils } from '../../../utils/UtilFunctions';
import CustomSnackbar from '../../../common/components/CustomSnackbar';
import Services from '../../../utils/Services';
import APIData from '../../../utils/APIData';
import { validateStringForNull } from '../../../utils/FieldValidations';
import CloseIcon from '@mui/icons-material/Close';
import GrantAppPermisssion from './GrantAppPermisssion';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ActionToolbar = ({ user, actions }) => {
  const getContent = () => {
    return (
      <GridToolbarContainer>
        <Tooltip key='Edit' title='Edit'>
          <IconButton aria-label="Edit" sx={{ ml: 1, color: 'primary.main' }} onClick={() => actions.editClick()}>
            <EditIcon sx={{ fontSize: '20px', mr: 1 }} />
            <Typography variant='h6'>
              Edit
            </Typography>
          </IconButton>
        </Tooltip>
        {user.is_active ? (
          <Tooltip key='Delete' title='Delete'>
            <IconButton aria-label="Delete" sx={{ ml: 1, color: '#d32f2f' }} onClick={() => actions.deleteClick()}>
              <DeleteIcon sx={{ fontSize: '20px', mr: 1 }} />
              <Typography variant='h6'>
                Delete
              </Typography>
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip key='Activate' title='Activate'>
            <IconButton aria-label="Activate" sx={{ ml: 1, color: '#4E9F3D' }} onClick={() => actions.activateClick()}>
              <TaskAltIcon sx={{ fontSize: '20px', mr: 1 }} />
              <Typography variant='h6'>
                Activate
              </Typography>
            </IconButton>
          </Tooltip>
        )}
      </GridToolbarContainer>
    );
  };

  return (user != undefined ? getContent() : '');
};

ActionToolbar.propTypes = {
  user: PropTypes.object,
  actions: PropTypes.object
};

const UserList = ({ refreshId }) => {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveUserDialogOpen, setIsRemoveUserDialogOpen] = useState(false);
  const [isUserDetailsDialogOpen, setIsUserDetailsDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState();
  const APIToken = {
    LIST_USERS: 'CUL01',
    ACTIVATE_USER: 'CUL02',
    // MODIFY_USER: 'CUL03',
    DELETE_USER: 'CUL04'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    raiseGetUserListRequest();
  }, [refreshId]);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.listUsers && apiToken == APIToken.LIST_USERS) {
      response.data.map((obj) => obj.id = obj.user_id);
      setRows(response.data);
      setIsLoading(false);
    } else if (apiData == APIData.deleteUser && apiToken == APIToken.DELETE_USER) {
      showSnackBar('success', response.message ?? 'User deleted successfully');
      raiseGetUserListRequest();
    } else if (apiData == APIData.activateUser && apiToken == APIToken.ACTIVATE_USER) {
      showSnackBar('success', response.message ?? 'Activation successful');
      raiseGetUserListRequest();
    }
    // else if (apiData == APIData.modifyUser && apiToken == APIToken.MODIFY_USER) {
    //   showSnackBar('success', res.message ?? 'User Modified successful');
    //   raiseGetUserListRequest();
    // }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.listUsers && apiToken == APIToken.LIST_USERS) {
      defaultMsg = 'Failed to retrieve user list';
    } else if (apiData == APIData.deleteUser && apiToken == APIToken.DELETE_USER) {
      defaultMsg = 'Failed to delete user';
    } else if (apiData == APIData.activateUser && apiToken == APIToken.ACTIVATE_USER) {
      defaultMsg = 'Failed to activate user';
    }
    // else if (apiData == APIData.modifyUser && apiToken == APIToken.MODIFY_USER) {
    //   defaultMsg = 'failed to modify user';
    // }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetUserListRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.listUsers },
      APIToken.LIST_USERS, processSuccess, processError);
  };

  const raiseRemoveUserRequest = () => {
    setIsLoading(true);
    setIsRemoveUserDialogOpen(false);
    Services.sendBackendRequest({ apiData: APIData.deleteUser, uriValues: [selectedUserId] },
      APIToken.DELETE_USER, processSuccess, processError);
  };

  const raiseActivateUserRequest = () => {
    if (!validateStringForNull(selectedUserId)) {
      return;
    }
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.activateUser, uriValues: [selectedUserId] },
      APIToken.ACTIVATE_USER, processSuccess, processError);
  };

  // const raiseModifyUserRequest = (userId, params) => {
  //   setIsLoading(true);
  //   Services.sendBackendRequest({ apiData: APIData.modifyUser, uriValues: [userId], params: params },
  //     APIToken.MODIFY_USER, processSuccess, processError);
  // };

  // const getValuesToModify = (originalUser, modifiedUser) => {
  //   const result = {};
  //   Object.keys(modifiedUser).forEach((key) => {
  //     if (originalUser[key] !== modifiedUser[key]) {
  //       if (!validateStringForNull(modifiedUser[key])) {
  //         throw new Error('Invalid Inputs');
  //       }
  //       result[key] = modifiedUser[key];
  //     }
  //   });
  //   return result;
  // };

  // const handleModifyUserComplete = (modifiedUser, users) => {
  //   setIsLoading(true);
  //   try {
  //     const originalUser = users.filter((obj) => obj.user_id == modifiedUser.user_id)[0];
  //     const params = getValuesToModify(originalUser, modifiedUser);
  //     raiseModifyUserRequest(modifiedUser.user_id, params);
  //   } catch (err) {
  //     showSnackBar('error', err.message ?? 'Failed to create user');
  //     setIsLoading(false);
  //   }
  // };

  const handleRemoveUserDialogOpen = () => {
    setIsRemoveUserDialogOpen(true);
  };

  const handleRemoveUserDialogClose = () => {
    setIsRemoveUserDialogOpen(false);
  };

  const handleRemoveUserClick = () => {
    if (validateStringForNull(selectedUserId)) {
      raiseRemoveUserRequest();
    }
  };

  const handleUserDetailsDialogOpen = () => {
    setIsUserDetailsDialogOpen(true);
  };

  const handleUserDetailsDialogClose = () => {
    setIsUserDetailsDialogOpen(false);
  };

  const getRemoveUserConfirmationDialog = () => {
    return (
      <Box>
        <Dialog
          open={isRemoveUserDialogOpen}
          PaperProps={{
            style: { borderRadius: 20 }
          }}
        >
          <DialogContent>
            <Typography variant='h4' textAlign='center' sx={{ mb: 1 }}>
              <strong>Remove User</strong>
            </Typography>
            <Typography variant='h5'>
              Are you sure you want to remove this user?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
            <Button
              size='small'
              color='primary'
              onClick={handleRemoveUserDialogClose}
              sx={{ mr: 8, borderRadius: 15, fontSize: '13px' }}
            >
              Cancel
            </Button>
            <Button
              size='small'
              color='primary'
              variant='contained'
              onClick={() => handleRemoveUserClick()}
              sx={{
                borderRadius: 15,
                fontSize: '12px'
              }}
            >
              Remove User
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  const handleRowSelectionChange = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel);
    setSelectedUserId(newRowSelectionModel.length > 0 ? newRowSelectionModel[0] : '');
  };

  const getSelectedUser = () => {
    if (rows == undefined) {
      return undefined;
    } else if (!validateStringForNull(selectedUserId)) {
      return undefined;
    } else {
      return rows.filter((row) => row.user_id == selectedUserId)[0];
    }
  };

  const columns = [
    { field: 'user_id', headerName: 'User Id' },
    { field: 'user_name', headerName: 'User Name', minWidth: 180, editable: false },
    { field: 'phone', headerName: 'Phone', minWidth: 150, editable: false },
    { field: 'is_active', headerName: 'Status', minWidth: 100,
      renderCell: ((params) => {
        if (params.row.is_active == true) {
          return (
            <Tooltip title='Active'>
              <IconButton
                sx={{ color: '#4E9F3D' }}
              >
                <TaskAltIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title='InActive'>
              <IconButton
                sx={{ color: '#950101' }}
              >
                <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          );
        }
      })
    }
  ];

  const onSuccessfulCommit = () => {
    raiseGetUserListRequest();
  };

  const getUserDetailsDialog = () => {
    return (
      <Dialog
        fullScreen
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }
        }}
        open={isUserDetailsDialogOpen}
        TransitionComponent={Transition}
        onClose={() => {}}
        PaperProps={{
          sx: {
            width: {
              lg: '50%'
            }
          }
        }}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleUserDetailsDialogClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              User Details
            </Typography>
          </Toolbar>
        </AppBar>
        <Container sx={{ p: 1 }}>
          {selectedUserId != undefined ? <GrantAppPermisssion userId={selectedUserId} onSuccessfulCommit={onSuccessfulCommit} /> : ''}
        </Container>
      </Dialog>
    );
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader
        title="Manage Users"
      />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <DataGrid
              sx={{ minHeight: 400, width: 'auto' }}
              rows={rows}
              columns={columns}
              disableColumnSelector
              rowModesModel={rowModesModel}
              onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
              onRowSelectionModelChange={(newRowSelectionModel) => handleRowSelectionChange(newRowSelectionModel)}
              rowSelectionModel={rowSelectionModel}
              slots={{ toolbar: ActionToolbar }}
              slotProps={{
                toolbar: {
                  user: getSelectedUser(),
                  actions: {
                    editClick: handleUserDetailsDialogOpen,
                    deleteClick: handleRemoveUserDialogOpen,
                    activateClick: raiseActivateUserRequest
                  }
                }
              }}
              disableMultipleRowSelection={true}
              columnVisibilityModel={{
                user_id: false
              }}
            />
          </Grid>
        </Grid>
        {getRemoveUserConfirmationDialog()}
        {getSnackbar}
        <Backdrop open={isLoading}>
          <CircularProgress style= {{ color: blue[200] }} />
        </Backdrop>
        {getUserDetailsDialog()}
      </CardContent>
    </Card>
  );
};

UserList.propTypes = {
  refreshId: PropTypes.number.isRequired
};

export default UserList;
