import { useState } from 'react';
import { ANALYSIS_COMPONENTS } from '../../../../utils/EnumDefinitions';
import ActivePlanList from './ActivePlanList';
import PlanDetails from './PlanDetails';
import PlanOrders from './PlanOrders';
import GetOrderDetails from './GetOrderDetails';
import OrderListByProduct from './OrderListByProduct';
import OrderListBySerial from './OrderListBySerial';

const AnalysisHome = () => {
  const [compDetailsList, setCompDetailsList] = useState([{
    component_id: ANALYSIS_COMPONENTS.active_plans
  }]);
  const [componentDetails, setComponentDetails] = useState(compDetailsList[0]);

  const handleSubmit = (obj) => {
    setCompDetailsList([...compDetailsList, obj]);
    setComponentDetails(obj);
  };

  const handleBack = () => {
    if (compDetailsList.length > 1) {
      compDetailsList.pop();
      setCompDetailsList(compDetailsList);
      setComponentDetails(compDetailsList[compDetailsList.length - 1]);
    }
  };

  const getComponent = () => {
    switch (componentDetails.component_id) {
    case ANALYSIS_COMPONENTS.active_plans:
      return <ActivePlanList onSubmit={handleSubmit} />;
    case ANALYSIS_COMPONENTS.plan_details:
      return <PlanDetails planId={componentDetails.plan_id} onSubmit={handleSubmit} onBack={handleBack} />;
    case ANALYSIS_COMPONENTS.plan_orders:
      return <PlanOrders planId={componentDetails.plan_id} onSubmit={handleSubmit} onBack={handleBack} />;
    case ANALYSIS_COMPONENTS.order_details:
      return <GetOrderDetails orderId={componentDetails.order_id} onSubmit={handleSubmit} onBack={handleBack} />;
    case ANALYSIS_COMPONENTS.orders_by_product:
      return <OrderListByProduct planId={componentDetails.plan_id} prodId = {componentDetails.prod_id}
        materialType={componentDetails.material_type} onSubmit = {handleSubmit} onBack={handleBack} />;
    case ANALYSIS_COMPONENTS.orders_by_serial:
      return <OrderListBySerial planId={componentDetails.plan_id}
        planSerial={componentDetails.plan_serial} onSubmit={handleSubmit} onBack={handleBack} />;
    };
  };

  return getComponent();
};

export default AnalysisHome;
