import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Container, CircularProgress, Grid, TextField, Card,
  Table, TableHead, TableContainer, Paper, TableCell, TableBody,
  TableRow, IconButton, Typography, Tooltip } from '@mui/material';
import { Utils } from '../../../../utils/UtilFunctions';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { validateStringForNull } from '../../../../utils/FieldValidations';
import Services from '../../../../utils/Services';
import APIData from '../../../../utils/APIData';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ANALYSIS_COMPONENTS } from '../../../../utils/EnumDefinitions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const OrderListBySerial = ({ planId, planSerial, onSubmit, onBack }) => {
  const [OrderDetails, setOrderDetails] = useState({
    order_data: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const APIToken = {
    GET_ORDERS_BY_SERIAL: 'COLS01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (validateStringForNull(planId)) {
      raiseGetOrdersRequest(planId);
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getOrderListBySerial && apiToken == APIToken.GET_ORDERS_BY_SERIAL ) {
      if (response.data != undefined) {
        populatePlanData(response.data);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getOrderListBySerial && apiToken == APIToken.GET_ORDERS_BY_SERIAL) {
      defaultMsg = 'Failed to retrieve Order Details';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetOrdersRequest = (planId) => {
    const params = 'plan_id=' + planId + '&plan_serial=' + planSerial;
    Services.sendBackendRequest({ apiData: APIData.getOrderListBySerial, params: params },
      APIToken.GET_ORDERS_BY_SERIAL, processSuccess, processError);
  };

  const populatePlanData = (data) => {
    const obj = {};
    data.forEach((item) => {
      const key = `${item.order_id}`;
      if (!obj[key]) {
        obj[key] = {
          id: Object.keys(obj).length + 1,
          order_id: item.order_id,
          ac_name: item.ac_name,
          sub_data: []
        };
      }
      obj[key].sub_data.push({
        id: obj[key].sub_data.length + 1,
        model_name: item.model_name,
        brand_name: item.brand_name,
        size_id: item.size_id,
        model_id: item.model_id,
        brand_id: item.brand_id,
        buy_price: item.buy_price,
        bought_quantity: item.bought_quantity
      });
    });
    const filteredData = Object.values(obj);
    setOrderDetails({
      ...OrderDetails,
      order_data: filteredData
    });
  };

  const getMaterialUIContent = () => {
    if (OrderDetails.order_data.length != 0) {
      return getPlanUIContent(OrderDetails.material_type);
    };
  };

  const handleOrderDetailsClick = (orderId) => {
    const obj={
      component_id: ANALYSIS_COMPONENTS.order_details,
      order_id: orderId
    };
    onSubmit(obj);
  };

  const handlePlanIdClick = () => {
    const obj={
      component_id: ANALYSIS_COMPONENTS.plan_details,
      plan_id: planId
    };
    onSubmit(obj);
  };

  const getPlanUIContent = () => {
    const gridArr = [];
    OrderDetails.order_data.map((readyMadePlan, i) => {
      gridArr.push(
        <Grid item xs={12} key={readyMadePlan.id}>
          <Card elevation={12}>
            <Grid key={readyMadePlan.id} container>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
                sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="Order Id"
                    label="Order Id"
                    name="order_id"
                    autoComplete ='off'
                    variant='standard'
                    value={readyMadePlan.order_id}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="supplier Name"
                    label="Supplier Name"
                    name="ac_name"
                    autoComplete ='off'
                    variant='standard'
                    value={readyMadePlan.ac_name}
                    size='small'
                    fullWidth
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2} display='flex' justifyContent='flex-end'>
                  <Tooltip title='View Order'>
                    <IconButton
                      color='primary'
                      onClick={() => handleOrderDetailsClick(readyMadePlan.order_id)}
                      sx={{ p: 1 }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Model</TableCell>
                      <TableCell>Brand</TableCell>
                      <TableCell>Size</TableCell>
                      <TableCell>Buy Price</TableCell>
                      <TableCell>Bought Qty</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {readyMadePlan.sub_data.map((planSubData, j) => (
                      <TableRow key={planSubData.id}>
                        <TableCell>{planSubData.model_name}</TableCell>
                        <TableCell>{planSubData.brand_name}</TableCell>
                        <TableCell>{planSubData.size_id}</TableCell>
                        <TableCell>{planSubData.buy_price}</TableCell>
                        <TableCell>{planSubData.bought_quantity}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Card>
        </Grid>
      );
    });
    return gridArr;
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
        <Grid item xs={1}>
          <Tooltip title='Back'>
            <IconButton
              color='primary'
              onClick={onBack}
              sx={{ p: 1 }}
            >
              <ArrowBackIcon/>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={11} sx={{ mb: 2 }}>
          <Typography variant='h5' align='center'>
            Analysis - Orders By Range
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="Plan Id"
            label="Plan Id"
            name="plan_id"
            autoComplete ='off'
            value={planId}
            size='small'
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Tooltip title='View Orders By Plan'>
            <IconButton
              color='primary'
              onClick={handlePlanIdClick}
              sx={{ p: 1 }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        {getMaterialUIContent()}
      </Grid>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

OrderListBySerial.propTypes = {
  planId: PropTypes.string,
  planSerial: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
};

export default OrderListBySerial;
