import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';

const TableModal = ( { handleSalesRange, handleModalClose, priceRange } ) => {
  const [rows, setRows] = useState([
    { id: 1, from: '', to: '' }
  ]);

  const convertPriceRange = () => {
    if (priceRange!=undefined && priceRange!='') {
      const rangePair = priceRange.split(',');
      const tempArray = [];
      for (let i = 0; i < rangePair.length; i++) {
        const rangeElement = rangePair[i].split('-');
        tempArray[i]={
          id: i+1,
          from: rangeElement[0],
          to: rangeElement[1]
        };
      }
      setRows(tempArray);
    };
  };

  useEffect(() => {
    convertPriceRange();
  }, []);

  const handleAdd = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1, from: '',
        to: ''
      }
    ]);
  };

  const handleSubmit = () => {
    handleSalesRange(rows);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...rows];
    list[index][name] = value;
    setRows(list);
  };

  const handleRemoveClick = (i) => {
    const list = [...rows];
    if (list.length>1) {
      list.splice(i, 1);
    } else {
      alert('Cannot delete! Minimum range limit=1');
    };
    setRows(list);
  };

  return (
    <React.Fragment>
      <Box sx={{ minWidth: 300, maxHeight: 400, overflowY: 'scroll' }}>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>
                    <TextField variant="outlined"
                      value={row.from}
                      name='from'
                      autoFocus={i + 1 == rows.length}
                      onChange={(e) => handleInputChange(e, i)}
                      size='small'
                    />
                  </TableCell>
                  <TableCell>
                    <TextField variant="outlined"
                      value={row.to}
                      name='to'
                      onChange={(e) => handleInputChange(e, i)}
                      size='small'
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={handleAdd} color='success'>
                      <AddBoxIcon style={{ fontSize: 30, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                    <IconButton onClick={() => {handleRemoveClick(i);}} color='error' disabled={rows.length == 1}>
                      <DeleteIcon style={{ fontSize: 30, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <div style={{ display: 'flex' }}>
        <Button type='submit' onClick={handleModalClose} color='error'
          sx={{ marginLeft: 'auto', marginTop: 2 }} variant='outlined'>
            CLOSE
        </Button>
        <Button type='submit' onClick={handleSubmit}
          sx={{ marginRight: 2, marginLeft: 2, marginTop: 2 }} variant='contained'>
            SET SALES RANGE
        </Button>
      </div>
    </React.Fragment>
  );
};

TableModal.propTypes = {
  handleModalClose: PropTypes.func,
  handleSalesRange: PropTypes.func,
  priceRange: PropTypes.string
};

export default TableModal;
