import { Helmet } from 'react-helmet';
import { Box, Backdrop,
  CircularProgress, Container, Grid, MenuItem, FormControl,
  InputLabel, Select, Button, Typography, Card, CardContent, Divider, Switch, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import SalesTotalCard from '../components/dashboard/SalesTotalCard';
import SalesBarChart from '../components/dashboard/SalesBarChart';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { red, blue, green } from '@mui/material/colors';
import CustomSnackbar from '../../../common/components/CustomSnackbar';
import DeductablesCard from '../components/dashboard/DeductablesCard';
import Services from '../../../utils/Services';
import APIData from '../../../utils/APIData';
import { TREND_TYPE } from '../../../utils/EnumDefinitions';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { validateDate } from '../../../utils/FieldValidations';
import CustomTree from '../components/dashboard/CustomTree';
import SalesSplitupCard from '../components/dashboard/SalesSplitupCard';
import SalesDonutChart from '../components/dashboard/SalesDonutChart';
import moment from 'moment';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#205295'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#205295',
    boxSizing: 'border-box'
  }
}));

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [trend, setTrend] = useState(1);
  const [filterDate, setFilterDate] = useState({ start_date: null, end_date: null });
  const [dashboardData, setDashboardData] = useState(undefined);
  const [shopList, setShopList] = useState(undefined);
  const [selectedShop, setSelectedShop] = useState(undefined);
  const [selectedData, setSelectedData] = useState(undefined);
  const [selectedSplitup, setSelectedSplitup] = useState(2);
  const [selectedDeductables, setSelectedDeductables] = useState(2);
  const [dataPath, setDataPath] = useState('date_range=1');
  const [reloadIndex, setReloadIndex] = useState(1);
  const [splitupChoice, setSplitupChoice] = useState('sales');
  const REFRESH_TIME = 300000;
  const APIToken = {
    GET_DASH_BOARD: 'PDB01'
  };

  const snackBarStatusDefault = {
    open: false,
    severity: 'info',
    message: ''
  };
  const [snackBarStatus, setSnackBarStatus] = useState(snackBarStatusDefault);
  const handleSnackBarClose = () => {
    setSnackBarStatus(snackBarStatusDefault);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const getServicePath = (selectedTrend) => {
    let servicePath = undefined;
    if (selectedTrend != 7) {
      servicePath = 'date_range=' + selectedTrend;
      setDataPath(servicePath);
    } else {
      servicePath = 'from_date='+ moment(filterDate.start_date).format('yyyy-MM-DD')+ '&to_date=' + moment(filterDate.end_date).format('yyyy-MM-DD');
      setDataPath(servicePath);
    }
    return servicePath;
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getDashboard && apiToken == APIToken.GET_DASH_BOARD ) {
      setDashboardData(response.data);
      const shopNames = {};
      const shopData = {};
      response.data.map((data) => {
        const shopName = data.shop_name;
        const shopId = data.shop_id;
        shopNames[shopId] = shopName;
        shopData[shopId] = data;
      });
      let shopSelected = undefined;
      if (callbackValues.selectedShopId == undefined) {
        setSelectedShop(response.data.length > 0 ? response.data[0].shop_id : undefined);
        shopSelected = response.data.length > 0 ? response.data[0].shop_id : undefined;
      } else {
        shopSelected = callbackValues.selectedShopId;
      }
      setSelectedData(shopData[shopSelected]);
      setDashboardData(shopData);
      setShopList(shopNames);
      setReloadIndex((reloadIndex) => reloadIndex + 1);
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    if (apiData == APIData.getDashboard && apiToken == APIToken.GET_DASH_BOARD) {
      setSnackBarStatus({
        open: true,
        severity: 'error',
        message: err.message ?? 'Failed to fetch dashboard data'
      });
      setSelectedData(undefined);
      setSelectedShop(undefined);
      setDashboardData(undefined);
      setShopList(undefined);
    }
    setIsLoading(false);
  };

  const getDashboardData = (showLoadingIndicator, path, selectedShopId) =>{
    if (showLoadingIndicator === true) {
      setIsLoading(true);
    }
    Services.sendBackendRequest({ apiData: APIData.getDashboard, params: path },
      APIToken.GET_DASH_BOARD, processSuccess, processError, { selectedShopId: selectedShopId });
  };

  // render at interval
  useEffect(() => {
    const interval = setInterval(() => {
      getDashboardData(false, dataPath, selectedShop);
    }, REFRESH_TIME);
    return () => clearInterval(interval);
  }, [dataPath, selectedShop]);

  // Intial render
  useEffect(() => {
    setIsLoading(true);
    getDashboardData(true, dataPath, selectedShop);
  }, []);

  const handleDateChange = (date, name) => {
    setFilterDate({ ...filterDate, [name]: date });
  };

  const getTrendItems =() => {
    const choiceArray = [];
    Object.keys(TREND_TYPE).map((key) => {
      choiceArray.push(<MenuItem value={key} key={key}>{TREND_TYPE[key]}</MenuItem>);
    });
    return choiceArray;
  };

  const getShopList =() => {
    const choiceArray = [];
    Object.keys(shopList).map((shopId) => {
      choiceArray.push(<MenuItem value={shopId} key={shopId}>{shopList[shopId]}</MenuItem>);
    });
    return choiceArray;
  };

  const handleChoiceChange = (event) => {
    setTrend(event.target.value);
    if (event.target.value != 7) {
      const servicePath = getServicePath(event.target.value, false);
      getDashboardData(true, servicePath);
    }
  };

  const handleShopChoiceChange = (event) => {
    setSelectedShop(event.target.value);
    setSelectedData(dashboardData[event.target.value]);
    setReloadIndex((reloadIndex) => reloadIndex + 1);
  };

  const validateParams = () =>{
    if (!validateDate(filterDate.start_date)) {
      throw new Error('Invalid Start Date');
    } else if (!validateDate(filterDate.end_date)) {
      throw new Error('Invalid End Date');
    } else if (filterDate.end_date.isBefore(filterDate.start_date)) {
      throw new Error('Invalid End Date');
    }
  };

  const handleSubmit = () => {
    try {
      validateParams();
      const servicePath = getServicePath(7);
      getDashboardData(true, servicePath);
    } catch (err) {
      setSnackBarStatus({
        open: true,
        severity: 'error',
        message: err.message
      });
    }
  };

  const getInputSection = () => {
    return (
      <Grid
        item container
        rowSpacing={2}
        p={0}
        px={1}
        sx={{ backgroundColor: '#FFFFFF' }}
      >
        <Grid
          item container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          columnSpacing={2}
          rowSpacing={2}
          xs={12}
          md={4}
          lg={7.25}
          pb={2}
        >
          <Grid item xs={12} sm={5} md={12}>
            {shopList != undefined ?
              <FormControl sx={{ width: { xs: '100%', md: 'auto', minWidth: '200px' } }}>
                <InputLabel size="small" id="shop-select-label">Shop</InputLabel>
                <Select
                  labelId="shop-select-label"
                  id="shop-select"
                  label="Shop"
                  name='shop'
                  value={selectedShop}
                  onChange={handleShopChoiceChange}
                  size="small"
                >
                  {getShopList()}
                </Select>
              </FormControl> : ''}
          </Grid>
        </Grid>
        <Grid
          item container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          columnSpacing={2}
          rowSpacing={2}
          xs={12}
          md={8}
          lg={4.5}
          pb={2}
        >
          <Grid item xs={12} sm={3} lg={3}>
            <FormControl fullWidth>
              <InputLabel size="small" id="session-id-select-label">Trend</InputLabel>
              <Select
                labelId="session-id-select-label"
                id="trend-select"
                label="Trend"
                name='trend'
                value={trend}
                onChange={handleChoiceChange}
                size="small"
              >
                {getTrendItems()}
              </Select>
            </FormControl>
          </Grid>
          {trend == 7 ? (
            <Grid item xs={5.5} sm={3.5} lg={3.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label = 'Start Date'
                  format="DD/MM/YYYY"
                  disableFuture
                  maxDate={filterDate.end_date}
                  value={filterDate.start_date}
                  onChange= {(date) => handleDateChange(date, 'start_date')}
                  slotProps={{ textField: { variant: 'outlined', size: 'small', error: false } }}
                />
              </LocalizationProvider>
            </Grid> ): ''}
          {trend == 7 ? (
            <Grid item xs={1} sm={0.5} lg={0.5}>
              <Typography variant='div' justifyContent={'center'} display='flex'> - </Typography>
            </Grid>
          ) : ''}
          {trend == 7 ? (
            <Grid item xs={5.5} sm={3.5} lg={3.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="End Date"
                  format="DD/MM/YYYY"
                  disableFuture
                  minDate={filterDate.start_date}
                  value={filterDate.end_date}
                  onChange= {(date) => handleDateChange(date, 'end_date')}
                  slotProps={{ textField: { variant: 'outlined', size: 'small', error: false } }}
                />
              </LocalizationProvider>
            </Grid>
          ) : ''}
          {trend == 7 ? (
            <Grid item xs={12} sm={1.5} lg={1.5} justifyContent='flex-end' display='flex'>
              <Button type='submit' size='small' variant='contained' onClick={handleSubmit}>Get</Button>
            </Grid>
          ) : ''}
        </Grid>
      </Grid>
    );
  };

  const getTotalSalesSection = () => {
    return (selectedData != undefined ? (
      <React.Fragment>
        <Grid
          item
          lg={3}
          sm={6}
          xs={12}
        >
          <SalesTotalCard cardIcon={{ color: green[300], icon: CurrencyRupeeIcon }}
            cardTitle='Net Sales'
            data={[
              { title: 'Sales', value: selectedData.sales.total },
              { title: 'Sales Return', value: selectedData.sales_return.total },
              { title: 'Net Sales', value: selectedData.net_sales.total }
            ]} reloadIndex={reloadIndex}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xs={12}
        >
          <SalesTotalCard cardIcon={{ color: blue[300], icon: ReceiptIcon }}
            cardTitle='Bills Receivables'
            extraData= {{ title: 'Sales Bill Value', value: selectedData.sales_bill_value }}
            data={[
              { title: 'Sales', value: selectedData.sales.total },
              { title: 'Unpaid Bills', value: selectedData.unpaid_bills.total },
              { title: 'Bills Receivables', value: selectedData.bills_receivables.total }
            ]} reloadIndex={reloadIndex}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xs={12}
        >
          <SalesTotalCard cardIcon={{ color: green[300], icon: CurrencyRupeeIcon }}
            cardTitle='Net Receivables'
            data={[
              { title: 'Bills Receivables', value: selectedData.bills_receivables.total },
              { title: 'Deductible Receipts', value: selectedData.deductible_receipts.total },
              { title: 'Net Receivables', value: selectedData.net_receivables }
            ]} reloadIndex={reloadIndex}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xs={12}
        >
          <SalesTotalCard cardIcon={{ color: red[300], icon: CurrencyRupeeIcon }}
            cardTitle='Revenue Leakage'
            data={[
              { title: 'Net Receivables', value: selectedData.net_receivables },
              { title: 'Payment Received', value: selectedData.payment_received.total },
              { title: 'Difference', value: selectedData.payment_difference }
            ]} reloadIndex={reloadIndex}
          />
        </Grid>
      </React.Fragment>
    ) : ''
    );
  };

  const getKeyValue = (data) => {
    const keyValue = {};
    data.map((obj) => {
      keyValue[obj.item_name] = obj.amount;
    });
    return keyValue;
  };

  const getSalesSplitupData = () => {
    const splitupData = [];
    splitupData.push({ name: 'Sales', data: getKeyValue(selectedData.sales.split_up) });
    splitupData.push({ name: 'Sales Return', data: getKeyValue(selectedData.sales_return.split_up) });
    splitupData.push({ name: 'Net Sales', data: getKeyValue(selectedData.net_sales.split_up) });
    splitupData.push({ name: 'Unpaid Bills', data: getKeyValue(selectedData.unpaid_bills.split_up) });
    splitupData.push({ name: 'Bill Receivables', data: getKeyValue(selectedData.bills_receivables.split_up) });
    return splitupData;
  };

  const getSalesSplitupDataColumns = () => {
    const arr = [];
    selectedData.sales.split_up.map((obj) => {
      arr.push(obj.item_name);
    });
    return arr;
  };

  const choiceArray = ['sales', 'sales_return', 'net_sales', 'unpaid_bills', 'bills_receivables'];
  const getBarchartConvertedData = (data) => {
    const convertedObject = {};
    const itemArray = [];
    for (let i = 0; i < choiceArray.length; i++) {
      const choice = data[choiceArray[i]].split_up;
      convertedObject[choiceArray[i]] ={};
      for (let x = 0; x < choice.length; x++) {
        if (i == 0) {
          itemArray[x] = choice[x].item_name;
        }
        convertedObject[choiceArray[i]][choice[x].item_name] = choice[x].amount;
      };
    }
    convertedObject.itemName= itemArray;
    return convertedObject;
  };

  const getLabel = (label) => {
    const arr = label.split('_');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const finalString = arr.join(' ');
    return finalString;
  };

  const getMenuItems =() => {
    const choices = [];
    for (let i = 0; i < choiceArray.length; i++) {
      choices[i] = (
        <MenuItem key={i} value={choiceArray[i]}>{ getLabel(choiceArray[i]) }</MenuItem>
      );
    };
    return choices;
  };

  const getSalesSplitUp = () => {
    return (selectedData != undefined ? (
      <Grid
        item
        lg={6}
        sm={12}
        xs={12}
      >
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Grid container alignItems='center' sx={{ pb: 2 }} columnGap={2} rowGap={2}>
              <Grid item xs={12} lg={6}>
                <Typography variant='h5'> Sales Split-up </Typography>
              </Grid>
              <Grid item xs={5.5} lg={3}>
                {selectedSplitup == 2 ? (
                  <FormControl fullWidth >
                    <Select
                      value={splitupChoice}
                      sx={{ maxHeight: 35 }}
                      variant = 'standard'
                      onChange={(event) => {
                        setSplitupChoice(event.target.value);
                        setReloadIndex((reloadIndex) => reloadIndex + 1);
                      }
                      }>
                      {getMenuItems()}
                    </Select>
                  </FormControl>
                ) : ''}
              </Grid>
              <Grid item xs={5.5} lg={2.5}>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                  <Typography>Table</Typography>
                  <AntSwitch checked={selectedSplitup == 2} onChange={(event) => {
                    setSelectedSplitup(event.target.checked ? 2 : 1);
                    setReloadIndex((reloadIndex) => reloadIndex + 1);
                  }} />
                  <Typography>Chart</Typography>
                </Stack>
              </Grid>
            </Grid>
            <Divider />
            {selectedSplitup == 1 ?
              <SalesSplitupCard cardData={getSalesSplitupData()} columns={getSalesSplitupDataColumns()} reloadIndex={reloadIndex}/> :
              <SalesBarChart data={getBarchartConvertedData(selectedData)} choice={splitupChoice} title = 'Sales Splitup'
                reloadIndex={reloadIndex}/> }
          </CardContent>
        </Card>
      </Grid>
    ) : '' );
  };

  const getPaymentSplitUp = () => {
    return (selectedData != undefined ? (
      <Grid
        item
        lg={3}
        sm={6}
        xs={12}
      >
        <CustomTree data={selectedData.payment_received.split_up} title="Payments Received" reloadIndex={reloadIndex}/>
      </Grid>
    ) : '' );
  };

  const getDeductablesCard = () => {
    return (selectedData != undefined ? (
      <Grid
        item
        lg={3}
        sm={6}
        xs={12}
      >
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Grid container alignItems='center' sx={{ pb: 2 }}>
              <Grid item xs={9}>
                <Typography variant='h5'> Deductable Receipts </Typography>
              </Grid>
              <Grid item xs={3}>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                  <Typography>Table</Typography>
                  <AntSwitch checked={selectedDeductables == 2} onChange={(event) => {
                    setSelectedDeductables(event.target.checked ? 2 : 1);
                    setReloadIndex((reloadIndex) => reloadIndex + 1);
                  }} />
                  <Typography>Chart</Typography>
                </Stack>
              </Grid>
            </Grid>
            <Divider />
            {selectedDeductables == 1 ?
              <DeductablesCard cardData={ selectedData.deductible_receipts.split_up } reloadIndex={reloadIndex}/> :
              <SalesDonutChart cardData={ selectedData.deductible_receipts.split_up } reloadIndex={reloadIndex}/>}
          </CardContent>
        </Card>
      </Grid>
    ) : '' );
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Dashboard | Rajmahal Apps</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 1,
          minHeight: 'calc(100vh - 98px)'
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={1}
            mb={1}
            sx={{ maxHeight: 150 }}
          >
            {getInputSection()}
            {getTotalSalesSection()}
            {getSalesSplitUp()}
            {getDeductablesCard()}
            {getPaymentSplitUp()}
          </Grid>
        </Container>
      </Box>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </React.Fragment>
  );
};

export default Dashboard;
