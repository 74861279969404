import { Backdrop, Button, Chip, CircularProgress, Container, Grid, Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import { MATERIAL_TYPE, ORDER_REPORT_OPTIONS } from '../../../../utils/EnumDefinitions';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import APIData from '../../../../utils/APIData';
import moment from 'moment';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { Utils } from '../../../../utils/UtilFunctions';
import Services from '../../../../utils/Services';
import { validateDate } from '../../../../utils/FieldValidations';

const OrderReports = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportDetails, setReportDetails] = useState({
    from_date: null,
    to_date: null,
    report_option: 0,
    report_data: [],
    refresh_id: 0
  });
  const APIToken = {
    GET_ORDER_REPORTS: 'COR01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );
  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getOrderReports && apiToken == APIToken.GET_ORDER_REPORTS ) {
      if (response.data != undefined) {
        setReportDetails({ ...reportDetails, report_data: response.data });
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getOrderReports && apiToken == APIToken.GET_ORDER_REPORTS) {
      defaultMsg = 'Failed to Get Order Reports';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const handleGetReportClick = () => {
    setIsLoading(true);
    try {
      const finalParams = validateParams();
      raiseGetOrderReports(finalParams);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to Get Report');
      setIsLoading(false);
    }
  };

  const validateParams = () => {
    const finalParams = {};
    if (!validateDate(reportDetails.from_date)) {
      throw new Error('Select valid Start Date');
    }
    if (!validateDate(reportDetails.to_date)) {
      throw new Error('Select valid End Date');
    }
    if (reportDetails.report_option <= 0) {
      throw new Error('Select valid Report option');
    }
    finalParams.from_date = moment(reportDetails.from_date).format('yyyy-MM-DD');
    finalParams.to_date = moment(reportDetails.to_date).format('yyyy-MM-DD');
    finalParams.report_option = reportDetails.report_option;

    return finalParams;
  };

  const raiseGetOrderReports = (finalparams) => {
    setIsLoading(true);
    const params = 'from_date=' + finalparams.from_date + '&to_date=' + finalparams.to_date + '&report_options=' + finalparams.report_option;
    Services.sendBackendRequest({ apiData: APIData.getOrderReports, params: params },
      APIToken.GET_ORDER_REPORTS, processSuccess, processError);
  };

  const handleOrderInfoChipClick = (chipValue) => {
    if ((reportDetails.report_option & chipValue) === chipValue) {
      reportDetails.report_option = reportDetails.report_option - chipValue;
    } else {
      reportDetails.report_option = reportDetails.report_option + chipValue;
    }
    reportDetails.report_data = [];
    notifyReportValueChange();
  };

  const notifyReportValueChange = () => {
    setReportDetails({ ...reportDetails, refresh_id: reportDetails.refresh_id + 1 });
  };

  const renderRows = () => {
    return reportDetails.report_data.map((item, index) => (
      <TableRow key={index}>
        {(reportDetails.report_option == 1 || reportDetails.report_option == 3) ? (
          <>
            <TableCell>{item.order_id}</TableCell>
            <TableCell>{item.plan_id}</TableCell>
            <TableCell>{MATERIAL_TYPE[item.material_type]}</TableCell>
            <TableCell>{item.ac_name}</TableCell>
            <TableCell>{item.planned_quantity}</TableCell>
            <TableCell>{item.bought_quantity}</TableCell>
            <TableCell>{item.percent_covered}</TableCell>
          </>
        ) : ''}
        {reportDetails.report_option == 2 ? (
          <>
            <TableCell>{item.order_id}</TableCell>
            <TableCell>{MATERIAL_TYPE[item.material_type]}</TableCell>
            <TableCell>{item.ac_name}</TableCell>
            <TableCell>{item.bought_quantity}</TableCell>
          </>
        ) : ''}
      </TableRow>
    ));
  };

  const getTableData = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {(reportDetails.report_option == 1 || reportDetails.report_option == 3) ? (
                <>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Plan ID</TableCell>
                  <TableCell>Material</TableCell>
                  <TableCell>Supplier Name</TableCell>
                  <TableCell>Planned Qty</TableCell>
                  <TableCell>Bought Qty</TableCell>
                  <TableCell>% Covered</TableCell>
                </>
              ) : ''}
              {reportDetails.report_option === 2 ? (
                <>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Material</TableCell>
                  <TableCell>Supplier Name</TableCell>
                  <TableCell>Bought Qty</TableCell>
                </>
              ) : ''}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRows()}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography variant='h5' align='center'>
            Order Reports
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3} >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label="Start Date"
              format="DD/MM/YYYY"
              sx={{ width: '100%' }}
              disableFuture
              value={reportDetails.from_date}
              name="dob"
              onChange={(newValue) => {setReportDetails({ ...reportDetails, from_date: newValue });}}
              slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
              maxDate={reportDetails.to_date}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label="End Date"
              format="DD/MM/YYYY"
              sx={{ width: '100%' }}
              disableFuture
              value={reportDetails.to_date}
              name="dob"
              onChange={(newValue) => {setReportDetails({ ...reportDetails, to_date: newValue });}}
              slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
              minDate={reportDetails.from_date}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item container xs={12} sm={6}>
          <Grid item sx={{ mr: 1, display: 'flex', alignItems: 'center' }} >
            <Typography component='h5'>
              Report Options
            </Typography>
          </Grid>
          <Grid item >
            {Object.keys(ORDER_REPORT_OPTIONS ).map((type, i) => (
              <Chip sx={{ m: 1 }}
                key={i} label={ORDER_REPORT_OPTIONS[type]}
                onClick={() => handleOrderInfoChipClick(parseInt(type))}
                color={(reportDetails.report_option & parseInt(type)) == parseInt(type) ? 'primary' : undefined}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item container display='flex' justifyContent='flex-end' xs={12} sm={6} md={12}>
          <Button
            type='submit'
            variant="contained"
            onClick={handleGetReportClick}
            size='small'
          >
            Get Report
          </Button>
        </Grid>
        {reportDetails.report_data.length > 1 ? (
          <Grid item xs={12}>
            {getTableData()}
          </Grid>
        ) : '' }
      </Grid>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

export default OrderReports;
