import Dashboard from './apps/reports/pages/Dashboard';
import Login from './apps/iam/pages/Login';
import Logout from './apps/iam/pages/Logout';
import NotFound from './common/pages/NotFound';
import SalesByItemReports from './apps/reports/pages/SalesByItemReports';
import SalesByRangeReports from './apps/reports/pages/SalesByRangeReports';
import MyAccount from './apps/iam/pages/MyAccount';
import RajDiscount from './apps/discount/pages/RajDiscount';
import ManageUsers from './apps/iam/pages/ManageUsers';
import Home from './common/pages/Home';
import { Navigate } from 'react-router-dom';
import HomeLayout from './common/layouts/HomeLayout';
import AppLayout from './common/layouts/AppLayout';
import EmptyLayout from './common/layouts/EmptyLayout';
import StockReport from './apps/reports/pages/StockReport';
import Planning from './apps/orders/pages/Planning';
import DirectBooking from './apps/orders/pages/DirectBooking';
import Booking from './apps/orders/pages/Booking';
import NewEntities from './apps/orders/pages/NewEntities';
import OrderReport from './apps/orders/pages/OrderReport';
import Analysis from './apps/orders/pages/Analysis';
import Approvals from './apps/approvals/pages/Approvals';

const Routes = [
  {
    path: '/',
    element: <EmptyLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'logout', element: <Logout /> },
      { path: '*', element: <NotFound /> },
      { path: '', element: <Navigate to='/home' /> },
      { path: '404', element: <NotFound /> }
    ]
  },
  {
    path: '/home',
    element: <HomeLayout />,
    children: [
      { path: '', element: <Home /> },
      { path: '*', element: <Navigate to='/404' /> },
      { path: '404', element: <Navigate to='/404' /> },
      { path: 'my-account', element: <MyAccount /> }
    ]
  },
  {
    path: '/reports',
    element: <AppLayout appName='reports'/>,
    children: [
      { path: 'sales-by-item', element: <SalesByItemReports /> },
      { path: 'sales-by-range', element: <SalesByRangeReports/> },
      { path: 'stock', element: <StockReport/> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: '', element: <Dashboard /> },
      { path: '*', element: <Navigate to='/404' /> },
      { path: '404', element: <Navigate to='/404' /> },
      { path: 'my-account', element: <MyAccount /> }
    ]
  },
  {
    path: '/iam',
    element: <AppLayout appName='iam'/>,
    children: [
      { path: '', element: <ManageUsers /> },
      { path: '*', element: <Navigate to='/404' /> },
      { path: '404', element: <Navigate to='/404' /> },
      { path: 'my-account', element: <MyAccount /> },
      { path: 'discount', element: <RajDiscount /> },
      { path: 'manage-users', element: <ManageUsers /> }
    ]
  },
  {
    path: '/discount',
    element: <AppLayout appName='discount'/>,
    children: [
      { path: '', element: <RajDiscount /> },
      { path: '*', element: <Navigate to='/404' /> },
      { path: '404', element: <Navigate to='/404' /> },
      { path: 'my-account', element: <MyAccount /> },
      { path: 'home', element: <RajDiscount /> }
    ]
  },
  {
    path: '/orders',
    element: <AppLayout appName='orders'/>,
    children: [
      { path: '', element: <Planning /> },
      { path: '*', element: <Navigate to='/404' /> },
      { path: '404', element: <Navigate to='/404' /> },
      { path: 'my-account', element: <MyAccount /> },
      { path: 'planning', element: <Planning /> },
      { path: 'booking', element: <Booking /> },
      { path: 'direct-booking', element: <DirectBooking /> },
      { path: 'new-entities', element: <NewEntities /> },
      { path: 'order-report', element: <OrderReport /> },
      { path: 'order-analysis', element: <Analysis /> }
    ]
  },
  {
    path: '/approvals',
    element: <AppLayout appName='approvals'/>,
    children: [
      { path: '', element: <Approvals /> }
    ]
  }
];

export default Routes;
