const DISCOUNT_FEATURES = {
  VIEW_DISCOUNT: 'VIEW_DISCOUNT',
  CREATE_OR_UPDATE_DISCOUNT: 'CREATE_OR_UPDATE_DISCOUNT',
  REMOVE_DISCOUNT: 'REMOVE_DISCOUNT'
};

const validateFeature = (featureList, featureId) => {
  const arr = featureList.filter((feature) => feature.feature_id == featureId);
  if (arr == undefined || arr.length != 1) {
    return false;
  }
  return arr[0].is_enabled;
};

export {
  DISCOUNT_FEATURES, validateFeature
};
