import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Container, CircularProgress, Grid, TextField, Card,
  Table, TableHead, TableContainer, Paper, TableCell, TableBody,
  TableRow, IconButton, Typography, Tooltip } from '@mui/material';
import { Utils } from '../../../../utils/UtilFunctions';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { ANALYSIS_COMPONENTS, MATERIAL_TYPE } from '../../../../utils/EnumDefinitions';
import { validateStringForNull } from '../../../../utils/FieldValidations';
import Services from '../../../../utils/Services';
import APIData from '../../../../utils/APIData';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const GetOrderDetails = ({ orderId, onSubmit, onBack }) => {
  const [orderDetails, setOrderDetails] = useState({
    plan_id: '',
    order_date: '',
    ac_name: '',
    material_type: '',
    order_data: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const APIToken = {
    GET_ORDERS_FOR_ANALYSIS: 'CGOD01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (validateStringForNull(orderId)) {
      raiseGetOrderRequest(orderId);
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getOrderDetailsForAnalysis && apiToken == APIToken.GET_ORDERS_FOR_ANALYSIS ) {
      if (response.data != undefined) {
        populatePlanData(response.data);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getOrderDetailsForAnalysis && apiToken == APIToken.GET_ORDERS_FOR_ANALYSIS) {
      defaultMsg = 'Failed to retrieve Order Details';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetOrderRequest = (orderId) => {
    const params = 'order_id=' + orderId;
    Services.sendBackendRequest({ apiData: APIData.getOrderDetailsForAnalysis, params: params },
      APIToken.GET_ORDERS_FOR_ANALYSIS, processSuccess, processError);
  };

  const populatePlanData = (data) => {
    const materialType = data.material_type;
    const obj = {};
    if (materialType == 3) {
      data.order_data.forEach((item) => {
        const key = `${item.cate_id}_${item.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            cate_name: item.cate_name,
            cate_id: item.cate_id,
            prod_id: item.prod_id,
            prod_name: item.prod_name,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          model_name: item.model_name,
          brand_name: item.brand_name,
          model_id: item.model_id,
          brand_id: item.brand_id,
          size_id: item.size_id,
          planned_quantity: item.planned_quantity,
          quantity: item.quantity,
          plan_serial: item.plan_serial
        });
      });
    } else {
      data.order_data.forEach((item) => {
        const key = item.prod_id;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: item.prod_id,
            prod_name: item.prod_name,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          max_price: item.max_price,
          min_price: item.min_price,
          quantity: item.quantity,
          plan_serial: item.plan_serial,
          buy_price: item.buy_price,
          sell_price: item.sell_price,
          description: item.description
        });
      });
    }
    const filteredData = Object.values(obj);
    setOrderDetails({
      ...orderDetails,
      plan_id: data.plan_id,
      order_date: data.order_date,
      material_type: data.material_type,
      ac_name: validateStringForNull(data.ac_name) ? data.ac_name: '',
      order_data: filteredData
    });
  };

  const getMaterialUIContent = () => {
    if (orderDetails.order_data.length != 0) {
      if (orderDetails.material_type == 3) {
        return getReadyMadePlanUIContent(orderDetails.material_type);
      } else if (orderDetails.material_type == 2 || orderDetails.material_type == 1) {
        return getSilkAndPolysterPlanUIContent(orderDetails.material_type);
      } else {
        return '';
      }
    };
  };

  const handlePlanIdClick = () => {
    const obj={
      component_id: ANALYSIS_COMPONENTS.plan_details,
      plan_id: orderDetails.plan_id
    };
    onSubmit(obj);
  };

  const handleOrdersByProductClick = (prodId, materialType) => {
    const obj={
      component_id: ANALYSIS_COMPONENTS.orders_by_product,
      plan_id: orderDetails.plan_id,
      prod_id: prodId,
      material_type: materialType
    };
    onSubmit(obj);
  };

  const handleOrdersBySerialClick = (serial) => {
    const obj = {
      component_id: ANALYSIS_COMPONENTS.orders_by_serial,
      plan_id: orderDetails.plan_id,
      plan_serial: serial
    };
    onSubmit(obj);
  };

  const getReadyMadePlanUIContent = (materialType) => {
    const gridArr = [];
    orderDetails.order_data.map((readyMadePlan, i) => {
      gridArr.push(
        <Grid item xs={12} key={readyMadePlan.id}>
          <Card elevation={12}>
            <Grid key={readyMadePlan.id} container>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
                sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="category Name"
                    label="Category Name"
                    name="cate_name"
                    autoComplete ='off'
                    variant='standard'
                    value={readyMadePlan.cate_name}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="product Name"
                    label="Product Name"
                    name="prod_name"
                    autoComplete ='off'
                    variant='standard'
                    value={readyMadePlan.prod_name}
                    size='small'
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3} sm={1} display='flex' justifyContent='flex-end'>
                  <Tooltip title='Order By Product'>
                    <IconButton
                      color='primary'
                      onClick={() => handleOrdersByProductClick(readyMadePlan.prod_id, materialType)}
                      sx={{ p: 1 }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Model</TableCell>
                      <TableCell>Brand</TableCell>
                      <TableCell>Size</TableCell>
                      <TableCell>Planned Qty</TableCell>
                      <TableCell>Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {readyMadePlan.sub_data.map((planSubData, j) => (
                      <TableRow key={planSubData.id}>
                        <TableCell>{planSubData.model_name}</TableCell>
                        <TableCell>{planSubData.brand_name}</TableCell>
                        <TableCell>{planSubData.size_id}</TableCell>
                        <TableCell>{planSubData.planned_quantity}</TableCell>
                        <TableCell>
                          <Tooltip title='Orders By Range'>
                            <Typography variant='h6' color='red'
                              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                              onClick={() => handleOrdersBySerialClick(planSubData.plan_serial)}
                            >
                              {planSubData.quantity}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Card>
        </Grid>
      );
    });
    return gridArr;
  };

  const getSilkAndPolysterPlanUIContent = (materialType) => {
    const gridArr = [];
    orderDetails.order_data.map((silkAndPolysterPlan, i) => {
      gridArr.push(
        <Grid item xs={12} key={silkAndPolysterPlan.id}>
          <Card elevation={12}>
            <Grid container>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
                sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="prod_id"
                    label="Product Id"
                    name="prod_id"
                    autoComplete ='off'
                    value={silkAndPolysterPlan.prod_id}
                    size='small'
                    fullWidth
                    variant='standard'
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="prod_name"
                    label="Product Name"
                    name="prod_id"
                    autoComplete ='off'
                    value={silkAndPolysterPlan.prod_name}
                    size='small'
                    fullWidth
                    variant='standard'
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={1} display='flex' justifyContent='flex-end'>
                  <Tooltip title='Order By Product'>
                    <IconButton
                      color='primary'
                      onClick={() => handleOrdersByProductClick(silkAndPolysterPlan.prod_id, materialType)}
                      sx={{ p: 1 }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {materialType == 1 ? (
                        <React.Fragment>
                          <TableCell>Buy Price</TableCell>
                          <TableCell>Quantity</TableCell>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <TableCell>Description</TableCell>
                          <TableCell>Buy Price</TableCell>
                          <TableCell>Sell Price</TableCell>
                        </React.Fragment>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {silkAndPolysterPlan.sub_data.map((planSubData, j) => (
                      materialType == 1 ? (
                        <TableRow key={planSubData.id}>
                          <TableCell>{planSubData.buy_price}</TableCell>
                          <TableCell>
                            <Tooltip title='Orders By Range'>
                              <Typography variant='h6' color='red'
                                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => handleOrdersBySerialClick(planSubData.plan_serial)}
                              >
                                {planSubData.quantity}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow key={planSubData.id}>
                          <TableCell>{planSubData.description}</TableCell>
                          <TableCell>{planSubData.buy_price}</TableCell>
                          <TableCell>{planSubData.sell_price}</TableCell>
                          <TableCell>
                            <Tooltip title = 'Orders by Range'>
                              <Typography variant='h6' color='red'
                                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => handleOrdersBySerialClick(planSubData.plan_serial)}
                              >
                                {planSubData.quantity}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Card>
        </Grid>
      );
    });
    return gridArr;
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
        <Grid item xs={1}>
          <Tooltip title='Back'>
            <IconButton
              color='primary'
              onClick={onBack}
              sx={{ p: 1 }}
            >
              <ArrowBackIcon/>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={11} sx={{ mb: 2 }}>
          <Typography variant='h5' align='center'>
            Analysis - Order Details
          </Typography>
        </Grid>
        <Grid item xs={10} sm={3}>
          <TextField
            id="Plan Id"
            label="Plan Id"
            name="plan_id"
            autoComplete ='off'
            value={orderDetails.plan_id}
            size='small'
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={2} sm={1}>
          <Tooltip title='View Order by Plan'>
            <IconButton
              color='primary'
              onClick={handlePlanIdClick}
              sx={{ p: 1 }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={3.5}>
          <TextField
            id="ac_name"
            label="Supplier Name"
            name="ac_name"
            autoComplete ='off'
            value={orderDetails.ac_name}
            size='small'
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            id="Order Date"
            label="Order Date"
            name="order_date"
            autoComplete ='off'
            value={orderDetails.order_date}
            size='small'
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <TextField
            id="material_type"
            label="Material Type"
            name="material_type"
            autoComplete ='off'
            value={validateStringForNull(orderDetails.material_type) ? MATERIAL_TYPE[orderDetails.material_type] : ''}
            size='small'
            disabled={true}
            fullWidth
          />
        </Grid>
        {getMaterialUIContent()}
      </Grid>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

GetOrderDetails.propTypes = {
  orderId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
};

export default GetOrderDetails;
