import { Backdrop, Button, Card, Chip, CircularProgress, Divider, FormControl, Grid,
  IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { validateStringForNull } from '../../../../utils/FieldValidations';
import Services from '../../../../utils/Services';
import APIData from '../../../../utils/APIData';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { CookieUtils, Utils } from '../../../../utils/UtilFunctions';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { cloneDeep } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomAutocomplete from '../../../../common/components/CustomAutocomplete';
import { MATERIAL_TYPE } from '../../../../utils/EnumDefinitions';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const OrdersPlanning = () => {
  const [isLoading, setIsLoading] = useState(false);
  const purchaserId = CookieUtils.getUserId();
  const [planList, setPlanList] = useState([]);
  const APIToken = {
    GET_PLAN_LIST: 'COP01',
    GET_PLAN_DETAILS: 'COP02',
    GET_PRODUCT_ID: 'COP03',
    CREATE_PLAN: 'COP04',
    UPDATE_PLAN: 'COP05'
  };

  const [isUpdateRequest, setIsUpdateRequest] = useState(false);
  const [planDetails, setPlanDetails] = useState({
    plan_id: '',
    plan_date: '',
    plan_tag: '',
    material_type: '',
    plan_data: [],
    refresh_id: 0
  });
  const [originalValue, setOriginalValue] = useState(planDetails);

  const readymadeDefaultSubObject = {
    id: '',
    model_id: '',
    model_name: '',
    brand_id: '',
    brand_name: '',
    min_price: '',
    max_price: '',
    quantity: ''
  };
  const readymadeDefaultObject = {
    id: '',
    cate_id: '',
    cate_name: '',
    prod_id: '',
    prod_name: '',
    is_expanded: true,
    sub_data: [readymadeDefaultSubObject]
  };

  const silkPolyesterDefaultSubObject = {
    id: '',
    min_price: '',
    max_price: '',
    quantity: ''
  };
  const silkPolyesterDefaultObject = {
    id: '',
    prod_id: '',
    prod_name: '',
    is_expanded: true,
    sub_data: [silkPolyesterDefaultSubObject]
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );
  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (validateStringForNull(purchaserId)) {
      raiseGetPlanListRequest();
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getPlanList && apiToken == APIToken.GET_PLAN_LIST ) {
      if (response.data != undefined) {
        setPlanList(response.data);
        const filteredObject = response.data.find((obj) => !obj.hasOwnProperty('material_type'));
        setPlanDetails({ ...planDetails,
          plan_id: filteredObject.plan_id,
          plan_date: filteredObject.plan_date,
          material_type: '',
          plan_tag: '',
          plan_data: []
        });
        setIsUpdateRequest(false);
      }
    } else if (apiData == APIData.getPlanDetails && apiToken == APIToken.GET_PLAN_DETAILS) {
      if (response.data != undefined) {
        setIsUpdateRequest(true);
        populatePlanData(response.data);
      }
    } else if (apiData == APIData.getProductId && apiToken == APIToken.GET_PRODUCT_ID) {
      setProductName(callbackValues.id, response.data);
    } else if (apiData == APIData.createPlan && apiToken == APIToken.CREATE_PLAN) {
      showSnackBar('success', response.message ?? 'Plan Created successfully');
      raiseGetPlanListRequest();
    } else if (apiData == APIData.updatePlan && apiToken == APIToken.UPDATE_PLAN) {
      showSnackBar('success', response.message ?? 'Plan Updated Successfully');
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getPlanList && apiToken == APIToken.GET_PLAN_LIST) {
      defaultMsg = 'Failed to retrieve plan list';
    } else if (apiData == APIData.getPlanDetails && apiToken == APIToken.GET_PLAN_DETAILS) {
      defaultMsg = 'Failed to Get plan details';
    } else if (apiData == APIData.getProductId && apiToken == APIToken.GET_PRODUCT_ID) {
      defaultMsg = 'Failed to retrive Product Details';
    } else if (apiData == APIData.createPlan && apiToken == APIToken.CREATE_PLAN) {
      defaultMsg = 'Failed to create plan Details';
    } else if (apiData == APIData.updatePlan && apiToken == APIToken.UPDATE_PLAN) {
      defaultMsg = 'Failed to update plan Details';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetPlanListRequest = () => {
    setIsLoading(true);
    const params = 'purchaser_id=' + purchaserId;
    Services.sendBackendRequest({ apiData: APIData.getPlanList, params: params },
      APIToken.GET_PLAN_LIST, processSuccess, processError);
  };

  const raiseGetPlanRequest = (planId) => {
    setIsLoading(true);
    const params = 'plan_id=' + planId;
    Services.sendBackendRequest({ apiData: APIData.getPlanDetails, params: params },
      APIToken.GET_PLAN_DETAILS, processSuccess, processError);
  };

  const raiseGetProductDetailsRequest = (id, productId) => {
    setIsLoading(true);
    const params = 'prod_id=' + productId;
    Services.sendBackendRequest({ apiData: APIData.getProductId, params: params },
      APIToken.GET_PRODUCT_ID, processSuccess, processError, { id: id });
  };

  const populatePlanData = (data) => {
    const materialType = data.material_type;
    const obj = {};
    if (materialType == 3) { // Readymade
      data.plan_data.forEach((item) => {
        const key = `${item.cate_id}_${item.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            cate_name: item.cate_name,
            cate_id: item.cate_id,
            prod_id: item.prod_id,
            prod_name: item.prod_name,
            is_expanded: true,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          model_name: item.model_name,
          brand_name: item.brand_name,
          model_id: item.model_id,
          brand_id: item.brand_id,
          min_price: item.min_price,
          max_price: item.max_price,
          quantity: item.quantity
        });
      });
    } else {
      data.plan_data.forEach((item) => {
        const key = item.prod_id;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: item.prod_id,
            prod_name: item.prod_name,
            is_expanded: true,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          max_price: item.max_price,
          min_price: item.min_price,
          quantity: item.quantity
        });
      });
    }
    const filteredData = Object.values(obj);
    const planObj = {
      plan_id: data.plan_id,
      plan_date: data.plan_date,
      plan_tag: validateStringForNull(data.plan_tag) ? data.plan_tag : '',
      material_type: data.material_type,
      plan_data: filteredData
    };
    setPlanDetails(planObj);
    setOriginalValue(cloneDeep(planObj));
  };

  const handleProductDetailsSearch = (id, prodId) => {
    if (validateStringForNull(prodId)) {
      raiseGetProductDetailsRequest(id, prodId);
    }
  };

  const notifyPlanDetailsChange = () => {
    setPlanDetails({ ...planDetails, refresh_id: planDetails.refresh_id + 1 });
  };

  const handlePlanChange = (event) => {
    const { name, value } = event.target;
    const filteredPlan = planList.find((plan) => plan.plan_id === value);
    if (filteredPlan != undefined && validateStringForNull(filteredPlan.material_type)) {
      setPlanDetails({ ...planDetails, [name]: value, plan_data: [] });
      raiseGetPlanRequest(value);
    } else {
      setPlanDetails({ ...planDetails,
        [name]: value,
        plan_date: filteredPlan.plan_date,
        plan_tag: '',
        material_type: '',
        plan_data: []
      });
      setIsUpdateRequest(false);
    }
  };

  const setProductName = (id, response) => {
    const dataObj= planDetails.plan_data.find((item) => item.id === id);
    if (dataObj != undefined && response.length > 0) {
      const { prod_id: prodId, prod_name: prodName } = response[0];
      dataObj.prod_id = prodId;
      dataObj.prod_name = prodName;
      notifyPlanDetailsChange();
    }
  };

  const initializeNewSelectedComponent = (material) => {
    let obj = undefined;
    if (material === 3) {
      obj = readymadeDefaultObject;
    } else {
      obj = silkPolyesterDefaultObject;
    }
    obj.id = 1;
    obj.sub_data[0].id = 1;
    planDetails.plan_data = [obj];
    planDetails.material_type = material;
    notifyPlanDetailsChange();
  };

  const handleMaterialCheckBoxClick = (value) => {
    initializeNewSelectedComponent(value);
  };

  const handleAutoCompleteChange = (value, data, idField, nameField) => {
    data[idField] = value != null ? value[idField] : '';
    data[nameField] = value != null ? value[nameField] : '';
    if (idField === 'cate_id') {
      data.prod_id = '';
      data.prod_name = '';
    }
    notifyPlanDetailsChange();
  };

  const handleAddSubDataClick = (data) => {
    const lastId = data.sub_data[data.sub_data.length - 1].id;
    const newSubObj = planDetails.material_type == 3 ? readymadeDefaultSubObject : silkPolyesterDefaultSubObject;
    newSubObj.id = lastId + 1;
    data.sub_data.push(newSubObj);
    notifyPlanDetailsChange();
  };

  const handleRemoveSubDataClick = (data, subDataId) => {
    data.sub_data = data.sub_data.filter((subItem) => subItem.id !== subDataId);
    notifyPlanDetailsChange();
  };

  const handleAddDataClick = (material) => {
    const lastIdData = planDetails.plan_data[planDetails.plan_data.length - 1].id;
    const newSubObj = material == 3 ? readymadeDefaultSubObject : silkPolyesterDefaultSubObject;
    newSubObj.id = 1;
    const newObj = material == 3 ? readymadeDefaultObject : silkPolyesterDefaultObject;
    newObj.id = lastIdData + 1;
    newObj.sub_data = [newSubObj];
    planDetails.plan_data.push(newObj);
    notifyPlanDetailsChange();
  };

  const handleRemoveDataClick = (id) => {
    planDetails.plan_data = planDetails.plan_data.filter((item) => item.id !== id);
    notifyPlanDetailsChange();
  };

  const handleProductIdChange = (event, data) => {
    const { name, value } = event.target;
    data[name] = value;
    if (!validateStringForNull(value)) {
      data.prod_name = '';
    }
    notifyPlanDetailsChange();
  };

  const handleTextChange = (event) => {
    setPlanDetails({ ...planDetails, [event.target.name]: event.target.value });
  };

  const handleSubDataTextChange = (event, subData) => {
    const { name, value } = event.target;
    subData[name] = value;
    notifyPlanDetailsChange();
  };

  const getProductNameSearchParams = (cateId) => {
    if (validateStringForNull(cateId)) {
      return 'cate_id='+ cateId;
    }
    return '';
  };

  const handleCreateOrUpdatePlanRequest = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(planDetails);
      let filteredParams = {};
      if (params.material_type == 3) {
        filteredParams = validateReadyMadePlanParams(params);
      } else {
        filteredParams = validateSilkAndPolysterPlanParams(params);
      }
      raiseCreateOrUpdatePlanRequest(filteredParams);
    } catch (err) {
      showSnackBar('error', err.message ?? (isUpdateRequest ? 'Failed to Update Plan' : 'Failed to Create Plan'));
      setIsLoading(false);
    }
  };

  const validateSilkAndPolysterPlanParams = (params) => {
    const finalParams = validatePlanDetails(params);
    const planDataArray = [];

    params.plan_data.forEach((dataItem) => {
      if (!validateStringForNull(dataItem.prod_id)) {
        throw new Error('Select valid Product');
      }

      dataItem.sub_data.forEach((subData) => {
        if (!validateStringForNull(subData.min_price)) {
          throw new Error('Provide valid Min Price');
        }
        if (!validateStringForNull(subData.max_price)) {
          throw new Error('Provide valid Max Price');
        }
        if (!validateStringForNull(subData.quantity)) {
          throw new Error('Provide valid Quantity');
        }
        planDataArray.push({
          prod_id: dataItem.prod_id,
          min_price: parseFloat(subData.min_price),
          max_price: parseFloat(subData.max_price),
          quantity: parseInt(subData.quantity)
        });
      });
    });
    finalParams.plan_data = planDataArray;
    return finalParams;
  };

  const validatePlanDetails = (params) => {
    const finalParams = {};
    if (!validateStringForNull(params.plan_id)) {
      throw new Error('Select valid PlanId');
    }
    if (!validateStringForNull(params.plan_date)) {
      throw new Error('Select valid PlanDate');
    }
    if (validateStringForNull(params.plan_tag)) {
      finalParams.plan_tag = params.plan_tag;
    }
    if (!validateStringForNull(params.material_type)) {
      throw new Error('Select valid Material');
    }
    finalParams.plan_id = params.plan_id;
    finalParams.plan_date = params.plan_date;
    finalParams.material_type = parseInt(params.material_type);

    return finalParams;
  };

  const validateReadyMadePlanParams = (params) => {
    const finalParams = validatePlanDetails(params);
    const planDataArray = [];

    params.plan_data.forEach((dataItem) => {
      if (!validateStringForNull(dataItem.cate_id)) {
        throw new Error('Select valid Category');
      }
      if (!validateStringForNull(dataItem.prod_id)) {
        throw new Error('Select valid Product');
      }

      dataItem.sub_data.forEach((subData) => {
        if (!validateStringForNull(subData.model_id)) {
          throw new Error('Select valid Model');
        }
        if (!validateStringForNull(subData.brand_id)) {
          throw new Error('Select valid Brand');
        }
        if (!validateStringForNull(subData.min_price)) {
          throw new Error('Provide valid Min Price');
        }
        if (!validateStringForNull(subData.max_price)) {
          throw new Error('Provide valid Max Price');
        }
        if (!validateStringForNull(subData.quantity)) {
          throw new Error('Provide valid Quantity');
        }
        planDataArray.push({
          cate_id: dataItem.cate_id,
          prod_id: dataItem.prod_id,
          model_id: subData.model_id,
          brand_id: subData.brand_id,
          min_price: parseFloat(subData.min_price),
          max_price: parseFloat(subData.max_price),
          quantity: parseInt(subData.quantity)

        });
      });
    });
    finalParams.plan_data = planDataArray;
    return finalParams;
  };

  const raiseCreateOrUpdatePlanRequest = (finalParams) => {
    let apiData = undefined;
    let apiToken = undefined;
    if (isUpdateRequest) {
      apiData = APIData.updatePlan;
      apiToken = APIToken.UPDATE_PLAN;
    } else {
      apiData = APIData.createPlan;
      apiToken = APIToken.CREATE_PLAN;
    }
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: apiData, params: finalParams },
      apiToken, processSuccess, processError);
  };

  const handleShowPlanDetails = (data) => {
    data.is_expanded = !data.is_expanded;
    notifyPlanDetailsChange();
  };

  const handleResetClick = () => {
    const cloned = cloneDeep(originalValue);
    setPlanDetails(cloned);
  };

  const getMaterialContent = () => {
    if (planDetails.material_type == 3) {
      return getReadyMadePlanUIContent(planDetails.material_type);
    } else if (planDetails.material_type == 1 || planDetails.material_type == 2) {
      return getSilkAndPolysterPlanUIContent(planDetails.material_type);
    } else {
      return '';
    }
  };

  const getReadyMadePlanUIContent = () => {
    const gridArr = [];
    planDetails.plan_data.map((readyMadePlan, i) => {
      gridArr.push(
        <Grid item xs={12} key={readyMadePlan.id}>
          <Card elevation={12}>
            <Grid key={readyMadePlan.id} container>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
                sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
                <Grid item xs={9} sm={5}>
                  <CustomAutocomplete
                    label={'Category Name'}
                    apiInfo={{
                      searchURI: APIData.getCategory,
                      searchField: 'cate_name',
                      idField: 'cate_id'
                    }}
                    defaultValue={{
                      cate_id: readyMadePlan.cate_id,
                      cate_name: readyMadePlan.cate_name
                    }}
                    onSelectionChange={(event, value) => handleAutoCompleteChange(value, readyMadePlan, 'cate_id', 'cate_name')}
                  />
                </Grid>
                <Grid item xs={9} sm={5}>
                  <CustomAutocomplete
                    label={'Product Name'}
                    apiInfo={{
                      searchURI: APIData.getProductName,
                      searchField: 'prod_name',
                      idField: 'prod_id',
                      searchParams: getProductNameSearchParams(readyMadePlan.cate_id)
                    }}
                    defaultValue={{
                      prod_id: readyMadePlan.prod_id,
                      prod_name: readyMadePlan.prod_name
                    }}
                    onSelectionChange={(event, value) => handleAutoCompleteChange(value, readyMadePlan, 'prod_id', 'prod_name')}
                  />
                </Grid>
                {planDetails.plan_data.length > 1 ? (
                  <Grid item xs={3} sm={2} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    <IconButton
                      onClick={() => handleRemoveDataClick(readyMadePlan.id)} color='error' size='small' sx={{ p: 1 }}>
                      <DeleteIcon/>
                    </IconButton>
                    <IconButton color='primary' onClick={() => handleShowPlanDetails(readyMadePlan)} >
                      {readyMadePlan.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                  </Grid>
                ) : ''}
              </Grid>
              {readyMadePlan.is_expanded == true ? readyMadePlan.sub_data.map((planSubData, j) => (
                <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                  <Grid item xs={12} sm={3}>
                    <CustomAutocomplete
                      label={'Model Name'}
                      apiInfo={{
                        searchURI: APIData.getModel,
                        searchField: 'model_name',
                        idField: 'model_id'
                      }}
                      defaultValue={{
                        model_id: planSubData.model_id,
                        model_name: planSubData.model_name
                      }}
                      onSelectionChange={(event, value) =>
                        handleAutoCompleteChange(value, planSubData, 'model_id', 'model_name')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <CustomAutocomplete
                      label={'Brand Name'}
                      apiInfo={{
                        searchURI: APIData.getBrand,
                        searchField: 'brand_name',
                        idField: 'brand_id'
                      }}
                      defaultValue={{
                        brand_id: planSubData.brand_id,
                        brand_name: planSubData.brand_name
                      }}
                      onSelectionChange={(event, value) =>
                        handleAutoCompleteChange(value, planSubData, 'brand_id', 'brand_name')}
                    />
                  </Grid>
                  <Grid item xs={6}sm={1.5}>
                    <TextField
                      id="min_price"
                      label="Min Price"
                      name="min_price"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.min_price}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}sm={1.5}>
                    <TextField
                      id="max_price"
                      label="Max Price"
                      name="max_price"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.max_price}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}sm={1.5}>
                    <TextField
                      id="quantity"
                      label="Quantity"
                      name="quantity"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.quantity}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={1.5} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    {j === readyMadePlan.sub_data.length - 1 && (
                      <IconButton onClick={() => handleAddSubDataClick(readyMadePlan)} color='primary' size='small'>
                        <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                      </IconButton>
                    )}
                    {readyMadePlan.sub_data.length > 1 ? (
                      <IconButton onClick={() => handleRemoveSubDataClick(readyMadePlan, planSubData.id)} color='error' size='small'>
                        <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                      </IconButton>
                    ) : ''}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              )) : ''}
            </Grid>
          </Card>
        </Grid>
      );
    });
    return gridArr;
  };

  const getSilkAndPolysterPlanUIContent = () => {
    const gridArr = [];
    planDetails.plan_data.map((silkAndPolyesterPlan, i) => {
      gridArr.push(
        <Grid item xs={12} key={silkAndPolyesterPlan.id}>
          <Card elevation={12}>
            <Grid container>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
                sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="prod_id"
                    label="Product Id"
                    name="prod_id"
                    autoComplete ='off'
                    sx={{ backgroundColor: 'white' }}
                    value={silkAndPolyesterPlan.prod_id}
                    onChange={(event) => handleProductIdChange(event, silkAndPolyesterPlan)}
                    size='small'
                    fullWidth
                    onBlur={() => handleProductDetailsSearch(silkAndPolyesterPlan.id, silkAndPolyesterPlan.prod_id)}
                  />
                </Grid>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="prod_name"
                    label="Product Name"
                    name="prod_id"
                    autoComplete ='off'
                    sx={{ backgroundColor: 'white' }}
                    value={silkAndPolyesterPlan.prod_name}
                    size='small'
                    fullWidth
                    disabled={true}
                  />
                </Grid>
                {planDetails.plan_data.length > 1 ? (
                  <Grid item xs={3} sm={2} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    <IconButton
                      onClick={() => handleRemoveDataClick(silkAndPolyesterPlan.id)} color='error' size='small' sx={{ p: 1 }}>
                      <DeleteIcon/>
                    </IconButton>
                    <IconButton color='primary' onClick={() => handleShowPlanDetails(silkAndPolyesterPlan)}>
                      {silkAndPolyesterPlan.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                  </Grid>
                ) : ''}
              </Grid>
              {silkAndPolyesterPlan.is_expanded == true ? silkAndPolyesterPlan.sub_data.map((planSubData, j) => (
                <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                  <Grid item xs={6} sm={3.5}>
                    <TextField
                      id="min_price"
                      label="Min Price"
                      name="min_price"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.min_price}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={3.5}>
                    <TextField
                      id="max_price"
                      label="Max Price"
                      name="max_price"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.max_price}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}sm={2.5}>
                    <TextField
                      id="quantity"
                      label="Quantity"
                      name="quantity"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.quantity}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={2.5} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    {j === silkAndPolyesterPlan.sub_data.length - 1 && (
                      <IconButton onClick={() => handleAddSubDataClick(silkAndPolyesterPlan)} color='primary' size='small'>
                        <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                      </IconButton>
                    )}
                    {silkAndPolyesterPlan.sub_data.length > 1 ? (
                      <IconButton onClick={() => handleRemoveSubDataClick(silkAndPolyesterPlan, planSubData.id)} color='error' size='small'>
                        <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                      </IconButton>
                    ) : ''}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              )) : ''}
            </Grid>
          </Card>
        </Grid>
      );
    });
    return gridArr;
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography variant='h5' align='center'>
            Orders Planning
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth >
            <InputLabel size="small" id="plan-list-select-label"> Plan Id</InputLabel>
            <Select
              labelId="plan-list-select-label"
              id="plan-list-select"
              label="Plan id"
              name='plan_id'
              value={planDetails.plan_id}
              onChange={(event) => handlePlanChange(event)}
              size="small"
            >
              {planList.map((obj) => (
                <MenuItem key={obj.plan_id} value={obj.plan_id}>
                  {obj.plan_id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="Plan Date"
            label="Plan Date"
            name="plan_date"
            autoComplete ='off'
            value={planDetails.plan_date}
            size='small'
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            id="Plan tag"
            label="Plan Tag"
            name="plan_tag"
            autoComplete ='off'
            value={planDetails.plan_tag}
            size='small'
            fullWidth
            onChange={(event) => {handleTextChange(event);}}
          />
        </Grid>
        <Grid item container xs={12} sm={12}>
          <Grid item sx={{ mr: 1, display: 'flex', alignItems: 'center' }} >
            <Typography component='h5'>
              Material
            </Typography>
          </Grid>
          <Grid item >
            {Object.keys(MATERIAL_TYPE).map((material) => (
              <Chip sx={{ m: 1 }}
                key={material} label={MATERIAL_TYPE[material]}
                onClick={() => handleMaterialCheckBoxClick(material)}
                color={planDetails.material_type == material ? 'primary' : undefined}
                disabled={isUpdateRequest}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {getMaterialContent()}
        {validateStringForNull(planDetails.material_type) ? (
          <Grid item container>
            <Grid item xs={6}>
              <Button
                variant='text'
                startIcon={<AddBoxIcon/>}
                size='small'
                color='primary'
                onClick={() => handleAddDataClick(planDetails.material_type)}
              >
                Add Product
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
              <Button
                type='submit'
                variant="text"
                onClick={handleResetClick}
                sx={{ mr: 2 }}
              >
                Reset
              </Button>
              <Button
                type="submit"
                variant='contained'
                onClick={handleCreateOrUpdatePlanRequest}
              >
                {isUpdateRequest == true ? 'Update' : 'Create' }
              </Button>
            </Grid>
          </Grid>
        ) : ''}
      </Grid>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

export default OrdersPlanning;
