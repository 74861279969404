import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { green, red } from '@mui/material/colors';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const CustomDatagrid = ( { rows, columns } ) => {
  const [rowsData, setRowsData] = useState(undefined);

  const renderHeaders = () => {
    const headerArray = [];
    for (let i = 0; i < columns.length; i++) {
      if (columns[i]['children'] !== undefined) {
        const size = columns[i]['children'].length;
        headerArray[i] = (
          <TableCell key={i} colSpan={size} align={'center'}
            sx={{ borderLeft: 3, borderBottom: 3, borderColor: 'grey.300' }}>{columns[i].headerName}</TableCell>
        );
      } else {
        headerArray[i]= (
          <TableCell key={i} colSpan={1}></TableCell>
        );
      }
    };
    return headerArray;
  };

  const checkForChildren = () => {
    for (let i = 0; i < columns.length; i++) {
      if (columns[i]['children'] !== undefined) {
        return true;
      }
    }
    return false;
  };

  const renderSubHeaders = () => {
    const subHeaderArray = [];
    let index = 0;
    for (let i = 0; i < columns.length; i++) {
      if (columns[i]['children'] !== undefined) {
        const col = columns[i]['children'];
        for (let j = 0; j < col.length; j++) {
          subHeaderArray[index] = (
            <TableCell key={index} colSpan={1} sx={{ borderLeft: 3, borderColor: 'grey.300' }}>
              {col[j].headerName}</TableCell>
          );
          index++;
        };
      } else {
        subHeaderArray[index] = (
          <TableCell key={index} colSpan={1}>
            {columns[i].headerName}</TableCell>
        );
        index++;
      }
    };
    return subHeaderArray;
  };

  useEffect(() => {
    setRowsData(rows);
  }, [rows]);

  const getValueToShow = (value) => {
    if (value.display != undefined) {
      return value.display;
    } else {
      return value.value;
    }
  };

  const getValue = (value) => {
    if (typeof(value)=='object') {
      const isIncreased = value.is_increased;
      const percent = value.percent;
      if (isIncreased != undefined) {
        const icon = isIncreased ? ArrowUpwardIcon : ArrowDownwardIcon;
        const color = isIncreased ? green[500] : red[500];
        return (
          <React.Fragment>
            {getValueToShow(value)}
            <SvgIcon component={icon} sx={{ color: color, pt: 1, ml: 1 }}/>
            {percent}
          </React.Fragment>
        );
      } else {
        return getValueToShow(value);
      }
    } else {
      return value;
    }
  };

  const renderRowData = (row) => {
    const rowDataList = [];
    let index = 0;
    for (let i = 0; i < columns.length; i++) {
      if (columns[i]['children'] !== undefined) {
        const col = columns[i]['children'];
        for (let j = 0; j < col.length; j++) {
          const fieldArr = col[j]['field'].split('.');
          let value = row;
          fieldArr.map( (field) => {
            value = value[field];
          });
          rowDataList[index] = (
            <TableCell key={index} colSpan={1} sx={{ borderLeft: 3, borderColor: 'grey.300' }}>
              {getValue(value)}</TableCell>
          );
          index++;
        };
      } else {
        rowDataList[index] = (
          <TableCell key={index} colSpan={1}>
            {getValue(row[columns[i].field])}</TableCell>
        );
        index++;
      }
    };
    return rowDataList;
  };

  return (
    <div style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
      <div style={{ height: '100%', width: '100%', backgroundColor: 'white' }}>
        <Table sx={{ border: 2, borderColor: 'grey.300' }}>
          {
            checkForChildren &&
              <TableHead>
                <TableRow>
                  {renderHeaders()}
                </TableRow>
              </TableHead>
          }
          <TableHead>
            <TableRow>
              {renderSubHeaders()}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsData != null && rowsData.map( (row) => (
              <TableRow key={row.id}>
                {renderRowData(row)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

CustomDatagrid.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired
};

export default CustomDatagrid;
