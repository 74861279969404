import { AppBar, Backdrop, Button, Card, CircularProgress, Dialog, Divider, FormControl, Grid,
  IconButton, InputLabel, MenuItem, Select, Switch, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { validateStringForNull } from '../../../../utils/FieldValidations';
import Services from '../../../../utils/Services';
import APIData from '../../../../utils/APIData';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { CookieUtils, Utils } from '../../../../utils/UtilFunctions';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { cloneDeep } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomAutocomplete from '../../../../common/components/CustomAutocomplete';
import { MATERIAL_TYPE } from '../../../../utils/EnumDefinitions';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import ViewPlanDetails from '../planning/ViewPlanDetails';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const OrdersBooking = () => {
  const [isLoading, setIsLoading] = useState(false);
  const purchaserId = CookieUtils.getUserId();
  const [planList, setPlanList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [isViewPlanDialogOpen, setIsViewPlanDialogOpen] = useState(false);
  const [isUpdateRequest, setIsUpdateRequest] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    plan_id: '',
    order_id: '',
    ac_name: '',
    ac_id: '',
    material_type: '',
    order_data: [],
    refresh_id: 0
  });
  const [originalValue, setOriginalValue] = useState(orderDetails);

  const readyMadeSubDataDefaultObject = {
    id: '',
    model_id: '',
    model_name: '',
    brand_id: '',
    brand_name: '',
    size_id: '',
    buy_price: '',
    bought_quantity: '',
    quantity: ''
  };
  const readyMadeDefaultObject = {
    id: '',
    cate_id: '',
    cate_name: '',
    prod_id: '',
    prod_name: '',
    is_expanded: true,
    sub_data: []
  };

  const silkSubDataDefaultObject = {
    id: '',
    vrty_id: '',
    vrty_name: '',
    buy_price: '',
    sell_price: '',
    description: '',
    quantity: ''
  };

  const silkDefaultObject = {
    id: '',
    prod_id: '',
    prod_name: '',
    is_expanded: true,
    sub_data: []
  };

  const polyesterSubDataDefaultObject = {
    id: '',
    vrty_id: '',
    vrty_name: '',
    buy_price: '',
    sell_price: '',
    description: '',
    quantity: '',
    qty_per_set: '6',
    no_of_sets: ''
  };

  const polyesterDefaultObject = {
    id: '',
    prod_id: '',
    prod_name: '',
    is_expanded: true,
    sub_data: []
  };
  const APIToken = {
    GET_PLAN_LIST: 'COB01',
    GET_BOOKING_ORDER_LIST: 'COB02',
    GET_BOOKING_ORDER: 'COB03',
    GET_ORDER_QTY: 'COB04',
    GET_PRODUCT_ID: 'COB05',
    CREATE_BOOKING_ORDER: 'COB06',
    UPDATE_BOOKING_ORDER: 'CON07',
    CALCULATE_SELL_PRICE: 'COB08'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (validateStringForNull(purchaserId)) {
      raiseGetPlanListRequest();
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getPlanList && apiToken == APIToken.GET_PLAN_LIST ) {
      const plans = response.data.filter((obj) => obj.hasOwnProperty('material_type'));
      setPlanList(plans);
    } else if (apiData == APIData.getBookingOrderList && apiToken == APIToken.GET_BOOKING_ORDER_LIST) {
      setOrderList(response.data);
      const filteredOrder = response.data.filter((obj) => obj.hasOwnProperty('material_type'));
      initializeMaterialUIComponent(filteredOrder[0].order_id, filteredOrder[0].order_date, filteredOrder[0].material_type, callbackValues.planId);
    } else if (apiData == APIData.getBookingOrder && apiToken == APIToken.GET_BOOKING_ORDER) {
      if (response.data != undefined) {
        setIsUpdateRequest(true);
        populateOrderData(response.data);
      }
    } else if (apiData == APIData.createBookingOrder && apiToken == APIToken.CREATE_BOOKING_ORDER) {
      showSnackBar('success', response.message ?? 'Order Created successfully');
      raiseGetOrderListRequest(orderDetails.plan_id);
    } else if (apiData == APIData.updateBookingOrder && apiToken == APIToken.UPDATE_BOOKING_ORDER) {
      showSnackBar('success', response.message ?? 'Order Updated Successfully');
    } else if (apiData == APIData.getProductId && apiToken == APIToken.GET_PRODUCT_ID) {
      setProductName(callbackValues.id, response.data);
    } else if (apiData == APIData.calculateSellPrice && apiToken == APIToken.CALCULATE_SELL_PRICE) {
      callbackValues.subData.sell_price = response.data.sell_price;
      notifyOrderDetailsChange();
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getPlanList && apiToken == APIToken.GET_PLAN_LIST) {
      defaultMsg = 'Failed to retrieve Plan list';
    } else if (apiData == APIData.getBookingOrderList && apiToken == APIToken.GET_BOOKING_ORDER_LIST) {
      defaultMsg = 'Failed to retrieve Order list';
    } else if (apiData == APIData.getBookingOrder && apiToken == APIToken.GET_BOOKING_ORDER) {
      defaultMsg = 'Failed to retrieve Order Details';
    } else if (apiData == APIData.createBookingOrder && apiToken == APIToken.CREATE_BOOKING_ORDER) {
      defaultMsg = 'Failed to Create order';
    } else if (apiData == APIData.updateBookingOrder && apiToken == APIToken.UPDATE_BOOKING_ORDER) {
      defaultMsg = 'Failed to Updare order';
    } else if (apiData == APIData.getProductId && apiToken == APIToken.GET_PRODUCT_ID) {
      defaultMsg = 'Failed to retrieve product details';
    } else if (apiData == APIData.calculateSellPrice && apiToken == APIToken.CALCULATE_SELL_PRICE) {
      defaultMsg = 'Failed to Calulate sell price';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetPlanListRequest = () => {
    setIsLoading(true);
    const params = 'purchaser_id=' + purchaserId;
    Services.sendBackendRequest({ apiData: APIData.getPlanList, params: params },
      APIToken.GET_PLAN_LIST, processSuccess, processError);
  };

  const populateOrderData = (data) => {
    const materialType = data.material_type;
    const obj = {};
    if (materialType == 3) {
      data.order_data.forEach((item) => {
        const key = `${item.cate_id}_${item.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            cate_name: item.cate_name,
            cate_id: item.cate_id,
            prod_id: item.prod_id,
            prod_name: item.prod_name,
            is_expanded: true,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          model_name: item.model_name,
          brand_name: item.brand_name,
          model_id: item.model_id,
          brand_id: item.brand_id,
          buy_price: item.buy_price,
          size_id: item.size_id,
          quantity: item.quantity,
          description: item.description,
          bought_quantity: item.bought_quantity,
          planned_quantity: item.planned_quantity
        });
      });
    } else if (materialType == 2) {
      data.order_data.forEach((item) => {
        const key = item.prod_id;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: item.prod_id,
            prod_name: item.prod_name,
            is_expanded: true,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          buy_price: item.buy_price,
          sell_price: item.sell_price,
          quantity: item.quantity,
          vrty_id: item.vrty_id,
          description: item.description,
          bought_quantity: item.bought_quantity,
          planned_quantity: item.planned_quantity
        });
      });
    } else if (materialType == 1) {
      data.order_data.forEach((item) => {
        const key = item.prod_id;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: item.prod_id,
            prod_name: item.prod_name,
            is_expanded: true,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          buy_price: item.buy_price,
          sell_price: item.sell_price,
          quantity: item.quantity,
          description: item.description,
          vrty_id: item.vrty_id,
          bought_quantity: item.bought_quantity,
          planned_quantity: item.planned_quantity
        });
      });
    }
    const filteredData = Object.values(obj);
    const filteredOrderObj = {
      plan_id: data.plan_id,
      order_id: data.order_id,
      order_date: data.order_date,
      material_type: data.material_type,
      ac_id: validateStringForNull(data.ac_id) ? data.ac_id: '',
      ac_name: validateStringForNull(data.ac_name) ? data.ac_name: '',
      order_data: filteredData
    };
    setOrderDetails(filteredOrderObj);
    setOriginalValue(cloneDeep(filteredOrderObj));
  };

  const raiseGetOrderListRequest = (planId) => {
    setIsLoading(true);
    const params = 'plan_id=' + planId;
    Services.sendBackendRequest({ apiData: APIData.getBookingOrderList, params: params },
      APIToken.GET_BOOKING_ORDER_LIST, processSuccess, processError, { planId: planId });
  };

  const raiseGetOrderRequest = (orderId) => {
    setIsLoading(true);
    const params = 'order_id=' + orderId;
    Services.sendBackendRequest({ apiData: APIData.getBookingOrder, params: params },
      APIToken.GET_BOOKING_ORDER, processSuccess, processError);
  };

  // const raiseGetOrderQuantity = (dataId, subDataId, planId, prodId, materialType) => {
  //   setIsLoading(true);
  //   const params = 'plan_id=' + orderId + '&prod_id=' + prodId + '&material_type=' + materialType;
  //   Services.sendBackendRequest({ apiData: APIData.getOrderQuantity, params: params },
  //     APIToken.GET_ORDER_QTY, processSuccess, processError);
  // };

  const raiseCreateOrUpdateOrderRequest = (finalParams) => {
    let apiData = undefined;
    let apiToken = undefined;
    if (isUpdateRequest) {
      apiData = APIData.updateBookingOrder;
      apiToken = APIToken.UPDATE_BOOKING_ORDER;
    } else {
      apiData = APIData.createBookingOrder;
      apiToken = APIToken.CREATE_BOOKING_ORDER;
    }
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: apiData, params: finalParams },
      apiToken, processSuccess, processError);
  };

  const raiseGetProductDetailsRequest = (id, productId) => {
    setIsLoading(true);
    const params = 'prod_id=' + productId;
    Services.sendBackendRequest({ apiData: APIData.getProductId, params: params },
      APIToken.GET_PRODUCT_ID, processSuccess, processError, { id: id });
  };

  const handlePlanChange = (event) => {
    const { name, value } = event.target;
    setOrderDetails({ ...orderDetails,
      [name]: value,
      order_id: '',
      order_data: [],
      material_type: '',
      ac_id: '',
      ac_name: ''
    });
    raiseGetOrderListRequest(value);
  };

  const handleViewPlanClick = () => {
    if (validateStringForNull(orderDetails.plan_id)) {
      setIsViewPlanDialogOpen(true);
    }
  };

  const handleProductDetailsSearch = (id, prodId) => {
    if (validateStringForNull(prodId)) {
      raiseGetProductDetailsRequest(id, prodId);
    }
  };

  const handleOrderChange = (event) => {
    const { name, value } = event.target;
    const filteredOrder = orderList.find((order) => order.order_id === value);
    if (filteredOrder != undefined && !validateStringForNull(filteredOrder.material_type)) {
      setOrderDetails({ ...orderDetails, [name]: value, ac_name: '', ac_id: '', order_data: [] });
      raiseGetOrderRequest(value);
    } else {
      initializeMaterialUIComponent(value, filteredOrder.order_date, filteredOrder.material_type);
    }
  };

  const handleSwitchChange = () => {
    // setIsPriceFieldDisabled(!isSellPriceFieldDisabled);
  };

  const handleSupplierNameChange = (value) => {
    if (validateStringForNull(value) && validateStringForNull(value.ac_id)) {
      setOrderDetails({ ...orderDetails,
        ac_id: value.ac_id,
        ac_name: value.ac_name
      });
    } else {
      setOrderDetails({ ...orderDetails,
        ac_id: '',
        ac_name: ''
      });
    }
  };

  const setProductName = (id, response) => {
    const dataObj= orderDetails.order_data.find((item) => item.id === id);
    if (dataObj != undefined && response.length > 0) {
      const { prod_id: prodId, prod_name: prodName } = response[0];
      dataObj.prod_id = prodId;
      dataObj.prod_name = prodName;
      notifyOrderDetailsChange();
    }
  };

  const initializeMaterialUIComponent = (orderId, orderDate, material, planId) => {
    let newObj = {};
    let newSubObj = {};
    if (material == 3) {
      newObj = readyMadeDefaultObject;
      newSubObj = readyMadeSubDataDefaultObject;
    } else if (material == 2) {
      newObj = silkDefaultObject;
      newSubObj = silkSubDataDefaultObject;
    } else if (material == 1) {
      newObj = polyesterDefaultObject;
      newSubObj = polyesterSubDataDefaultObject;
    }
    newSubObj.id = 1;
    newObj.id = 1;
    newObj.sub_data = [newSubObj];

    if (validateStringForNull(planId)) {
      orderDetails.plan_id = planId;
    }
    orderDetails.order_id = orderId;
    orderDetails.order_date = orderDate;
    orderDetails.material_type = material;
    orderDetails.ac_id = '';
    orderDetails.ac_name = '';
    orderDetails.order_data = [newObj];
    notifyOrderDetailsChange();
    setIsUpdateRequest(false);
  };

  const handleAddDataClick = (material) => {
    const lastIdData = orderDetails.order_data[orderDetails.order_data.length - 1].id;
    let newObj = {};
    let newSubObj = {};
    if (material == 3) {
      newObj = readyMadeDefaultObject;
      newSubObj = readyMadeSubDataDefaultObject;
    } else if (material == 2) {
      newObj = silkDefaultObject;
      newSubObj = silkSubDataDefaultObject;
    } else if (material == 1) {
      newObj = polyesterDefaultObject;
      newSubObj = polyesterSubDataDefaultObject;
    }
    newSubObj.id = 1;
    newObj.id = lastIdData + 1;
    newObj.sub_data = [newSubObj];
    orderDetails.order_data.push(newObj);
    notifyOrderDetailsChange();
  };

  const handleAutoCompleteChange = (value, data, idField, nameField) => {
    data[idField] = value != null ? value[idField] : '';
    data[nameField] = value != null ? value[nameField] : '';
    if (idField === 'cate_id') {
      data.prod_id = '';
      data.prod_name = '';
    }
    notifyOrderDetailsChange();
  };

  const handleAddSubDataClick = (data) => {
    const lastId = data.sub_data[data.sub_data.length - 1].id;
    let newSubObj = {};
    if (orderDetails.material_type == 3) {
      newSubObj = readyMadeSubDataDefaultObject;
    } else if (orderDetails.material_type == 2) {
      newSubObj = silkSubDataDefaultObject;
    } else if (orderDetails.material_type == 1) {
      newSubObj = polyesterSubDataDefaultObject;
    }
    newSubObj.id = lastId + 1;
    data.sub_data.push(newSubObj);
    notifyOrderDetailsChange();
  };

  const handleResetClick = () => {
    const cloned = cloneDeep(originalValue);
    setOrderDetails(cloned);
  };

  const getMaterialContent = () => {
    const materialType = orderDetails.material_type;
    switch (materialType) {
    case 1:
      return getPolysterPlanUIContent(orderDetails.material_type);
    case 2:
      return getSilkPlanUIContent(orderDetails.material_type);
    case 3:
      return getReadyMadePlanUIContent(orderDetails.material_type);
    default:
      return '';
    }
  };

  const computeBuyQuantityForPolyester = (subData) => {
    if (validateStringForNull(subData.no_of_sets) && validateStringForNull(subData.qty_per_set)) {
      const noOfSets = parseInt(subData.no_of_sets);
      const qtyPerSet = parseInt(subData.qty_per_set);
      subData.quantity = (noOfSets * qtyPerSet).toString();
      notifyOrderDetailsChange();
    }
  };

  const notifyOrderDetailsChange = () => {
    setOrderDetails({ ...orderDetails, refresh_id: orderDetails.refresh_id + 1 });
  };

  const handleRemoveSubDataClick = (data, subDataId) => {
    data.sub_data = data.sub_data.filter((subItem) => subItem.id !== subDataId);
    notifyOrderDetailsChange();
  };

  const handleRemoveDataClick = (id) => {
    orderDetails.order_data = orderDetails.order_data.filter((item) => item.id !== id);
    notifyOrderDetailsChange();
  };

  const handleProductIdChange = (event, data) => {
    const { name, value } = event.target;
    data[name] = value;
    if (!validateStringForNull(value)) {
      data.prod_name = '';
    }
    notifyOrderDetailsChange();
  };

  const handleSubDataTextChange = (event, subData) => {
    const { name, value } = event.target;
    subData[name] = value;
    notifyOrderDetailsChange();
  };

  const getProductNameSearchParams = (cateId) => {
    if (validateStringForNull(cateId)) {
      return 'cate_id='+ cateId;
    }
    return '';
  };

  const handleCreateOrUpdateOrderRequest = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(orderDetails);
      let filteredParams = {};
      if (params.material_type == 1) {
        filteredParams = validatePolyesterOrderData(params);
      } else if (params.material_type == 2) {
        filteredParams = validateSilkOrderData(params);
      } else if (params.material_type == 3) {
        filteredParams = validateReadyMadeOrderData(params);
      }
      raiseCreateOrUpdateOrderRequest(filteredParams);
    } catch (err) {
      showSnackBar('error', err.message ?? (isUpdateRequest ? 'Failed to Update Order' : 'Failed to Create Order'));
      setIsLoading(false);
    }
  };

  const validateOrderDetails = (params) => {
    const finalParams ={};
    if (!validateStringForNull(params.plan_id)) {
      throw new Error('Select valid Plan Id');
    }
    if (!validateStringForNull(params.order_id)) {
      throw new Error('Select valid Order Id');
    }
    if (!validateStringForNull(params.ac_name)) {
      throw new Error('Select valid Supplier Name');
    }
    if (!validateStringForNull(params.material_type)) {
      throw new Error('Select valid Material');
    }
    finalParams.order_id = params.order_id;
    finalParams.plan_id = params.plan_id;
    finalParams.material_type = parseInt(params.material_type);
    finalParams.ac_name = params.ac_name;
    finalParams.ac_id = params.ac_id;

    return finalParams;
  };

  const validateSilkOrderData = (params) => {
    const finalParams = validateOrderDetails(params);
    const planDataArray = [];

    params.order_data.forEach((dataItem) => {
      if (!validateStringForNull(dataItem.prod_id)) {
        throw new Error('Select valid Product');
      }

      dataItem.sub_data.forEach((subData) => {
        if (!validateStringForNull(subData.vrty_id)) {
          throw new Error('Provide valid Variety');
        }
        if (!validateStringForNull(subData.buy_price)) {
          throw new Error('Provide valid Buy Price');
        }
        if (!validateStringForNull(subData.sell_price)) {
          throw new Error('Provide valid Sell Price');
        }
        if (!validateStringForNull(subData.description)) {
          throw new Error('Provide valid Description');
        }
        if (!validateStringForNull(subData.quantity)) {
          throw new Error('Provide valid Buy Quantity');
        }
        planDataArray.push({
          prod_id: dataItem.prod_id,
          vrty_id: subData.vrty_id,
          buy_price: parseFloat(subData.buy_price),
          sell_price: parseFloat(subData.sell_price),
          description: subData.description,
          quantity: parseInt(subData.quantity)
        });
      });
    });
    finalParams.order_data = planDataArray;
    return finalParams;
  };

  const validateReadyMadeOrderData = (params) => {
    const finalParams = validateOrderDetails(params);
    const planDataArray = [];

    params.order_data.forEach((dataItem) => {
      if (!validateStringForNull(dataItem.cate_id)) {
        throw new Error('Select valid Category');
      }
      if (!validateStringForNull(dataItem.prod_id)) {
        throw new Error('Select valid Product');
      }

      dataItem.sub_data.forEach((subData) => {
        if (!validateStringForNull(subData.model_id)) {
          throw new Error('Select valid Model');
        }
        if (!validateStringForNull(subData.brand_id)) {
          throw new Error('Select valid Brand');
        }
        if (!validateStringForNull(subData.size_id)) {
          throw new Error('Provide valid Size');
        }
        if (!validateStringForNull(subData.buy_price)) {
          throw new Error('Provide valid Buy Price');
        }
        if (!validateStringForNull(subData.quantity)) {
          throw new Error('Provide valid Buy Quantity');
        }
        planDataArray.push({
          cate_id: dataItem.cate_id,
          prod_id: dataItem.prod_id,
          plan_serial: dataItem.id,
          model_id: subData.model_id,
          brand_id: subData.brand_id,
          size_id: subData.size_id,
          buy_price: parseFloat(subData.buy_price),
          quantity: parseInt(subData.quantity)
        });
      });
    });
    finalParams.order_data = planDataArray;
    return finalParams;
  };

  const validatePolyesterOrderData = (params) => {
    const finalParams = validateOrderDetails(params);
    const planDataArray = [];

    params.order_data.forEach((dataItem) => {
      if (!validateStringForNull(dataItem.prod_id)) {
        throw new Error('Select valid Product');
      }

      dataItem.sub_data.forEach((subData) => {
        if (!validateStringForNull(subData.buy_price)) {
          throw new Error('Provide valid Buy Price');
        }
        if (!validateStringForNull(subData.no_of_sets)) {
          throw new Error('Provide valid No OF Sets');
        }
        if (!validateStringForNull(subData.qty_per_set)) {
          throw new Error('Provide valid Quan Per Sets');
        }
        if (!validateStringForNull(subData.quantity)) {
          throw new Error('Provide valid Buy Quantity');
        }
        const optionalData = {};
        if (validateStringForNull(subData.model_id)) {
          optionalData.model_id = subData.model_id;
        }
        planDataArray.push({
          prod_id: dataItem.prod_id,
          buy_price: parseFloat(subData.buy_price),
          no_of_sets: parseInt(subData.no_of_sets),
          qty_per_set: parseInt(subData.qty_per_set),
          quantity: parseInt(subData.quantity),
          ...optionalData
        });
      });
    });
    finalParams.order_data = planDataArray;
    return finalParams;
  };

  const raiseCalulateSellPriceRequest = (subData, prodId) => {
    if (!validateStringForNull(subData.buy_price) || !validateStringForNull(prodId)) {
      return;
    }
    setIsLoading(true);
    const params = 'prod_id=' + prodId + '&buy_price=' + subData.buy_price;
    Services.sendBackendRequest({ apiData: APIData.calculateSellPrice, params: params },
      APIToken.CALCULATE_SELL_PRICE, processSuccess, processError, { subData: subData });
  };

  const handleShowPlanDetails = (data) => {
    data.is_expanded = !data.is_expanded;
    notifyOrderDetailsChange();
  };

  const getVarietyNameSearchParams = (prodId) => {
    if (validateStringForNull(prodId)) {
      return 'prod_id='+prodId;
    }
    return '';
  };

  const handleAddPhotoClick = () => {
  };

  const getReadyMadePlanUIContent = (materialType) => {
    const gridArr = [];
    orderDetails.order_data.map((readyMadePlan, i) => {
      gridArr.push(
        <Grid item xs={12} key={readyMadePlan.id}>
          <Card elevation={12}>
            <Grid key={readyMadePlan.id} container>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
                sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
                <Grid item xs={9} sm={5}>
                  <CustomAutocomplete
                    label={'Category Name'}
                    apiInfo={{
                      searchURI: APIData.getCategory,
                      searchField: 'cate_name',
                      idField: 'cate_id'
                    }}
                    defaultValue={{
                      cate_id: readyMadePlan.cate_id,
                      cate_name: readyMadePlan.cate_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, readyMadePlan, 'cate_id', 'cate_name')}
                  />
                </Grid>
                <Grid item xs={9} sm={5}>
                  <CustomAutocomplete
                    label={'Product Name'}
                    apiInfo={{
                      searchURI: APIData.getProductName,
                      searchField: 'prod_name',
                      idField: 'prod_id',
                      searchParams: getProductNameSearchParams(readyMadePlan.cate_id)
                    }}
                    defaultValue={{
                      prod_id: readyMadePlan.prod_id,
                      prod_name: readyMadePlan.prod_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, readyMadePlan, 'prod_id', 'prod_name')}
                  />
                </Grid>
                {orderDetails.order_data.length > 1 ? (
                  <Grid item xs={3} sm={2} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    <IconButton
                      onClick={() => handleRemoveDataClick(readyMadePlan.id)} color='error' size='small' sx={{ p: 1 }}>
                      <DeleteIcon/>
                    </IconButton>
                    <IconButton color='inherit' onClick={() => handleShowPlanDetails(readyMadePlan)}>
                      {readyMadePlan.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                  </Grid>
                ) : ''}
              </Grid>
              {readyMadePlan.is_expanded == true ? readyMadePlan.sub_data.map((planSubData, j) => (
                <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                  <Grid item container xs={12} lg={5} rowSpacing={2} columnSpacing={2} >
                    <Grid item xs={12} sm={5} lg={6}>
                      <CustomAutocomplete
                        label={'Model Name'}
                        apiInfo={{
                          searchURI: APIData.getModel,
                          searchField: 'model_name',
                          idField: 'model_id'
                        }}
                        defaultValue={{
                          model_id: planSubData.model_id,
                          model_name: planSubData.model_name
                        }}
                        onSelectionChange={(event, value) =>
                          handleAutoCompleteChange(value, planSubData, 'model_id', 'model_name')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5} lg={6}>
                      <CustomAutocomplete
                        label={'Brand Name'}
                        apiInfo={{
                          searchURI: APIData.getBrand,
                          searchField: 'brand_name',
                          idField: 'brand_id'
                        }}
                        defaultValue={{
                          brand_id: planSubData.brand_id,
                          brand_name: planSubData.brand_name
                        }}
                        onSelectionChange={(event, value) =>
                          handleAutoCompleteChange(value, planSubData, 'brand_id', 'brand_name')}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} lg={7} rowSpacing={2} columnSpacing={2} >
                    <Grid item xs={4} sm={2} lg={2}>
                      <TextField
                        id="size"
                        label="Size"
                        name="size_id"
                        autoComplete ='off'
                        value={planSubData.size_id}
                        variant='standard'
                        onChange={(event) => handleSubDataTextChange(event, planSubData)}
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4.25} sm={3} lg={3}>
                      <TextField
                        id="buy_price"
                        label="Buy Price"
                        name="buy_price"
                        autoComplete ='off'
                        variant='standard'
                        value={planSubData.buy_price}
                        onChange={(event) => handleSubDataTextChange(event, planSubData)}
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4} sm={2} lg={2}>
                      <TextField
                        id="bought_quantity"
                        label="B/P Qty"
                        name="bought_quantity"
                        autoComplete ='off'
                        variant='standard'
                        value={validateStringForNull(planSubData.bought_quantity) ?
                          planSubData.bought_quantity + '/' + planSubData.planned_quantity : ''}
                        disabled={true}
                        onChange={(event) => handleSubDataTextChange(event, planSubData)}
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4} sm={3} lg={3}>
                      <TextField
                        id="buy_quantity"
                        label="Buy Quantity"
                        name="quantity"
                        autoComplete ='off'
                        variant='standard'
                        value={planSubData.quantity}
                        onChange={(event) => handleSubDataTextChange(event, planSubData)}
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4} sm={2} lg={2} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                      {j === readyMadePlan.sub_data.length - 1 && (
                        <IconButton onClick={() => handleAddSubDataClick(readyMadePlan)} color='primary' size='small'>
                          <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                        </IconButton>
                      )}
                      {readyMadePlan.sub_data.length > 1 ? (
                        <IconButton onClick={() => handleRemoveSubDataClick(readyMadePlan, planSubData.id)} color='error' size='small'>
                          <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                        </IconButton>
                      ) : ''}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              )) : ''}
            </Grid>
          </Card>
        </Grid>
      );
    });
    return gridArr;
  };

  const getSilkPlanUIContent = (materialType) => {
    const gridArr = [];
    orderDetails.order_data.map((silkPlan, i) => {
      gridArr.push(
        <Grid item xs={12} key={silkPlan.id}>
          <Card elevation={12}>
            <Grid key={silkPlan.id} container>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
                sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="prod_id"
                    label="Product Id"
                    name="prod_id"
                    autoComplete ='off'
                    sx={{ backgroundColor: 'white' }}
                    value={silkPlan.prod_id}
                    onChange={(event) => handleProductIdChange(event, silkPlan)}
                    size='small'
                    fullWidth
                    onBlur={() => handleProductDetailsSearch(silkPlan.id, silkPlan.prod_id)}
                  />
                </Grid>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="prod_name"
                    label="Product Name"
                    name="prod_id"
                    autoComplete ='off'
                    sx={{ backgroundColor: 'white' }}
                    value={silkPlan.prod_name}
                    size='small'
                    fullWidth
                    disabled={true}
                  />
                </Grid>
                {orderDetails.order_data.length > 1 ? (
                  <Grid item xs={3} sm={2} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    <IconButton
                      onClick={() => handleRemoveDataClick(silkPlan.id)} color='error' size='small' sx={{ p: 1 }}>
                      <DeleteIcon/>
                    </IconButton>
                    <IconButton color='inherit' onClick={() => handleShowPlanDetails(silkPlan)}>
                      {silkPlan.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                  </Grid>
                ) : ''}
              </Grid>
              {silkPlan.is_expanded == true ? silkPlan.sub_data.map((planSubData, j) => (
                <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                  <Grid item xs={9} sm={4.5}>
                    <CustomAutocomplete
                      label={'Variety'}
                      apiInfo={{
                        searchURI: APIData.getVariety,
                        searchField: 'vrty_name',
                        idField: 'vrty_id',
                        searchParams: getVarietyNameSearchParams(silkPlan.prod_id)
                      }}
                      defaultValue={{
                        vrty_id: planSubData.vrty_id,
                        vrty_name: planSubData.vrty_name
                      }}
                      onSelectionChange={(event, value) =>
                        handleAutoCompleteChange(value, planSubData, 'vrty_id', 'vrty_name')}
                    />
                  </Grid>
                  <Grid item xs={4.5} sm={3}>
                    <TextField
                      id="buy_price"
                      label="Buy Price"
                      name="buy_price"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.buy_price}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                      onBlur={(event, value) => raiseCalulateSellPriceRequest(planSubData, silkPlan.prod_id)}
                    />
                  </Grid>
                  <Grid item xs={3} sm={1.5} lg={1.5}>
                    <Switch
                      // checked={isSellPriceFieldDisabled}
                      onChange={handleSwitchChange}
                      sx={{
                        '& .MuiSwitch-thumb': {
                          color: '#205295;'
                        },
                        '& .MuiSwitch-track': {
                          backgroundColor: '#172b4d'
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4.5} sm={3}>
                    <TextField
                      id="sell_price"
                      label="Sell Price"
                      name="sell_price"
                      autoComplete ='off'
                      variant='standard'
                      // disabled={!isSellPriceFieldDisabled}
                      value={planSubData.sell_price}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={7.5}sm={4.5}>
                    <TextField
                      id="description"
                      label="Description"
                      name="description"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.description}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4.5}sm={2.5} lg={2}>
                    <TextField
                      id="bought_quantity"
                      label="B/P Qty"
                      name="bought_quantity"
                      autoComplete ='off'
                      variant='standard'
                      value={validateStringForNull(planSubData.bought_quantity) ?
                        planSubData.bought_quantity + '/' + planSubData.planned_quantity : ''}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      disabled={true}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}sm={3}>
                    <TextField
                      id="buy_quantity"
                      label="Buy Quantity"
                      name="quantity"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.quantity}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={2} lg={2.5} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    {j === silkPlan.sub_data.length - 1 && (
                      <IconButton onClick={() => handleAddSubDataClick(silkPlan)} color='primary' size='small'>
                        <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                      </IconButton>
                    )}
                    {silkPlan.sub_data.length > 1 ? (
                      <IconButton onClick={() => handleRemoveSubDataClick(silkPlan, planSubData.id)} color='error' size='small'>
                        <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                      </IconButton>
                    ) : ''}
                    <IconButton onClick={() => handleAddPhotoClick(silkPlan.id, planSubData.id)} color='success' size='small'>
                      <AddAPhotoIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                    <IconButton onClick={() => handleAddPhotoClick(silkPlan.id, planSubData.id)} color='success' size='small'>
                      <MoreHorizIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              )) : ''}
            </Grid>
          </Card>
        </Grid>
      );
    });
    return gridArr;
  };

  const getPolysterPlanUIContent = (materialType) => {
    const gridArr = [];
    orderDetails.order_data.map((polysterPlan, i) => {
      gridArr.push(
        <Grid item xs={12} key={polysterPlan.id}>
          <Card elevation={12}>
            <Grid key={polysterPlan.id} container>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
                sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="prod_id"
                    label="Product Id"
                    name="prod_id"
                    autoComplete ='off'
                    sx={{ backgroundColor: 'white' }}
                    value={polysterPlan.prod_id}
                    onChange={(event) => handleProductIdChange(event, polysterPlan)}
                    size='small'
                    fullWidth
                    onBlur={() => handleProductDetailsSearch(polysterPlan.id, polysterPlan.prod_id)}
                  />
                </Grid>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="prod_name"
                    label="Product Name"
                    name="prod_id"
                    autoComplete ='off'
                    sx={{ backgroundColor: 'white' }}
                    value={polysterPlan.prod_name}
                    size='small'
                    fullWidth
                    disabled={true}
                  />
                </Grid>
                {orderDetails.order_data.length > 1 ? (
                  <Grid item xs={3} sm={2} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    <IconButton
                      onClick={() => handleRemoveDataClick(polysterPlan.id)} color='error' size='small' sx={{ p: 1 }}>
                      <DeleteIcon/>
                    </IconButton>
                    <IconButton color='inherit' onClick={() => handleShowPlanDetails(polysterPlan)} >
                      {polysterPlan.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                  </Grid>
                ) : ''}
              </Grid>
              {polysterPlan.is_expanded == true ? polysterPlan.sub_data.map((planSubData, j) => (
                <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                  <Grid item xs={9} sm={3}>
                    <CustomAutocomplete
                      label={'Variety'}
                      apiInfo={{
                        searchURI: APIData.getVariety,
                        searchField: 'vrty_name',
                        idField: 'vrty_id',
                        searchParams: getVarietyNameSearchParams(polysterPlan.prod_id)
                      }}
                      defaultValue={{
                        vrty_id: planSubData.vrty_id,
                        vrty_name: planSubData.vrty_name
                      }}
                      onSelectionChange={(event, value) =>
                        handleAutoCompleteChange(value, planSubData, 'vrty_id', 'vrty_name')}
                    />
                  </Grid>
                  <Grid item xs={6} sm={1.5}>
                    <TextField
                      id="buy_price"
                      label="Buy Price"
                      name="buy_price"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.buy_price}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={1.5}>
                    <TextField
                      id="bought_quantity"
                      label="B/P Qty"
                      name="bought_quantity"
                      autoComplete ='off'
                      variant='standard'
                      value={validateStringForNull(planSubData.bought_quantity) ?
                        planSubData.bought_quantity + '/' + planSubData.planned_quantity : ''}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      disabled={true}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}sm={1.5}>
                    <TextField
                      id="no_of_sets"
                      label="No Of Sets"
                      name="no_of_sets"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.no_of_sets}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                      onBlur={() => computeBuyQuantityForPolyester(planSubData)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={1.5}>
                    <TextField
                      id="qty_per_set"
                      label="Quan Per Set"
                      name="qty_per_set"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.qty_per_set}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                      onBlur={() => computeBuyQuantityForPolyester(planSubData)}
                    />
                  </Grid>
                  <Grid item xs={6}sm={1.5}>
                    <TextField
                      id="buy_quantity"
                      label="Buy Quantity"
                      name="quantity"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.quantity}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={1.5} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    {j === polysterPlan.sub_data.length - 1 && (
                      <IconButton onClick={() => handleAddSubDataClick(polysterPlan)} color='primary' size='small'>
                        <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                      </IconButton>
                    )}
                    {polysterPlan.sub_data.length > 1 ? (
                      <IconButton onClick={() => handleRemoveSubDataClick(polysterPlan, planSubData.id)} color='error' size='small'>
                        <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                      </IconButton>
                    ) : ''}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              )) : ''}
            </Grid>
          </Card>
        </Grid>
      );
    });
    return gridArr;
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography variant='h5' align='center'>
            Orders Booking
          </Typography>
        </Grid>
        <Grid item xs={10} sm={3.5}>
          <FormControl fullWidth >
            <InputLabel size="small" id="plan-list-select-label"> Plan Id</InputLabel>
            <Select
              labelId="plan-list-select-label"
              id="plan-list-select"
              label="Plan id"
              name='plan_id'
              value={orderDetails.plan_id}
              onChange={(event) => handlePlanChange(event)}
              size="small"
            >
              {planList.map((obj) => (
                <MenuItem key={obj.plan_id} value={obj.plan_id}>
                  {obj.plan_id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1} >
          <Tooltip title='View Plan'>
            <IconButton
              sx={{ backgroundColor: 'white' }} color='primary'
              onClick={handleViewPlanClick}
              size='small'
            >
              <VisibilityIcon/>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={3.5}>
          <FormControl fullWidth >
            <InputLabel size="small" id="order-list-select-label"> Order Id</InputLabel>
            <Select
              labelId="order-list-select-label"
              id="order-list-select"
              label="Order Id"
              name='order_id'
              value={orderDetails.order_id}
              onChange={(event) => handleOrderChange(event)}
              size="small"
            >
              {orderList.map((obj) => (
                <MenuItem key={obj.order_id} value={obj.order_id}>
                  {obj.order_id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3.5}>
          <TextField
            id="material_type"
            label="Material Type"
            name="material_type"
            autoComplete ='off'
            value={validateStringForNull(orderDetails.material_type) ? MATERIAL_TYPE[orderDetails.material_type] : ''}
            size='small'
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <CustomAutocomplete
            label={'Supplier Name'}
            apiInfo={{
              searchURI: APIData.getSupplier,
              searchField: 'ac_name',
              idField: 'ac_id'
            }}
            defaultValue={{
              ac_id: orderDetails.ac_id,
              ac_name: orderDetails.ac_name
            }}
            onSelectionChange={(event, value) =>
              handleSupplierNameChange(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {getMaterialContent()}
        {validateStringForNull(orderDetails.material_type) ? (
          <Grid item container>
            <Grid item xs={6}>
              <Button
                variant='text'
                startIcon={<AddBoxIcon/>}
                size='small'
                color='primary'
                onClick={() => handleAddDataClick(orderDetails.material_type)}
              >
                Add Product
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
              <Button
                type='submit'
                variant="text"
                onClick={handleResetClick}
                sx={{ mr: 2 }}
              >
                Reset
              </Button>
              <Button
                type="submit"
                variant='contained'
                onClick={handleCreateOrUpdateOrderRequest}
              >
                {isUpdateRequest == true ? 'Update' : 'Create' }
              </Button>
            </Grid>
          </Grid>
        ) : ''}
      </Grid>
      <Dialog
        fullScreen
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }
        }}
        open={isViewPlanDialogOpen}
        TransitionComponent={Transition}
        onClose={() => {}}
        PaperProps={{
          sx: {
            width: {
              lg: '50%'
            }
          }
        }}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsViewPlanDialogOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              View Plan Details
            </Typography>
          </Toolbar>
        </AppBar>
        <ViewPlanDetails planId={orderDetails.plan_id} />
      </Dialog>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

export default OrdersBooking;
