import { Backdrop, Button, Card, Chip, CircularProgress, Divider, FormControl, Grid,
  IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { validateStringForNull } from '../../../../utils/FieldValidations';
import Services from '../../../../utils/Services';
import APIData from '../../../../utils/APIData';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { CookieUtils, Utils } from '../../../../utils/UtilFunctions';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { cloneDeep } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomAutocomplete from '../../../../common/components/CustomAutocomplete';
import { MATERIAL_TYPE } from '../../../../utils/EnumDefinitions';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const OrdersDirectBooking = () => {
  const [isLoading, setIsLoading] = useState(false);
  const purchaserId = CookieUtils.getUserId();
  const [orderList, setOrderList] = useState([]);
  const [isUpdateRequest, setIsUpdateRequest] = useState(false);
  const APIToken = {
    GET_DIRECT_BOOKING_ORDER_LIST: 'CODB01',
    GET_DIRECT_BOOKING_ORDER: 'CODB02',
    GET_PRODUCT_ID: 'CODB03',
    CALCULATE_SELL_PRICE: 'COB04',
    CREATE_DIRECT_BOOKING_ORDER: 'COB05',
    UPDATE_DIRECT_BOOKING_ORDER: 'COB06'
  };
  const [orderDetails, setOrderDetails] = useState({
    order_id: '',
    order_date: '',
    ac_id: '',
    ac_name: '',
    material_type: '',
    order_data: [],
    refresh_id: 0
  });
  const [originalValue, setOriginalValue] = useState(orderDetails);

  const readymadeDefaultSubObject = {
    id: '',
    model_id: '',
    model_name: '',
    brand_id: '',
    brand_name: '',
    size_id: '',
    buy_price: '',
    description: '',
    quantity: ''
  };
  const readyMadeDefaultObject = {
    id: '',
    cate_id: '',
    cate_name: '',
    prod_id: '',
    prod_name: '',
    is_expanded: true,
    sub_data: [readymadeDefaultSubObject]
  };

  const silkDefaultSubObject = {
    id: '',
    vrty_id: '',
    buy_price: '',
    sell_price: '',
    description: '',
    quantity: '',
    vrty_name: ''
  };
  const silkDefaultObject = {
    id: '',
    prod_id: '',
    prod_name: '',
    is_expanded: true,
    sub_data: [silkDefaultSubObject]
  };

  const polyesterDefaultSubObject = {
    id: '',
    vrty_id: '',
    buy_price: '',
    no_of_sets: '',
    description: '',
    quantity: '',
    vrty_name: '',
    qty_per_set: 6
  };
  const polyesterDefaultObject = {
    id: '',
    prod_id: '',
    prod_name: '',
    is_expanded: true,
    sub_data: [polyesterDefaultSubObject]
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (validateStringForNull(purchaserId)) {
      raiseGetOrderListRequest();
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getDirectBookingOrderList && apiToken == APIToken.GET_DIRECT_BOOKING_ORDER_LIST ) {
      setOrderList(response.data);
      const filteredObject = response.data.find((obj) => !obj.hasOwnProperty('ac_name'));
      setOrderDetails({ ...orderDetails,
        order_id: filteredObject.order_id,
        order_date: filteredObject.order_date,
        material_type: '',
        ac_id: '',
        ac_name: '',
        order_data: []
      });
      setIsUpdateRequest(false);
    } else if (apiData == APIData.getDirectBookingOrder && apiToken == APIToken.GET_DIRECT_BOOKING_ORDER ) {
      if (response.data != undefined) {
        setIsUpdateRequest(true);
        populateOrderData(response.data);
      }
    } else if (apiData == APIData.getProductId && apiToken == APIToken.GET_PRODUCT_ID ) {
      setProductName(callbackValues.id, response.data);
    } else if (apiData == APIData.calculateSellPrice && apiToken == APIToken.CALCULATE_SELL_PRICE) {
      if (response.data != undefined) {
        callbackValues.subData.sell_price = response.data.sell_price;
        notifyOrderDetailsChange();
      }
    } else if (apiData == APIData.createDirectBookingOrder && apiToken == APIToken.CREATE_DIRECT_BOOKING_ORDER) {
      showSnackBar('success', response.message ?? 'Order Created successfully');
      raiseGetOrderListRequest();
    } else if (apiData == APIData.updateDirectBookingOrder && apiToken == APIToken.UPDATE_DIRECT_BOOKING_ORDER) {
      showSnackBar('success', response.message ?? 'Order Updated Successfully');
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getDirectBookingOrderList && apiToken == APIToken.GET_DIRECT_BOOKING_ORDER_LIST) {
      defaultMsg = 'Failed to retrieve Order List';
    } else if (apiData == APIData.getDirectBookingOrder && apiToken == APIToken.GET_DIRECT_BOOKING_ORDER ) {
      defaultMsg = 'Failed to retrive Order';
    } else if (apiData == APIData.getProductId && apiToken == APIToken.GET_PRODUCT_ID ) {
      defaultMsg = 'Failed to get product details';
    } else if (apiData == APIData.calculateSellPrice && apiToken == APIToken.CALCULATE_SELL_PRICE) {
      defaultMsg = 'Failed to calulate sell price';
    } else if (apiData == APIData.createDirectBookingOrder && apiToken == APIToken.CREATE_DIRECT_BOOKING_ORDER) {
      defaultMsg = 'Failed to Create Order';
    } else if (apiData == APIData.updateDirectBookingOrder && apiToken == APIToken.UPDATE_DIRECT_BOOKING_ORDER) {
      defaultMsg = 'Failed to Update Order';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetOrderListRequest = () => {
    setIsLoading(true);
    const params = 'purchaser_id=' + purchaserId;
    Services.sendBackendRequest({ apiData: APIData.getDirectBookingOrderList, params: params },
      APIToken.GET_DIRECT_BOOKING_ORDER_LIST, processSuccess, processError );
  };

  const raiseGetOrderRequest = (orderId) => {
    setIsLoading(true);
    const params = 'order_id=' + orderId;
    Services.sendBackendRequest({ apiData: APIData.getDirectBookingOrder, params: params },
      APIToken.GET_DIRECT_BOOKING_ORDER, processSuccess, processError );
  };


  const raiseGetProductDetailsRequest = (id, productId) => {
    setIsLoading(true);
    const params = 'prod_id=' + productId;
    Services.sendBackendRequest({ apiData: APIData.getProductId, params: params },
      APIToken.GET_PRODUCT_ID, processSuccess, processError, { id: id });
  };

  const raiseCalulateSellPriceRequest = (subData, prodId) => {
    if (!validateStringForNull(subData.buy_price) || !validateStringForNull(prodId)) {
      return;
    }
    setIsLoading(true);
    const params = 'prod_id=' + prodId + '&buy_price=' + subData.buy_price;
    Services.sendBackendRequest({ apiData: APIData.calculateSellPrice, params: params },
      APIToken.CALCULATE_SELL_PRICE, processSuccess, processError, { subData: subData });
  };

  const notifyOrderDetailsChange = () => {
    setOrderDetails({ ...orderDetails, refresh_id: orderDetails.refresh_id + 1 });
  };

  const handleProductDetailsSearch = (id, prodId) => {
    if (validateStringForNull(prodId)) {
      raiseGetProductDetailsRequest(id, prodId);
    }
  };

  const populateOrderData = (data) => {
    const materialType = data.material_type;
    const obj = {};
    if (materialType == 3) {
      data.order_data.forEach((item) => {
        const key = `${item.cate_id}_${item.prod_id}`;
        if (!obj[key]) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            cate_name: item.cate_name,
            cate_id: item.cate_id,
            prod_id: item.prod_id,
            prod_name: item.prod_name,
            is_expanded: true,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          model_name: item.model_name,
          brand_name: item.brand_name,
          model_id: item.model_id,
          brand_id: item.brand_id,
          buy_price: item.buy_price,
          size_id: item.size_id,
          quantity: item.quantity,
          description: item.description
        });
      });
    } else if (materialType == 2) {
      data.order_data.forEach((item) => {
        const key = item.prod_id;
        if (!obj[key]) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: item.prod_id,
            prod_name: item.prod_name,
            is_expanded: true,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          buy_price: item.buy_price,
          sell_price: item.sell_price,
          quantity: item.quantity,
          vrty_id: item.vrty_id,
          description: item.description,
          vrty_name: item.vrty_name
        });
      });
    } else if (materialType == 1) {
      data.order_data.forEach((item) => {
        const key = item.prod_id;
        if (!obj[key]) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: item.prod_id,
            prod_name: item.prod_name,
            is_expanded: true,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          buy_price: item.buy_price,
          quantity: item.quantity,
          description: item.description,
          vrty_id: validateStringForNull(item.vrty_id) ? item.vrty_id : '',
          vrty_name: validateStringForNull(item.vrty_name) ? item.vrty_name : '',
          qty_per_set: 6,
          no_of_sets: validateStringForNull(item.no_of_sets) ? item.no_of_sets : ''
        });
      });
    }
    const filteredData = Object.values(obj);
    const filteredOrderObj = {
      order_id: data.order_id,
      order_date: data.order_date,
      material_type: data.material_type,
      ac_id: validateStringForNull(data.ac_id) ? data.ac_id: '',
      ac_name: validateStringForNull(data.ac_name) ? data.ac_name: '',
      order_data: filteredData
    };
    setOrderDetails(filteredOrderObj);
    setOriginalValue(cloneDeep(filteredOrderObj));
  };

  const computeBuyQuantityForPolyester = (subData) => {
    if (validateStringForNull(subData.no_of_sets) && validateStringForNull(subData.qty_per_set)) {
      const noOfSets = parseInt(subData.no_of_sets);
      const qtyPerSet = parseInt(subData.qty_per_set);
      subData.quantity = (noOfSets * qtyPerSet).toString();
      notifyOrderDetailsChange();
    }
  };

  const handleOrderChange = (event) => {
    const { name, value } = event.target;
    const filteredOrder = orderList.find((order) => order.order_id === value);
    if (filteredOrder != undefined && validateStringForNull(filteredOrder.ac_name)) {
      setOrderDetails({ ...orderDetails, [name]: value, order_data: [] });
      raiseGetOrderRequest(value);
    } else {
      setOrderDetails({ ...orderDetails,
        [name]: value,
        order_date: filteredOrder.order_date,
        ac_id: '',
        ac_name: '',
        material_type: '',
        order_data: []
      });
      setIsUpdateRequest(false);
    }
  };

  const setProductName = (id, response) => {
    const dataObj= orderDetails.order_data.find((item) => item.id === id);
    if (dataObj != undefined && response.length > 0) {
      const { prod_id: prodId, prod_name: prodName } = response[0];
      dataObj.prod_id = prodId;
      dataObj.prod_name = prodName;
      notifyOrderDetailsChange();
    }
  };

  const initializeMaterialUIComponent = (material) => {
    let obj = undefined;
    if (material === 3) {
      obj = readyMadeDefaultObject;
    } else if (material === 2) {
      obj = silkDefaultObject;
    } else {
      obj = polyesterDefaultObject;
    }
    obj.id = 1;
    obj.sub_data[0].id = 1;
    orderDetails.order_data = [obj];
    orderDetails.material_type = material;
    orderDetails.ac_id = validateStringForNull(orderDetails.ac_id) ? orderDetails.ac_id : '';
    orderDetails.ac_name = validateStringForNull(orderDetails.ac_name) ? orderDetails.ac_name : '';
    notifyOrderDetailsChange();
  };

  const handleMaterialCheckBoxClick = (value) => {
    initializeMaterialUIComponent(value);
  };

  const handleResetClick = () => {
    const cloned = cloneDeep(originalValue);
    setOrderDetails(cloned);
  };

  const handleAddDataClick = (material) => {
    const lastIdData = Math.max(...orderDetails.order_data.map((item) => item.id));
    let newObj = {};
    let newSubObj = {};
    if (material == 3) {
      newObj = readyMadeDefaultObject;
      newSubObj = readymadeDefaultSubObject;
    } else if (material == 2) {
      newObj = silkDefaultObject;
      newSubObj = silkDefaultSubObject;
    } else if (material == 1) {
      newObj = polyesterDefaultObject;
      newSubObj = polyesterDefaultSubObject;
    }
    newSubObj.id = 1;
    newObj.id = lastIdData + 1;
    newObj.sub_data = [newSubObj];
    orderDetails.order_data.push(newObj);
    notifyOrderDetailsChange();
  };

  const handleSupplierNameChange = (value, idField, nameField) => {
    if (validateStringForNull(value) && validateStringForNull(value.ac_id)) {
      setOrderDetails({ ...orderDetails,
        ac_id: value.ac_id,
        ac_name: value.ac_name
      });
    } else {
      setOrderDetails({ ...orderDetails,
        ac_id: '',
        ac_name: ''
      });
    }
  };

  const handleAutoCompleteChange = (value, data, idField, nameField) => {
    data[idField] = value != null ? value[idField] : '';
    data[nameField] = value != null ? value[nameField] : '';
    if (idField === 'cate_id') {
      data.prod_id = '';
      data.prod_name = '';
    }
    notifyOrderDetailsChange();
  };

  const handleAddSubDataClick = (data) => {
    const lastId = data.sub_data[data.sub_data.length - 1].id;
    let newSubObj = {};
    if (orderDetails.material_type == 3) {
      newSubObj = readymadeDefaultSubObject;
    } else if (orderDetails.material_type == 2) {
      newSubObj = silkDefaultSubObject;
    } else if (orderDetails.material_type == 1) {
      newSubObj = polyesterDefaultSubObject;
    }
    newSubObj.id = lastId + 1;
    data.sub_data.push(newSubObj);
    notifyOrderDetailsChange();
  };

  const handleRemoveSubDataClick = (data, subDataId) => {
    data.sub_data = data.sub_data.filter((subItem) => subItem.id !== subDataId);
    notifyOrderDetailsChange();
  };

  const handleRemoveDataClick = (id) => {
    orderDetails.order_data = orderDetails.order_data.filter((item) => item.id !== id);
    notifyOrderDetailsChange();
  };

  const handleProductIdChange = (event, data) => {
    const { name, value } = event.target;
    data[name] = value;
    if (!validateStringForNull(value)) {
      data.prod_name = '';
    }
    notifyOrderDetailsChange();
  };

  const handleSubDataTextChange = (event, subData) => {
    const { name, value } = event.target;
    subData[name] = value;
    notifyOrderDetailsChange();
  };

  const getProductNameSearchParams = (cateId) => {
    if (validateStringForNull(cateId)) {
      return 'cate_id='+ cateId;
    }
    return '';
  };

  const handleCreateOrUpdateOrderRequest = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(orderDetails);
      let filteredParams = {};
      if (params.material_type == 3) {
        filteredParams = validateReadyMadePlanParams(params);
      } else if (params.material_type == 2) {
        filteredParams = validateSilkPlanParams(params);
      } else if (params.material_type == 1) {
        filteredParams = validatePolyesterPlanParams(params);
      }
      raiseCreateOrUpdateOrderRequest(filteredParams);
    } catch (err) {
      showSnackBar('error', err.message ?? (isUpdateRequest ? 'Failed to Update Order' : 'Failed to Create Order'));
      setIsLoading(false);
    }
  };

  const validateOrderDetails = (params) => {
    const finalParams = {};
    if (!validateStringForNull(params.order_id)) {
      throw new Error('Select valid Order Id');
    }
    if (!validateStringForNull(params.order_date)) {
      throw new Error('Select valid Order Date');
    }
    if (!validateStringForNull(params.ac_id)) {
      throw new Error('Select valid Supplier Name');
    }
    if (!validateStringForNull(params.material_type)) {
      throw new Error('Select valid Material');
    }
    finalParams.order_id = params.order_id;
    finalParams.order_date = params.order_date;
    finalParams.material_type = parseInt(params.material_type);
    finalParams.ac_id = params.ac_id;

    return finalParams;
  };

  const validateSilkPlanParams = (params) => {
    const finalParams = validateOrderDetails(params);
    const planDataArray = [];

    params.order_data.forEach((dataItem) => {
      if (!validateStringForNull(dataItem.prod_id)) {
        throw new Error('Select valid Product');
      }

      dataItem.sub_data.forEach((subData) => {
        if (!validateStringForNull(subData.vrty_id)) {
          throw new Error('Provide valid Variety');
        }
        if (!validateStringForNull(subData.buy_price)) {
          throw new Error('Provide valid Buy Price');
        }
        if (!validateStringForNull(subData.sell_price)) {
          throw new Error('Provide valid Sell Price');
        }
        if (!validateStringForNull(subData.description)) {
          throw new Error('Provide valid Description');
        }
        if (!validateStringForNull(subData.quantity)) {
          throw new Error('Provide valid Buy Quantity');
        }
        planDataArray.push({
          prod_id: dataItem.prod_id,
          vrty_id: subData.vrty_id,
          buy_price: parseFloat(subData.buy_price),
          sell_price: parseFloat(subData.sell_price),
          description: subData.description,
          quantity: parseInt(subData.quantity)
        });
      });
    });
    finalParams.order_data = planDataArray;
    return finalParams;
  };

  const validateReadyMadePlanParams = (params) => {
    const finalParams = validateOrderDetails(params);
    const planDataArray = [];

    params.order_data.forEach((dataItem) => {
      if (!validateStringForNull(dataItem.cate_id)) {
        throw new Error('Select valid Category');
      }
      if (!validateStringForNull(dataItem.prod_id)) {
        throw new Error('Select valid Product');
      }

      dataItem.sub_data.forEach((subData) => {
        if (!validateStringForNull(subData.size_id)) {
          throw new Error('Provide valid Size');
        }
        if (!validateStringForNull(subData.buy_price)) {
          throw new Error('Provide valid Buy Price');
        }
        if (!validateStringForNull(subData.description)) {
          throw new Error('Provide valid Description');
        }
        if (!validateStringForNull(subData.quantity)) {
          throw new Error('Provide valid Buy Quantity');
        }
        const optionalData = {};
        if (validateStringForNull(subData.model_id)) {
          optionalData.model_id = subData.model_id;
        }
        if (validateStringForNull(subData.brand_id)) {
          optionalData.brand_id = subData.brand_id;
        }
        planDataArray.push({
          cate_id: dataItem.cate_id,
          prod_id: dataItem.prod_id,
          size_id: subData.size_id,
          buy_price: parseFloat(subData.buy_price),
          description: subData.description,
          quantity: parseInt(subData.quantity),
          ...optionalData
        });
      });
    });
    finalParams.order_data = planDataArray;
    return finalParams;
  };

  const validatePolyesterPlanParams = (params) => {
    const finalParams = validateOrderDetails(params);
    const planDataArray = [];

    params.order_data.forEach((dataItem) => {
      if (!validateStringForNull(dataItem.prod_id)) {
        throw new Error('Select valid Product');
      }

      dataItem.sub_data.forEach((subData) => {
        if (!validateStringForNull(subData.vrty_id)) {
          throw new Error('Provide valid Variety');
        }
        if (!validateStringForNull(subData.buy_price)) {
          throw new Error('Provide valid Buy Price');
        }
        if (!validateStringForNull(subData.description)) {
          throw new Error('Provide valid Description');
        }
        if (!validateStringForNull(subData.quantity)) {
          throw new Error('Provide valid Buy Quantity');
        }
        planDataArray.push({
          prod_id: dataItem.prod_id,
          vrty_id: subData.vrty_id,
          buy_price: parseFloat(subData.buy_price),
          description: subData.description,
          quantity: parseInt(subData.quantity)
        });
      });
    });
    finalParams.order_data = planDataArray;
    return finalParams;
  };

  const raiseCreateOrUpdateOrderRequest = (finalParams) => {
    let apiData = undefined;
    let apiToken = undefined;
    if (isUpdateRequest) {
      apiData = APIData.updateDirectBookingOrder;
      apiToken = APIToken.UPDATE_DIRECT_BOOKING_ORDER;
    } else {
      apiData = APIData.createDirectBookingOrder;
      apiToken = APIToken.CREATE_DIRECT_BOOKING_ORDER;
    }
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: apiData, params: finalParams },
      apiToken, processSuccess, processError );
  };

  const handleShowPlanDetails = (data) => {
    data.is_expanded = !data.is_expanded;
    notifyOrderDetailsChange();
  };

  const getVarietyNameSearchParams = (prodId) => {
    if (validateStringForNull(prodId)) {
      return 'prod_id='+prodId;
    }
    return '';
  };

  const getMaterialContent = () => {
    const materialType = orderDetails.material_type;
    switch (materialType) {
    case 1:
      return getPolyesterPlanUIContent(orderDetails.material_type);
    case 2:
      return getSilkPlanUIContent(orderDetails.material_type);
    case 3:
      return getReadyMadePlanUIContent(orderDetails.material_type);
    default:
      return '';
    }
  };

  const getReadyMadePlanUIContent = (materialType) => {
    const gridArr = [];
    orderDetails.order_data.map((readyMadePlan, i) => {
      gridArr.push(
        <Grid item xs={12} key={readyMadePlan.id}>
          <Card elevation={12}>
            <Grid key={readyMadePlan.id} container>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
                sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
                <Grid item xs={9} sm={5}>
                  <CustomAutocomplete
                    label={'Category Name'}
                    apiInfo={{
                      searchURI: APIData.getCategory,
                      searchField: 'cate_name',
                      idField: 'cate_id'
                    }}
                    defaultValue={{
                      cate_id: readyMadePlan.cate_id,
                      cate_name: readyMadePlan.cate_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, readyMadePlan, 'cate_id', 'cate_name')}
                  />
                </Grid>
                <Grid item xs={9} sm={5}>
                  <CustomAutocomplete
                    label={'Product Name'}
                    apiInfo={{
                      searchURI: APIData.getProductName,
                      searchField: 'prod_name',
                      idField: 'prod_id',
                      searchParams: getProductNameSearchParams(readyMadePlan.cate_id)
                    }}
                    defaultValue={{
                      prod_id: readyMadePlan.prod_id,
                      prod_name: readyMadePlan.prod_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, readyMadePlan, 'prod_id', 'prod_name')}
                  />
                </Grid>
                {orderDetails.order_data.length > 1 ? (
                  <Grid item xs={3} sm={2} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    <IconButton
                      onClick={() => handleRemoveDataClick(readyMadePlan.id)} color='error' size='small' sx={{ p: 1 }}>
                      <DeleteIcon/>
                    </IconButton>
                    <IconButton color='primary' onClick={() => handleShowPlanDetails(readyMadePlan)}>
                      {readyMadePlan.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                  </Grid>
                ) : ''}
              </Grid>
              {readyMadePlan.is_expanded == true ? readyMadePlan.sub_data.map((planSubData, j) => (
                <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                  <Grid item container xs={12} lg={5} rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={6} sm={5} lg={6}>
                      <CustomAutocomplete
                        label={'Model Name'}
                        apiInfo={{
                          searchURI: APIData.getModel,
                          searchField: 'model_name',
                          idField: 'model_id'
                        }}
                        defaultValue={{
                          model_id: planSubData.model_id,
                          model_name: planSubData.model_name
                        }}
                        onSelectionChange={(event, value) =>
                          handleAutoCompleteChange(value, planSubData, 'model_id', 'model_name')}
                      />
                    </Grid>
                    <Grid item xs={6} sm={5} lg={6}>
                      <CustomAutocomplete
                        label={'Brand Name'}
                        apiInfo={{
                          searchURI: APIData.getBrand,
                          searchField: 'brand_name',
                          idField: 'brand_id'
                        }}
                        defaultValue={{
                          brand_id: planSubData.brand_id,
                          brand_name: planSubData.brand_name
                        }}
                        onSelectionChange={(event, value) =>
                          handleAutoCompleteChange(value, planSubData, 'brand_id', 'brand_name')}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} lg={7} rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={4} sm={1.5}>
                      <TextField
                        id="size"
                        label="Size"
                        name="size_id"
                        autoComplete ='off'
                        variant='standard'
                        value={planSubData.size_id}
                        onChange={(event) => handleSubDataTextChange(event, planSubData)}
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={8} sm={3.5}>
                      <TextField
                        id="description"
                        label="Description"
                        name="description"
                        autoComplete ='off'
                        variant='standard'
                        value={planSubData.description}
                        onChange={(event) => handleSubDataTextChange(event, planSubData)}
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4} sm={2.5}>
                      <TextField
                        id="buy_price"
                        label="Buy Price"
                        name="buy_price"
                        autoComplete ='off'
                        variant='standard'
                        value={planSubData.buy_price}
                        onChange={(event) => handleSubDataTextChange(event, planSubData)}
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4} sm={2.5}>
                      <TextField
                        id="buy_quantity"
                        label="Buy Quantity"
                        name="quantity"
                        autoComplete ='off'
                        variant='standard'
                        value={planSubData.quantity}
                        onChange={(event) => handleSubDataTextChange(event, planSubData)}
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4} sm={2} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                      {j === readyMadePlan.sub_data.length - 1 && (
                        <IconButton onClick={() => handleAddSubDataClick(readyMadePlan)} color='primary' size='small'>
                          <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                        </IconButton>
                      )}
                      {readyMadePlan.sub_data.length > 1 ? (
                        <IconButton onClick={() => handleRemoveSubDataClick(readyMadePlan, planSubData.id)} color='error' size='small'>
                          <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                        </IconButton>
                      ) : ''}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              )) : ''}
            </Grid>
          </Card>
        </Grid>
      );
    });
    return gridArr;
  };

  const getSilkPlanUIContent = (materialType) => {
    const gridArr = [];
    orderDetails.order_data.map((silkPlan, i) => {
      gridArr.push(
        <Grid item xs={12} key={silkPlan.id}>
          <Card elevation={12}>
            <Grid key={silkPlan.id} container>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
                sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="prod_id"
                    label="Product Id"
                    name="prod_id"
                    autoComplete ='off'
                    sx={{ backgroundColor: 'white' }}
                    value={silkPlan.prod_id}
                    onChange={(event) => handleProductIdChange(event, silkPlan)}
                    size='small'
                    fullWidth
                    onBlur={() => handleProductDetailsSearch(silkPlan.id, silkPlan.prod_id)}
                  />
                </Grid>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="prod_name"
                    label="Product Name"
                    name="prod_id"
                    autoComplete ='off'
                    sx={{ backgroundColor: 'white' }}
                    value={silkPlan.prod_name}
                    size='small'
                    fullWidth
                    disabled={true}
                  />
                </Grid>
                {orderDetails.order_data.length > 1 ? (
                  <Grid item xs={3} sm={2} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    <IconButton
                      onClick={() => handleRemoveDataClick(silkPlan.id)} color='error' size='small' sx={{ p: 1 }}>
                      <DeleteIcon/>
                    </IconButton>
                    <IconButton color='primary' onClick={() => handleShowPlanDetails(silkPlan)} >
                      {silkPlan.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                  </Grid>
                ) : ''}
              </Grid>
              {silkPlan.is_expanded == true ? silkPlan.sub_data.map((planSubData, j) => (
                <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                  <Grid item xs={6} sm={2.5}>
                    <CustomAutocomplete
                      label={'Variety'}
                      apiInfo={{
                        searchURI: APIData.getVariety,
                        searchField: 'vrty_name',
                        idField: 'vrty_id',
                        searchParams: getVarietyNameSearchParams(silkPlan.prod_id)
                      }}
                      defaultValue={{
                        vrty_id: planSubData.vrty_id,
                        vrty_name: planSubData.vrty_name
                      }}
                      onSelectionChange={(event, value) =>
                        handleAutoCompleteChange(value, planSubData, 'vrty_id', 'vrty_name')}
                    />
                  </Grid>
                  <Grid item xs={6}sm={2.5}>
                    <TextField
                      id="description"
                      label="Description"
                      name="description"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.description}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <TextField
                      id="buy_price"
                      label="Buy Price"
                      name="buy_price"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.buy_price}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                      onBlur={(event, value) => raiseCalulateSellPriceRequest(planSubData, silkPlan.prod_id)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <TextField
                      id="sell_price"
                      label="Sell Price"
                      name="sell_price"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.sell_price}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}sm={1.5}>
                    <TextField
                      id="buy_quantity"
                      label="Buy Qty"
                      name="quantity"
                      variant='standard'
                      autoComplete ='off'
                      value={planSubData.quantity}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={1.5} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    {j === silkPlan.sub_data.length - 1 && (
                      <IconButton onClick={() => handleAddSubDataClick(silkPlan)} color='primary' size='small'>
                        <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                      </IconButton>
                    )}
                    {silkPlan.sub_data.length > 1 ? (
                      <IconButton onClick={() => handleRemoveSubDataClick(silkPlan, planSubData.id)} color='error' size='small'>
                        <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                      </IconButton>
                    ) : ''}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              )) : ''}
            </Grid>
          </Card>
        </Grid>
      );
    });
    return gridArr;
  };

  const getPolyesterPlanUIContent = (materialType) => {
    const gridArr = [];
    orderDetails.order_data.map((polyesterPlan, i) => {
      gridArr.push(
        <Grid item xs={12} key={polyesterPlan.id}>
          <Card elevation={12}>
            <Grid key={polyesterPlan.id} container>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
                sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="prod_id"
                    label="Product Id"
                    name="prod_id"
                    autoComplete ='off'
                    sx={{ backgroundColor: 'white' }}
                    value={polyesterPlan.prod_id}
                    onChange={(event) => handleProductIdChange(event, polyesterPlan)}
                    size='small'
                    fullWidth
                    onBlur={() => handleProductDetailsSearch(polyesterPlan.id, polyesterPlan.prod_id)}
                  />
                </Grid>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="prod_name"
                    label="Product Name"
                    name="prod_id"
                    autoComplete ='off'
                    sx={{ backgroundColor: 'white' }}
                    value={polyesterPlan.prod_name}
                    size='small'
                    fullWidth
                    disabled={true}
                  />
                </Grid>
                {orderDetails.order_data.length > 1 ? (
                  <Grid item xs={3} sm={2} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    <IconButton
                      onClick={() => handleRemoveDataClick(polyesterPlan.id)} color='error' size='small' sx={{ p: 1 }}>
                      <DeleteIcon/>
                    </IconButton>
                    <IconButton color='primary' onClick={() => handleShowPlanDetails(polyesterPlan)} >
                      {polyesterPlan.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                  </Grid>
                ) : ''}
              </Grid>
              {polyesterPlan.is_expanded == true ? polyesterPlan.sub_data.map((planSubData, j) => (
                <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                  <Grid item xs={9} sm={2}>
                    <CustomAutocomplete
                      label={'Variety'}
                      apiInfo={{
                        searchURI: APIData.getVariety,
                        searchField: 'vrty_name',
                        idField: 'vrty_id',
                        searchParams: getVarietyNameSearchParams(polyesterPlan.prod_id)
                      }}
                      defaultValue={{
                        vrty_id: planSubData.vrty_id,
                        vrty_name: planSubData.vrty_name
                      }}
                      onSelectionChange={(event, value) =>
                        handleAutoCompleteChange(value, planSubData, 'vrty_id', 'vrty_name')}
                    />
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <TextField
                      id="buy_price"
                      label="Buy Price"
                      name="buy_price"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.buy_price}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}sm={2}>
                    <TextField
                      id="description"
                      label="Description"
                      name="description"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.description}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}sm={1.5}>
                    <TextField
                      id="no_of_sets"
                      label="No Of Sets"
                      name="no_of_sets"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.no_of_sets}
                      onChange={(event) =>handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                      onBlur={() => computeBuyQuantityForPolyester(planSubData)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={1.5}>
                    <TextField
                      id="qty_per_set"
                      label="Quan Per Set"
                      name="qty_per_set"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.qty_per_set}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                      onBlur={() => computeBuyQuantityForPolyester(planSubData)}
                    />
                  </Grid>
                  <Grid item xs={6}sm={1.5}>
                    <TextField
                      id="buy_quantity"
                      label="Buy Qty"
                      name="quantity"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.quantity}
                      onChange={(event) => handleSubDataTextChange(event, planSubData)}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={1.5} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    {j === polyesterPlan.sub_data.length - 1 && (
                      <IconButton onClick={() => handleAddSubDataClick(polyesterPlan)} color='primary' size='small'>
                        <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                      </IconButton>
                    )}
                    {polyesterPlan.sub_data.length > 1 ? (
                      <IconButton onClick={() => handleRemoveSubDataClick(polyesterPlan, planSubData.id)} color='error' size='small'>
                        <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                      </IconButton>
                    ) : ''}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              )) : ''}
            </Grid>
          </Card>
        </Grid>
      );
    });
    return gridArr;
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography variant='h5' align='center'>
            Orders Direct Booking
          </Typography>
        </Grid>
        <Grid item xs={7} sm={4}>
          <FormControl fullWidth >
            <InputLabel size="small" id="order-list-select-label"> Order Id</InputLabel>
            <Select
              labelId="order-list-select-label"
              id="order-list-select"
              label="Order Id"
              name='order_id'
              value={orderDetails.order_id}
              onChange={(event) => handleOrderChange(event)}
              size="small"
            >
              {orderList.map((obj) => (
                <MenuItem key={obj.order_id} value={obj.order_id}>
                  {obj.order_id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5} sm={3}>
          <TextField
            id="Order Date"
            label="Order Date"
            name="order_date"
            autoComplete ='off'
            value={orderDetails.order_date}
            size='small'
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <CustomAutocomplete
            label={'Supplier Name'}
            apiInfo={{
              searchURI: APIData.getSupplier,
              searchField: 'ac_name',
              idField: 'ac_id'
            }}
            defaultValue={{
              ac_id: orderDetails.ac_id,
              ac_name: orderDetails.ac_name
            }}
            onSelectionChange={(event, value) =>
              handleSupplierNameChange(value)}
          />
        </Grid>
        <Grid item container xs={12} sm={12}>
          <Grid item sx={{ mr: 1, display: 'flex', alignItems: 'center' }} >
            <Typography component='h5'>
              Material
            </Typography>
          </Grid>
          <Grid item >
            {Object.keys(MATERIAL_TYPE).map((material) => (
              <Chip sx={{ m: 1 }}
                key={material} label={MATERIAL_TYPE[material]}
                onClick={() => handleMaterialCheckBoxClick(parseInt(material))}
                color={orderDetails.material_type == material ? 'primary' : undefined}
                disabled={isUpdateRequest}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {getMaterialContent()}
        {validateStringForNull(orderDetails.material_type) ? (
          <Grid item container>
            <Grid item xs={6}>
              <Button
                variant='text'
                startIcon={<AddBoxIcon/>}
                size='small'
                color='primary'
                onClick={() => handleAddDataClick(orderDetails.material_type)}
              >
                Add Product
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
              <Button
                type='submit'
                variant="text"
                onClick={handleResetClick}
                sx={{ mr: 2 }}
              >
                Reset
              </Button>
              <Button
                type="submit"
                variant='contained'
                onClick={handleCreateOrUpdateOrderRequest}
              >
                {isUpdateRequest == true ? 'Update' : 'Create' }
              </Button>
            </Grid>
          </Grid>
        ) : ''}
      </Grid>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

export default OrdersDirectBooking;
