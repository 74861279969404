import { Helmet } from 'react-helmet';
import { Box, Container, Grid, Backdrop, CircularProgress, Tooltip, Badge, Typography } from '@mui/material';
import ReportsTable from '../components/reports/ReportsTable';
import TableModal from '../components/reports/TableModal';
import React, { useState } from 'react';
import { Button, FormControl, Select, MenuItem, InputLabel, Modal } from '@mui/material';
import CustomSnackbar from '../../../common/components/CustomSnackbar';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { blue } from '@mui/material/colors';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useEffect } from 'react';
import { validateDate } from '../../../utils/FieldValidations';
import APIData from '../../../utils/APIData';

const SalesByRangeReports = () => {
  const [params, setParams] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [instanceId, setInstanceId] = useState(0);
  const [totalTableCompletion, setTotalTableCompletion] = useState(false);
  const [nonTotalTableCompletion, setNonTotalTableCompletion] = useState(false);
  const [obj, setObj] = useState({
    date_range1: 7,
    from_date1: null,
    to_date1: null,
    date_range2: 0,
    from_date2: null,
    to_date2: null
  });
  const [itemName, setItemName] = useState('');
  const [priceRange, setPriceRange] = useState(undefined);
  const [rangeCount, setRangeCount] = useState(0);
  const snackBarStatusDefault = {
    open: false,
    severity: 'info',
    message: ''
  };
  const [snackBarStatus, setSnackBarStatus] = useState(snackBarStatusDefault);
  const handleSnackBarClose = () => {
    setSnackBarStatus(snackBarStatusDefault);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );
  const [openModal, setModalOpen] = useState(false);

  const handleChoiceChange = (event) => {
    setObj({ ...obj, [event.target.name]: event.target.value });
  };

  const handleDateValueChange = (date, name) => {
    setObj({ ...obj, [name]: date });
  };

  const handleItemChoiceChange = (event) => {
    setItemName(event.target.value);
  };

  const handleSalesRange = (arr) => {
    let range = '';
    let count = 0;
    for (let i = 0; i< arr.length; i++) {
      if (arr[i].to == '' || isNaN(arr[i].to)) {
        arr[i].to = 0;
      }
      if (arr[i].from != '') {
        if (isNaN(arr[i].from)) {
          arr[i].from = 0;
        }
        range += arr[i].from+'-'+arr[i].to;
        range+=',';
        count++;
      }
    }
    if (range.charAt(range.length-1) == ',') {
      range = range.slice(0, -1);
    }
    setPriceRange(range);
    setRangeCount(count);
    setModalOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (totalTableCompletion && nonTotalTableCompletion) {
      setIsLoading(false);
    }
  }, [totalTableCompletion, nonTotalTableCompletion]);

  const handleSubmit=()=>{
    setTotalTableCompletion(false);
    setNonTotalTableCompletion(false);
    setIsLoading(true);
    try {
      if (obj.date_range1 == 0 || obj.date_range1 ==undefined) {
        throw new Error('Select Date Range 1');
      } else if (obj.date_range1==7 && !validateDate(obj.from_date1)) {
        throw new Error('Select From Date for Range 1');
      } else if (obj.date_range1==7 && !validateDate(obj.to_date1)) {
        throw new Error('Select To Date for Range 1');
      } else if (obj.date_range2==7 && !validateDate(obj.from_date2)) {
        throw new Error('Select From Date for Range 2');
      } else if (obj.date_range2==7 && !validateDate(obj.to_date2)) {
        throw new Error('Select To Date for Range 2');
      } else if (itemName == undefined) {
        throw new Error('Select any Item Name');
      } else if (priceRange == undefined || priceRange == '') {
        throw new Error('Enter proper price range');
      }
      let queryString = '';
      if (obj.date_range1==7) {
        queryString = 'from_date1='+moment(obj.from_date1).format('yyyy-MM-DD')+'&to_date1='+moment(obj.to_date1).format('yyyy-MM-DD');
      } else {
        queryString = 'date_range1='+obj.date_range1;
      }

      if (obj.date_range2!= 0 && obj.date_range2!= undefined ) {
        if (obj.date_range2==7) {
          queryString += '&from_date2='+moment(obj.from_date2).format('yyyy-MM-DD')+'&to_date2='+moment(obj.to_date2).format('yyyy-MM-DD');
        } else {
          queryString += '&date_range2='+obj.date_range2;
        }
      }
      queryString += '&item_name='+itemName+'&sale_range='+priceRange;
      setParams(queryString);
      setInstanceId(instanceId + 1);
    } catch (error) {
      setIsLoading(false);
      handleAPIException(error ?? 'Failed to fetch Reports for the given Inputs');
    }
  };

  const getObjLooping = ( data, obj, num ) =>{
    const columns = [];
    const columnsOrder= ['bills', 'quantity', 'amount', 'ticket_size', 'basket_size'];
    columnsOrder.map((key) => {
      if (data[0][obj][key] !==undefined) {
        const columnObj = {};
        columnObj.field = obj + '.' + key;
        columnObj.headerName = key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ');
        columns.push(columnObj);
      }
    });
    return columns;
  };

  const getColumns = (dataReport, isTotalTable) => { // give data.total or data.split_up as input
    const columns = [];
    const data = dataReport.reports;
    if (!isTotalTable) {
      columns.push({
        field: 'sale_range',
        headerName: 'Sale Range'
      });
    }
    const columnsObj1= {
      headerName: dataReport.duration_names['duration1'],
      children: getObjLooping( data, 'duration1' )
    };
    let finalColumn = [];
    if ('duration2' in data[0]) {
      const columnsObj2= {
        headerName: dataReport.duration_names['duration2'],
        children: getObjLooping( data, 'duration2')
      };
      finalColumn = columns.concat(columnsObj1, columnsObj2);
    } else {
      finalColumn = columns.concat(columnsObj1);
    }
    return finalColumn;
  };

  const getDate =( labelName, name, onChangeFunc, dateRange ) => {
    return (
      <React.Fragment>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label = { labelName }
            format="DD/MM/YYYY"
            disableFuture
            disabled={obj[dateRange]!=7}
            value={obj[name]}
            onChange= {(date) => onChangeFunc(date, name)}
            slotProps={{ textField: { variant: 'standard', size: 'small', error: false } }}
          />
        </LocalizationProvider>
      </React.Fragment>
    );
  };

  const getDropDown =(label, handleChoiceChange, dateRange) => {
    return (
      <FormControl sx={{ width: '100%' }}>
        <InputLabel>{label}</InputLabel>
        <Select
          name={dateRange}
          value= {obj[dateRange]}
          variant = 'standard'
          onChange={handleChoiceChange}
        >
          { getMenuItems() }
        </Select>
      </FormControl>
    );
  };

  const getMenuItems =() => {
    const choices = ['None', 'Today', 'Yesterday', 'This Month', 'Last Month', 'This Year', 'Last Year', 'Custom'];
    const choiceArray = [];
    for (let i = 0; i < choices.length; i++) {
      choiceArray[i] = (
        <MenuItem key={i} value={i}>{ choices[i] }</MenuItem>
      );
    };
    return choiceArray;
  };

  const getItemNameDropDown =() => {
    return (
      <FormControl sx={{ width: '100%' }}>
        <InputLabel>Item Name</InputLabel>
        <Select
          name={'itemName'}
          value= {itemName}
          variant = 'standard'
          onChange={handleItemChoiceChange}
        >
          { getItemNames() }
        </Select>
      </FormControl>
    );
  };

  const getItemNames =() => {
    const choices = ['Silk', 'Aparna', 'Readymade', 'Textiles'];
    const choiceArray = [];
    for (let i = 0; i < choices.length; i++) {
      choiceArray[i] = (
        <MenuItem key={i} value={choices[i]}>{ choices[i] }</MenuItem>
      );
    };
    return choiceArray;
  };

  const handleAPIException=(error)=>{
    setSnackBarStatus({
      open: true,
      severity: 'error',
      message: error.message ?? 'Failed to fetch Reports data'
    });
  };

  const handleAPICompletion=(isTotalTable)=>{
    if (isTotalTable) {
      setTotalTableCompletion(true);
    } else {
      setNonTotalTableCompletion(true);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Price-wise Sales Report | Rajmahal Apps</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: 'calc(100vh - 98px)'
        }}
      >
        <Container maxWidth={false}>
          <Grid xs={12} justifyContent='center' display='flex' sx={{ py: 1 }}>
            <Typography
              color="textPrimary"
              variant="h5">
              Price-wise Sales Report
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent= 'space-between'
            marginBottom={1}
          >
            <Grid
              item
              mt={1}
              xs={12} sm={6} lg={2.5}
              sx={{ background: '#f6f6f6', border: 1, borderRadius: 1, borderColor: 'lightgrey' }}
            >
              <Grid
                container
                justifyContent={'space-between'}
                padding={1}
                alignItems='center'>
                <Grid item xs={9} marginRight={2}>
                  { getItemNameDropDown() }
                </Grid>
                <Grid item xs={2} >
                  <Tooltip title={ 'Select Price Range' }>
                    <Badge color='error' overlap='circular' badgeContent={rangeCount} max={99}>
                      <Button type='submit' variant='contained' onClick={()=>setModalOpen(true)} sx={{ width: 25, minWidth: 20, margin: .5 }}>
                        <CurrencyRupeeIcon style={{ fontSize: 20, cursor: 'pointer' }}/>
                      </Button>
                    </Badge>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              mt={1}
              sx={{ background: '#f6f6f6', border: 1, borderRadius: 1, borderColor: 'lightgrey' }}
              xs={12} lg={4}
            >
              <Grid
                container
                justifyContent={'space-between'}
                pt={1.5} pb={1} px={2}
              >
                <Grid item xs={4}>
                  { getDropDown('Date Range 1', handleChoiceChange, 'date_range1') }
                </Grid>
                <Grid item xs={3.5}>
                  { getDate('From Date', 'from_date1', handleDateValueChange, 'date_range1') }
                </Grid>
                <Grid item xs={3.5}>
                  { getDate('To Date', 'to_date1', handleDateValueChange, 'date_range1') }
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              mt={1}
              xs={12} lg={4}
              sx={{ background: '#f6f6f6', border: 1, borderRadius: 1, borderColor: 'lightgrey' }}>
              <Grid
                container
                justifyContent={'space-between'}
                pt={1.5} pb={1} px={2}>
                <Grid item xs={4}>
                  { getDropDown('Date Range 2', handleChoiceChange, 'date_range2') }
                </Grid>
                <Grid item xs={3.5} >
                  { getDate('From Date', 'from_date2', handleDateValueChange, 'date_range2') }
                </Grid>
                <Grid item xs={3.5} >
                  { getDate('To Date', 'to_date2', handleDateValueChange, 'date_range2') }
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              mt={1}
              xs={12} lg={1}
              sx={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
                textAlign: 'center'
              }}
            >
              <Button type='submit' variant='contained' onClick={handleSubmit}>SUBMIT</Button>
            </Grid>
          </Grid>
          { params!= undefined ? (
            <React.Fragment>
              <Grid item padding={1}>
                <ReportsTable reportsURI={APIData.getItemRangeReport} isTotalTable={true} queryParams={params} getColumns={getColumns}
                  handleException={handleAPIException} handleCompletion={handleAPICompletion} instanceId={instanceId}/>
              </Grid>
              <Grid item padding={1}>
                <ReportsTable reportsURI={APIData.getItemRangeReport} isTotalTable={false} queryParams={params} getColumns={getColumns}
                  handleException={handleAPIException} handleCompletion={handleAPICompletion} instanceId={instanceId}/>
              </Grid>
            </React.Fragment>
          ) : ''}
          <Modal
            open={openModal}
            onClose={()=> setModalOpen(false)}
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              border: 0,
              padding: 2 }}>
              <TableModal handleSalesRange={handleSalesRange} handleModalClose={handleModalClose} priceRange={priceRange}/>
            </Box>
          </Modal>
        </Container>
      </Box>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </React.Fragment>
  );
};

export default SalesByRangeReports;
