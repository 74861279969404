import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Container, CircularProgress, Grid, TextField, Card, IconButton, Divider } from '@mui/material';
import { Utils } from '../../../../utils/UtilFunctions';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { MATERIAL_TYPE } from '../../../../utils/EnumDefinitions';
import { validateStringForNull } from '../../../../utils/FieldValidations';
import Services from '../../../../utils/Services';
import APIData from '../../../../utils/APIData';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ViewPlanDetails = ({ planId }) => {
  const [planDetails, setPlanDetails] = useState({
    plan_id: '',
    plan_date: '',
    plan_tag: '',
    material_type: '',
    plan_data: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const APIToken = {
    VIEW_PLAN_DETAILS: 'CVPD01'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (validateStringForNull(planId)) {
      raiseGetPlanRequest(planId);
    }
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getPlanDetails && apiToken == APIToken.VIEW_PLAN_DETAILS ) {
      if (response.data != undefined) {
        populatePlanData(response.data);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getPlanDetails && apiToken == APIToken.VIEW_PLAN_DETAILS) {
      defaultMsg = 'Failed to retrieve plan list';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetPlanRequest = (planId) => {
    const params = 'plan_id=' + planId;
    Services.sendBackendRequest({ apiData: APIData.getPlanDetails, params: params },
      APIToken.VIEW_PLAN_DETAILS, processSuccess, processError);
  };

  const populatePlanData = (data) => {
    const materialType = data.material_type;
    const obj = {};
    if (materialType == 3) {
      data.plan_data.forEach((item) => {
        const key = `${item.cate_id}_${item.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            cate_name: item.cate_name,
            cate_id: item.cate_id,
            prod_id: item.prod_id,
            prod_name: item.prod_name,
            is_expanded: true,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          model_name: item.model_name,
          brand_name: item.brand_name,
          model_id: item.model_id,
          brand_id: item.brand_id,
          min_price: item.min_price,
          max_price: item.max_price,
          quantity: item.quantity
        });
      });
    } else {
      data.plan_data.forEach((item) => {
        const key = item.prod_id;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: item.prod_id,
            prod_name: item.prod_name,
            is_expanded: true,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          max_price: item.max_price,
          min_price: item.min_price,
          quantity: item.quantity
        });
      });
    }
    const filteredData = Object.values(obj);
    setPlanDetails({
      ...planDetails,
      plan_id: data.plan_id,
      plan_date: data.plan_date,
      material_type: data.material_type,
      plan_tag: validateStringForNull(data.plan_tag) ? data.plan_tag: '',
      plan_data: filteredData
    });
  };

  const handleShowPlanDetails = (id) => {
    setPlanDetails((prevPlanDetails) => ({
      ...prevPlanDetails,
      plan_data: prevPlanDetails.plan_data.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            is_expanded: !item.is_expanded
          };
        }
        return item;
      })
    }));
  };

  const getMaterialUIContent = () => {
    if (planDetails.plan_data.length != 0) {
      if (planDetails.material_type == 3) {
        return getReadyMadePlanUIContent(planDetails.material_type);
      } else if (planDetails.material_type == 2 || planDetails.material_type == 1) {
        return getSilkAndPolysterPlanUIContent(planDetails.material_type);
      } else {
        return '';
      }
    };
  };

  const getReadyMadePlanUIContent = () => {
    const gridArr = [];
    planDetails.plan_data.map((readyMadePlan, i) => {
      gridArr.push(
        <Grid item xs={12} key={readyMadePlan.id}>
          <Card elevation={12}>
            <Grid key={readyMadePlan.id} container>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
                sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="category Name"
                    label="Category Name"
                    name="cate_name"
                    autoComplete ='off'
                    variant='standard'
                    value={readyMadePlan.cate_name}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="product Name"
                    label="Product Name"
                    name="prod_name"
                    autoComplete ='off'
                    variant='standard'
                    value={readyMadePlan.prod_name}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                {planDetails.plan_data.length != 0 ? (
                  <Grid item xs={3} sm={2} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    <IconButton color='primary' onClick={() => handleShowPlanDetails(readyMadePlan.id)} >
                      {readyMadePlan.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                  </Grid>
                ) : ''}
              </Grid>
              {readyMadePlan.is_expanded == true ? readyMadePlan.sub_data.map((planSubData, j) => (
                <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      id="Model Name"
                      label="Model Name"
                      name="model_name"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.model_name}
                      disabled={true}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      id="Brand Name"
                      label="Brand Name"
                      name="brand_name"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.brand_name}
                      disabled={true}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}sm={1.5}>
                    <TextField
                      id="min_price"
                      label="Min Price"
                      name="min_price"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.min_price}
                      disabled={true}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}sm={1.5}>
                    <TextField
                      id="max_price"
                      label="Max Price"
                      name="max_price"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.max_price}
                      disabled={true}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}sm={1.5}>
                    <TextField
                      id="quantity"
                      label="Quantity"
                      name="quantity"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.quantity}
                      disabled={true}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              )) : ''}
            </Grid>
          </Card>
        </Grid>
      );
    });
    return gridArr;
  };

  const getSilkAndPolysterPlanUIContent = () => {
    const gridArr = [];
    planDetails.plan_data.map((silkAndPolysterPlan, i) => {
      gridArr.push(
        <Grid item xs={12} key={silkAndPolysterPlan.id}>
          <Card elevation={12}>
            <Grid container>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
                sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="prod_id"
                    label="Product Id"
                    name="prod_id"
                    autoComplete ='off'
                    value={silkAndPolysterPlan.prod_id}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={9} sm={5}>
                  <TextField
                    id="prod_name"
                    label="Product Name"
                    name="prod_id"
                    autoComplete ='off'
                    value={silkAndPolysterPlan.prod_name}
                    size='small'
                    fullWidth
                    disabled={true}
                  />
                </Grid>
                {planDetails.plan_data.length != 0 ? (
                  <Grid item xs={3} sm={2} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    <IconButton color='primary' onClick={() => handleShowPlanDetails(silkAndPolysterPlan.id)}>
                      {silkAndPolysterPlan.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                  </Grid>
                ) : ''}
              </Grid>
              {silkAndPolysterPlan.is_expanded == true ? silkAndPolysterPlan.sub_data.map((planSubData, j) => (
                <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      id="min_price"
                      label="Min Price"
                      name="min_price"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.min_price}
                      disabled={true}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      id="max_price"
                      label="Max Price"
                      name="max_price"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.max_price}
                      disabled={true}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}sm={1.5}>
                    <TextField
                      id="quantity"
                      label="Quantity"
                      name="quantity"
                      autoComplete ='off'
                      variant='standard'
                      value={planSubData.quantity}
                      disabled={true}
                      size='small'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              )) : ''}
            </Grid>
          </Card>
        </Grid>
      );
    });
    return gridArr;
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            id="Plan Id"
            label="Plan Id"
            name="plan_id"
            autoComplete ='off'
            value={planDetails.plan_id}
            size='small'
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={3.5}>
          <TextField
            id="Plan tag"
            label="Plan Tag"
            name="plan_tag"
            autoComplete ='off'
            value={planDetails.plan_tag}
            size='small'
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <TextField
            id="Plan Date"
            label="Plan Date"
            name="plan_date"
            autoComplete ='off'
            value={planDetails.plan_date}
            size='small'
            disabled={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="material_type"
            label="Material Type"
            name="material_type"
            autoComplete ='off'
            value={validateStringForNull(planDetails.material_type) ? MATERIAL_TYPE[planDetails.material_type] : ''}
            size='small'
            disabled={true}
            fullWidth
          />
        </Grid>
        {getMaterialUIContent()}
      </Grid>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

ViewPlanDetails.propTypes = {
  planId: PropTypes.string
};

export default ViewPlanDetails;
