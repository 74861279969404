import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useState } from 'react';
import { useEffect } from 'react';

const CustomSnackbar = ({ isOpen, message, severity, onClose }) => {
  const [open, setOpen] = useState(isOpen ?? false);
  const [msg, setMsg] = useState(message);
  const handleClose = () => {
    setOpen(false);
    setMsg('');
    onClose();
  };

  useEffect(() => {
    setOpen(isOpen);
    setMsg(message);
  }, [isOpen]);

  return (
    <Snackbar
      sx={{ mt: 6 }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open && msg !== ''}
      autoHideDuration={5000}
      onClose={() => {handleClose();}}
    >
      <Alert onClose={() => {handleClose();}} variant="filled"
        severity={severity ?? 'info'} sx={{ width: '100%' }}>
        {msg ?? ''}
      </Alert>
    </Snackbar>
  );
};

CustomSnackbar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default CustomSnackbar;
