import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import Services from '../../utils/Services';
import { validateStringForNull } from '../../utils/FieldValidations';
import { cloneDeep } from 'lodash';

const CustomAutocomplete = (props) => {
  const { label, apiInfo, autoFocus, defaultValue, onSelectionChange } = props;
  const getUIProps = (props) => {
    const obj = cloneDeep(props);
    delete obj.label;
    delete obj.apiInfo;
    delete obj.autoFocus;
    delete obj.defaultValue;
    delete obj.onSelectionChange;
    return obj;
  };
  const [uiparams] = useState(getUIProps(props));

  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});
  const APIToken = {
    SEARCH_URI: 'CCAC01'
  };

  useEffect(() => {
    setOptions(defaultValue == undefined ? [] : [defaultValue] );
    setSelectedValue(defaultValue != undefined && defaultValue[apiInfo.searchField] != undefined ? defaultValue : {});
  }, [defaultValue]);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == apiInfo.searchURI && apiToken == APIToken.SEARCH_URI ) {
      const searchData = response.data;
      setOptions(searchData == undefined ? [] : searchData);
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      return;
    };
    if (apiData == apiInfo.searchURI && apiToken == APIToken.SEARCH_URI) {
      console.log(err);
      setOptions([]);
      setSelectedValue(null);
    }
  };

  const getSearchData = (searchStr) => {
    if (searchStr == undefined || searchStr == '') {
      return;
    }
    let uriParams = '';
    if (validateStringForNull(apiInfo.searchParams)) {
      uriParams += apiInfo.searchParams+'&';
    }
    uriParams += apiInfo.searchField+'='+searchStr;
    Services.sendBackendRequest({ apiData: apiInfo.searchURI, params: uriParams },
      APIToken.SEARCH_URI, processSuccess, processError);
  };

  const handleChange = (event) => {
    const searchStr = event.target.value;
    if (searchStr.length == 3) {
      getSearchData(searchStr);
    }
  };

  return (
    <Autocomplete
      options={options}
      noOptionsText={''}
      size='small'
      value={selectedValue}
      id="autocompletefield344"
      getOptionLabel={(option) => option[apiInfo.searchField] ?? ''}
      isOptionEqualToValue={(option, value) => {
        return option != undefined && value != undefined ? option[apiInfo.idField] === value[apiInfo.idField] : false;
      }}
      onChange={(event, value) => {
        setSelectedValue(value);
        onSelectionChange(event, value);
      }}
      {...uiparams}
      autoHighlight
      renderInput={(params) => (
        <TextField
          autoFocus={autoFocus}
          {...params}
          label={label}
          InputLabelProps={{ style: { color: '#515253' } }}
          variant="outlined"
          fullWidth
          size='small'
          onChange={handleChange}
          sx={{ backgroundColor: 'white' }}
        />
      )}
    />
  );
};

CustomAutocomplete.propTypes = {
  label: PropTypes.string,
  apiInfo: PropTypes.object,
  autoFocus: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  defaultValue: PropTypes.object
};

export default CustomAutocomplete;
