import { useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  TextField
} from '@mui/material';
import { validateStringForNull } from '../../../utils/FieldValidations';
import CustomSnackbar from '../../../common/components/CustomSnackbar';
import React from 'react';
import APIData from '../../../utils/APIData';
import Services from '../../../utils/Services';
import { Utils } from '../../../utils/UtilFunctions';

const ChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    old_password: '',
    new_password: '',
    confirmPassword: ''
  });
  const APIToken = {
    CHANGE_PASSWORD: 'CCP01'
  };

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };
  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const [originalValues] = useState(values);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };

  const handleChange = (event) => {
    switch (event.target.name) {
    default:
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
      break;
    }
  };

  const getValuesToModify = () => {
    const result = {};
    Object.keys(values).forEach((key) => {
      if (originalValues[key] !== values[key]) {
        if (!validateStringForNull(values[key])) {
          throw new Error('Invalid Inputs');
        }
        result[key] = values[key];
      }
    });
    if (result.old_password === undefined || result.new_password === undefined || result.confirmPassword === undefined) {
      throw new Error('Invalid Password');
    }
    if (result.new_password !== result.confirmPassword) {
      throw new Error('Password does not match');
    }
    delete result.confirmPassword;
    return result;
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.changePasssword && apiToken == APIToken.CHANGE_PASSWORD ) {
      showSnackBar('success', response.message ?? 'Password updated successfully');
      setValues({ ...values, old_password: '', new_password: '', confirmPassword: '' });
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.changePasssword && apiToken == APIToken.CHANGE_PASSWORD) {
      defaultMsg = 'Failed to update password details';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseChangePasswordRequest = (modifiedValues) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.changePasssword, params: modifiedValues },
      APIToken.MODIFY_USER, processSuccess, processError);
  };

  const onSave = () => {
    try {
      const modifiedValues = getValuesToModify();
      raiseChangePasswordRequest(modifiedValues);
    } catch (err) {
      setSnackBarStatus({
        open: true,
        severity: 'error',
        message: err.message
      });
      setIsLoading(false);
    }
  };

  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  return (
    <React.Fragment>
      <form
        autoComplete="off"
        noValidate
      >
        <Card>
          <CardHeader
            title="Change Password"
          />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6.02}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Old Password"
                  name="old_password"
                  onChange={handleChange}
                  type="password"
                  size='small'
                  value={values.old_password}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="New Password"
                  name="new_password"
                  onChange={handleChange}
                  type="password"
                  size='small'
                  value={values.new_password}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Confirm Password"
                  name="confirmPassword"
                  onChange={handleChange}
                  type="password"
                  size='small'
                  value={values.confirmPassword}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <Button
              color="primary"
              variant="contained"
              size='small'
              onClick={() => {onSave();}}
            >
              Change Password
            </Button>
          </Box>
        </Card>
      </form>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default ChangePassword;
