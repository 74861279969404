import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Card, CardContent, CardHeader, Divider } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';

const StyledTreeItem = React.forwardRef(function StyledTreeItem(props, ref) {
  const {
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 0.5,
            pr: 0
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      {...other}
      ref={ref}
    />
  );
});

StyledTreeItem.propTypes = {
  labelInfo: PropTypes.string,
  labelText: PropTypes.string
};

const CustomTree = ({ data, title, reloadIndex })=>{
  const [currentData, setCurrentData] = useState(undefined);
  const getValueToDisplay = (obj) => {
    return obj.display !== undefined ? obj.display : obj.value;
  };
  let nodeItemId = 1;
  useEffect(() => {
    if (data != undefined) {
      setCurrentData(data);
    }
  }, [reloadIndex]);

  const getTreeNodeItem = (obj) => {
    if (obj.split_up == undefined) {
      return (
        <StyledTreeItem
          nodeId={(nodeItemId++).toString()}
          key={nodeItemId++}
          labelText={obj.pay_mode_name}
          labelInfo={getValueToDisplay(obj.amount)}
        />
      );
    } else {
      return (
        <StyledTreeItem
          nodeId={(nodeItemId++).toString()}
          key={nodeItemId++}
          labelText={obj.txn_type_name}
          labelInfo={getValueToDisplay(obj.total)}>
          {getTreeNodeItems(obj.split_up)}
        </StyledTreeItem>
      );
    }
  };

  const getTreeNodeItems = (treeData) => {
    const treeNodes = [];
    let i = 0;
    treeData.map((obj) => {
      treeNodes[i] = getTreeNodeItem(obj);
      i++;
    });
    return treeNodes;
  };

  return (
    (currentData != undefined ? (
      <Card sx={{ mb: 2 }}>
        <CardHeader title={title} />
        <Divider />
        <CardContent>
          <TreeView
            defaultExpanded={['0']}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            sx={{ height: 285, flexGrow: 1, overflowY: 'auto' }}
          >
            {getTreeNodeItems(currentData)}
          </TreeView>
        </CardContent>
      </Card>
    ) : '')
  );
};

CustomTree.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  reloadIndex: PropTypes.number
};

export default CustomTree;
