import { useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid
} from '@mui/material';
import { Utils } from '../../../utils/UtilFunctions';
import CustomSnackbar from '../../../common/components/CustomSnackbar';
import CustomAutocomplete from '../../../common/components/CustomAutocomplete';
import React from 'react';
import Services from '../../../utils/Services';
import APIData from '../../../utils/APIData';

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const APIToken = {
    RESET_PASSWORD: 'CRP01'
  };
  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState());
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState());
  };
  const [userId, setUserId] = useState(undefined);

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.resetPassword && apiToken == APIToken.RESET_PASSWORD ) {
      showSnackBar('success', response.message);
      setUserId(undefined);
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.resetPassword && apiToken == APIToken.RESET_PASSWORD) {
      defaultMsg = 'failed to reset password';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseResetPasswordRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.resetPassword, uriValues: [userId] },
      APIToken.RESET_PASSWORD, processSuccess, processError);
  };

  const onResetClick = () => {
    if (userId == undefined) {
      setSnackBarStatus({
        open: true,
        severity: 'error',
        message: 'Invalid User Name'
      });
    } else {
      raiseResetPasswordRequest();
    }
  };

  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const handleAutocompleteChange = (value) => {
    if (value !== undefined && value !== null) {
      setUserId(value.user_id);
    } else {
      setUserId(undefined);
    }
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader
          title="Reset Password"
        />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <CustomAutocomplete
                label={'User Name'}
                apiInfo={{
                  searchURI: APIData.searchUser,
                  searchField: 'user_name'
                }}
                onSelectionChange={(event, value) => handleAutocompleteChange(value)}
                margin="normal"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            size='small'
            onClick={() => {onResetClick();}}
          >
            Reset Password
          </Button>
        </Box>
      </Card>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default ResetPassword;
