import {
  Card,
  CardContent,
  Grid,
  Typography,
  Tooltip
} from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

const DeductablesCard = ({ cardData, reloadIndex })=>{
  const [currentData, setCurrentData] = useState(undefined);
  useEffect(() => {
    if (cardData != undefined) {
      setCurrentData(cardData);
    }
  }, [reloadIndex]);

  const getValueToDisplay = (obj) => {
    return obj.display !== undefined ? obj.display : obj.value;
  };

  const getDeductablesData = () => {
    const deductablesData = [];
    let i = 0;
    currentData.map((obj) => {
      deductablesData[i] = (
        <Grid item container xs= {12} key={obj.pay_mode_id} >
          <Grid item xs={6}>
            <Tooltip key={i} title= {obj.pay_mode_name}>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h6"
              >
                { obj.pay_mode_name }
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={6}>
            <Tooltip key={i} title= {getValueToDisplay(obj.amount)}>
              <Typography
                color="textPrimary"
                variant="h5"
                sx={{ fontSize: '15px', color: '#6d7cb6' }}
                lg={2}
              >
                { getValueToDisplay(obj.amount) }
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      );
      i++;
    });
    return deductablesData;
  };

  return (
    (currentData != undefined ? (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Grid
            container
            spacing={3}
            sx={{ justifyContent: 'space-between' }}
          >
            {getDeductablesData()}
          </Grid>
        </CardContent>
      </Card>
    ) : '')
  );
};

DeductablesCard.propTypes = {
  cardData: PropTypes.array,
  reloadIndex: PropTypes.number
};

export default DeductablesCard;
