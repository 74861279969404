import React, { useState } from 'react';
import { Backdrop, Button, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { Utils } from '../../../../utils/UtilFunctions';
import { blue } from '@mui/material/colors';
import { ENTITY_TYPE } from '../../../../utils/EnumDefinitions';
import Services from '../../../../utils/Services';
import APIData from '../../../../utils/APIData';
import CustomAutocomplete from '../../../../common/components/CustomAutocomplete';
import { Constants } from '../../../../utils/Constants';
import { validateStringForNull } from '../../../../utils/FieldValidations';

const CreateEntities = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );
  const [entityObject, setEntityObject] = useState({
    entity_type: '',
    refresh_id: 0
  });
  const [entityOptions, setEntityOptions] = useState({});
  const APIToken = {
    GET_ENTITY_COLUMNS: 'CCE01',
    CREATE_NEW_ENTITY: 'CCE02'
  };

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getEntityColumns && apiToken == APIToken.GET_ENTITY_COLUMNS ) {
      setEntityOptions({ ...entityOptions, [callbackValues.entityType]: response.data });
    } else if (apiData == APIData.createNewEntity && apiToken == APIToken.CREATE_NEW_ENTITY ) {
      showSnackBar('success', response.message ?? 'Entity created successfully');
      setEntityObject({
        entity_type: '',
        refresh_id: 0
      });
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getEntityColumns && apiToken == APIToken.GET_ENTITY_COLUMNS) {
      defaultMsg = 'Failed to retrieve entity columnst';
    } else if (apiData == APIData.createNewEntity && apiToken == APIToken.CREATE_NEW_ENTITY ) {
      defaultMsg = 'Failed to create entity';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetEntityTypeColumns = (entityType) => {
    setIsLoading(true);
    const params = 'entity=' + ENTITY_TYPE[entityType].toLowerCase();
    Services.sendBackendRequest({ apiData: APIData.getEntityColumns, params: params },
      APIToken.GET_ENTITY_COLUMNS, processSuccess, processError, { entityType: entityType });
  };

  const notifyOrderDetailsChange = () => {
    setEntityObject({ ...entityObject, refresh_id: entityObject.refresh_id + 1 });
  };

  const handleEntityTypeChange = (event) => {
    const selectedValue = event.target.value;
    setEntityObject({ ...entityObject, entity_type: selectedValue });
    if (entityOptions[selectedValue] == undefined) {
      raiseGetEntityTypeColumns(selectedValue);
    }
  };

  const handleTextFieldChange = (event) => {
    const { name, value } = event.target;
    entityObject[name] = value;
    notifyOrderDetailsChange();
  };

  const handleAutoCompleteChange = (selectedValue, idField, nameField) => {
    entityObject[idField] = selectedValue != undefined ? selectedValue[idField] : '';
    entityObject[nameField] = selectedValue != undefined ? selectedValue[nameField] : '';
    notifyOrderDetailsChange();
  };

  const raiseCreateEntityRequest = (finalParams) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.createNewEntity, params: finalParams },
      APIToken.CREATE_NEW_ENTITY, processSuccess, processError );
  };

  const validateEntityInputs = () => {
    const filteredObj = {};
    if (!validateStringForNull(entityObject.entity_type)) {
      throw new Error('Select Entity Type');
    }
    if (entityOptions[entityObject.entity_type] == undefined) {
      throw new Error('Failed to create entity');
    }

    entityOptions[entityObject.entity_type].map((column) => {
      const value = entityObject[column.column_name];
      if (column.is_mandatory == true & !validateStringForNull(value)) {
        throw new Error('Invalid value for ' + column.display_name);
      }

      if (column.data_type == 'int' & validateStringForNull(value)) {
        if (isNaN(value)) {
          throw new Error('Invalid value for ' + column.display_name + '. Type Int required');
        }
      }

      if (column.data_length != undefined & validateStringForNull(value)) {
        const length = parseInt(column.data_length);
        if (length < value.length) {
          throw new Error('Invalid value for ' + column.display_name + '. Max Allowed Length: ' + length);
        }
      }
      filteredObj[column.column_name] = value;
    });
    if (Object.keys(filteredObj).length == 0) {
      throw new Error('Invalid Inputs');
    }
    const entityObj = {
      entity: ENTITY_TYPE[entityObject.entity_type].toLowerCase(),
      new_entry_data: filteredObj
    };
    return entityObj;
  };

  const handleCreateEntityRequest = () => {
    setIsLoading(true);
    try {
      const filteredParams = validateEntityInputs();
      raiseCreateEntityRequest(filteredParams);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to create entity');
      setIsLoading(false);
    }
  };

  const getTextColumnContent = (column) => {
    return (
      <Grid item xs={12} sm={8} key={column.column_name}>
        <TextField
          id={column.column_name}
          label={column.display_name}
          name={column.column_name}
          autoComplete ='off'
          variant='outlined'
          value={entityObject[column.column_name] ?? ''}
          onChange={(event) => handleTextFieldChange(event)}
          size='small'
          fullWidth
        />
      </Grid>
    );
  };

  const getAutoCompleteContent = (column) => {
    return (
      <Grid item xs={12} sm={8} key={column.column_name}>
        <CustomAutocomplete
          label={column.display_name}
          apiInfo={{
            searchURI: APIData.getAPIDataByPath(Constants.ORDERS_APP_KEY, column.refer_api),
            searchField: column.refer_column_name,
            idField: column.column_name
          }}
          defaultValue={{
            [column.column_name]: entityObject[column.column_name] ?? '',
            [column.refer_column_name]: entityObject[column.refer_column_name] ?? ''
          }}
          onSelectionChange={(event, value) => handleAutoCompleteChange(value, column.column_name, column.refer_column_name)}
        />
      </Grid>
    );
  };

  return (
    <Container maxWidth="sm" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2} justifyContent='center'>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography variant='h5' align='center'>
            Create New Entities
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <FormControl fullWidth >
            <InputLabel size="small" id="entity-type-select-label"> Entity Type</InputLabel>
            <Select
              labelId="entity-type-select-label"
              id="entity-type-select"
              label="Entity Type"
              name='entity_type'
              size="small"
              value={entityObject.entity_type}
              onChange={handleEntityTypeChange}
            >
              {Object.keys(ENTITY_TYPE).map((key) => (
                <MenuItem key={key} value={key}>
                  {ENTITY_TYPE[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {entityOptions[entityObject.entity_type] != undefined ? (
          entityOptions[entityObject.entity_type].map((column) => {
            if (column.refer_api !== undefined) {
              return getAutoCompleteContent(column);
            } else {
              return getTextColumnContent(column);
            }
          })
        ) :''}
        {entityOptions[entityObject.entity_type] != undefined && entityOptions[entityObject.entity_type].length > 0 ? (
          <Grid item xs={12} sm={8} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button
              type="submit"
              variant='contained'
              onClick={handleCreateEntityRequest}
            >
              Create
            </Button>
          </Grid>
        ) : ''}
      </Grid>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

export default CreateEntities;
