import { Box } from '@mui/material';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import CreateUser from '../components/CreateUser';
import UserList from '../components/UserList';
import { Container } from '@mui/system';

const ManageUsers = () => {
  const [refreshId, setRefreshId] = useState(1);
  return (
    <React.Fragment>
      <Helmet>
        <title>Manage Users | Rajmahal Apps</title>
      </Helmet>
      <Box sx={{
        backgroundColor: 'background.default',
        minHeight: '100vh',
        pb: 10,
        justifyContent: 'center',
        alignItems: 'flex-start',
        display: 'flex'
      }}>
        <Container maxWidth='md'>
          <UserList refreshId={refreshId}/>
          <CreateUser onSuccess={() => setRefreshId(refreshId + 1)} />
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default ManageUsers;
